import * as React from "react";
import ReactCollapse from "react-css-collapse";

interface Props {
  open: boolean;
  children: React.ReactNode;
}

export default class Collapse extends React.PureComponent<Props> {
  public render() {
    const { open, children } = this.props;

    return (
      <ReactCollapse isOpen={open} transition={"height 300ms ease"}>
        {children}
      </ReactCollapse>
    );
  }
}
