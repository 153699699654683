import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import DocumentsTable, { DocumentsQuery } from "./Table";
import documentsQuery from "./documents.gql";
import { DocumentObjectType } from "gql-gen";

export default compose(
  graphql<{ organizationId: string; documentObjectType: DocumentObjectType }, DocumentsQuery, { orgId: string }, any>(
    documentsQuery,
    {
      options: props => ({
        variables: {
          orgId: props.organizationId,
          documentObjectType: props.documentObjectType,
        },
        fetchPolicy: "no-cache",
      }),
    },
  ),
)(DocumentsTable);
