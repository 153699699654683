import { ArrayDate } from "./ArrayDate";
import { Asset } from "./Asset";
import { Program } from "modules/Dashboard/interfaces/Program";
import { UserKind } from "gql-gen";
import { isHayday, isPinata } from "utilities/flavor";
import { Organization } from "./Organization";

// Old Legacy type
export interface UserInformation {
  uid?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  businessName: string;
  password?: string;
}

export type UserType = "super_admin" | "owner" | "admin" | "manager" | "member" | "limited_user" | "gogetter";

const PINATA_USER_TYPES: UserType[] = ["owner", "admin", "manager", "member", "limited_user", "gogetter"];

export const USER_TYPES: UserType[] = isPinata ? PINATA_USER_TYPES : ["super_admin", "admin", "manager", "gogetter"];

export const USER_TYPE_NAMES: { [K in UserType]: string } = {
  super_admin: "PINATA Admin",
  owner: "Owner",
  admin: "Administrator",
  manager: "Manager",
  member: "Member",
  limited_user: "Limited User",
  gogetter: "GoGetter",
};

export const USER_TYPE_DESCRIPTIONS: { [K in UserType]: string } = {
  super_admin: "A PINATA staff member.",
  owner: "Access to all current and future programs.",
  admin: "Access to all current and future programs.",
  manager: `Select which ${"programs"} to access. Managers can create their own new programs.`,
  member: `Select which ${"programs"} to access. Members cannot create new programs.`,
  limited_user: `Select which ${"programs"} to access. Limited users cannot create new programs and have no access to company settings.`,
  gogetter: isPinata
    ? "No access to this dashboard (can only be assigned to tasks)."
    : "No access to this dashboard (can only do check-ins)",
};

export type OrganizationType = "client" | "agency";

export type OrganizationUserType =
  | "super_admin"
  | "owner"
  | "admin"
  | "manager"
  | "member"
  | "limited_user"
  | "gogetter";

export interface OrganizationUser {
  id: string;
  type: OrganizationUserType;
  organizationId: string;
  organizationType: OrganizationType;
  treasurerActive: Boolean;
  organizationNotes: string | null;
  tagIds: string[] | null;
  tags: Array<{ id: string; name: string }> | null;
  defaultRate: number | null;
  documents: Array<{ id: string; url: string; name: string }> | null;
  salaried: boolean;
  reportingProgramId: string;
  ignoreCapacity: boolean;
  organizationUserId: string;
  legalName: string | null;
  employeeId: string | null;
  canRequest: boolean;
  canSelfAssign: boolean;
  bulkUploaderActive: boolean;
  organizationInvoicingActive: boolean;
  slidesActive: boolean;
  redirectToTheApp: boolean;
}

export interface UserProfile {
  zip: string | null;
  selfSummary: string | null;
  shirtSize: string | null;
  birthday: ArrayDate | null;
  hasCar: string | null;
  canDrive: string | null;
  areas: string | null;
  languages: Array<{ key: string; label: string }>;
  shipHome: boolean | null;
  shippingAddress: string | null;
  address: string | null;
  apartment: string | null;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  kind: UserKind;
  userType?: UserType;
  reportingProgramId?: string;
  organizationUser: OrganizationUser;
  phone?: string;
  unconfirmedPhone?: string;
  secondaryPhone?: string;
  email: string;
  unconfirmedEmail?: string;
  confirmedAt?: string;
  selfSummary?: string;
  zip?: string;
  assets?: Asset[];
  isAdmin?: boolean;
  createdAt?: string;
  updatedAt?: string;
  isCorporate?: boolean;
  firstOrganizationId?: string;
  language: string;
  programs: Program[];
  haydayWorkplace: Program;
  profile: UserProfile | null;
  documents: Array<{ id: string; name: string; url: string }>;
  intercomHash: string;
  organizations: Organization[];
}

export function isUserType(
  user: { userType?: UserType; organizationUser: OrganizationUser } | null,
  userType: UserType,
) {
  if (!user) return false;
  const currentUserType = user.userType || (user.organizationUser && user.organizationUser.type);
  return currentUserType === userType;
}

export function isUserAtLeast(
  user: { userType?: UserType; organizationUser: OrganizationUser } | null,
  userType: UserType,
) {
  if (!user) return false;
  const currentUserType = user.userType || (user.organizationUser && user.organizationUser.type);
  return currentUserType && USER_TYPES.indexOf(currentUserType) <= USER_TYPES.indexOf(userType);
}

export function isAdminOrOwner(userType: UserType) {
  return userType === "admin" || userType === "owner";
}

export function isStandardPowerUser(userType: UserType) {
  return userType === "manager" || userType === "member" || userType === "limited_user" || userType === "super_admin";
}

export function canSpecifyPrograms(userType: UserType, canRequest: boolean, canSelfAssign: boolean) {
  return !isAdminOrOwner(userType) && (isStandardPowerUser(userType) || canRequest || canSelfAssign);
}

export function getManageableUserTypes(user: User | null) {
  if (!user) return [];
  const userType = user.userType || (user.organizationUser && user.organizationUser.type);

  if (!userType) return [];

  if (userType.startsWith("pinata")) {
    return USER_TYPES;
  }

  return USER_TYPES.slice(USER_TYPES.indexOf(userType) + (userType === "admin" || userType === "owner" ? 0 : 1));
}

export function canSeeCompanySettings(userType: UserType) {
  return userType !== "limited_user";
}
