import { flowRight as compose } from "lodash";
import * as React from "react";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";
import { withFormik, FormikProps } from "formik";
import * as yup from "yup";
import Form from "components/Form";
import Text from "components/LegacyText";
import AccordionPart from "components/AccordionLayout/AccordionPart";
import AccordionPartContent from "components/AccordionLayout/AccordionPartContent";
import AccordionPartHelper from "components/AccordionLayout/AccordionPartHelper";
import { AccordionContextProps, withAccordionContext } from "components/AccordionLayout/AccordionContext";
import OptionsDropdown from "../../../../../../components/OptionsDropdown";
import SaveButton from "../SaveButton";
import { inputProps } from "utilities/formik";
import ProductsTable from "../../../ProductsTable";
import { Helper1, Helper2 } from "../FormHelpers";
import { withProgramFormContext, validateAndNotify, ProgramFormContext, optionSchema } from "../Context";
import styles from "./styles.scss";
import { ProgramsRouteParams } from "../../index";
import Button from "components/Button";

interface ProductsValues {
  productIds: string[];
  selectedProducts: { id: string; name: string }[];
  sampleProductsSource: string;
}

interface Props extends FormikProps<ProductsValues>, RouteComponentProps<ProgramsRouteParams> {
  programForm: ProgramFormContext;
  accordionState: AccordionContextProps;
}

export const ID = "products";

// const needsGigBudget = (option: string) => option === 'purchase';

function Products(props: Props) {
  const {
    programForm: { canEdit },
    match: {
      url,
      params: { orgId },
    },

    touched,
    values,
    errors,
    setFieldValue,
    location,
    accordionState: { showHelpersColumn },
  } = props;

  const renderCounter = (count: number) => `${count === 1 ? "One product" : `${count} products`} added to this program`;

  const input = inputProps(props, undefined, !canEdit);

  return (
    <AccordionPart>
      <AccordionPartHelper>
        <Helper1>
          Your team’s Products on PINATA are shown here. Select those that will be used within this Program.
        </Helper1>

        <Helper2>
          When scheduling an individual task, Power Users will be able to narrow down the selections you make here,
          choosing only the Products that are needed on the specific task.
        </Helper2>

        {showHelpersColumn && (
          <Helper2>
            Don’t see what you’re looking for?
            <br />
            <Link to={{ ...location, pathname: url + "/+add-products" }}>
              <Text.NewLink4>Add a new product</Text.NewLink4>
            </Link>
          </Helper2>
        )}
      </AccordionPartHelper>
      <AccordionPartContent>
        {props.programForm.executionType === "solo" ? null : (
          <Form.HorizontalSectionGroup>
            <Form.Section className={/*withGigBudget ? '' : */ styles.noMargin}>
              <OptionsDropdown
                group={"sampleProductsSource"}
                ddProps={{
                  ...input("sampleProductsSource.key"),
                  label: "HOW WILL PRODUCT GET TO THE TASK?",
                }}
                descProps={{
                  ...input("sampleProductsSource.description"),
                }}
              />
            </Form.Section>
          </Form.HorizontalSectionGroup>
        )}
        <ProductsTable
          atomsFilter="objects"
          tableProps={{
            selected: values.productIds,
            selectedItems: values.selectedProducts
              ? values.selectedProducts.map(({ id, name }) => ({ id, label: name }))
              : [],
            onSelectionChange: canEdit && setFieldValue.bind(null, "productIds"),
            displaySelected: { renderCounter, disableRemove: !canEdit },
          }}
          canToggleStatus={false}
        />

        {touched.productIds && <Text.Message kind={"error"}>{errors.productIds}</Text.Message>}

        <SaveButton id={ID} topMargin />
        {!showHelpersColumn && (
          <Link to={{ ...location, pathname: url + "/+add-products" }} className={styles.inContentAddProduct}>
            <Button kind={"primary"}>ADD A NEW PRODUCT</Button>
          </Link>
        )}
      </AccordionPartContent>
    </AccordionPart>
  );
}

const transactionalSchema = yup.object().shape({
  productIds: yup
    .array()
    .min(1, "Please add at least one product")
    .of(yup.string()),
  sampleProductsSource: optionSchema,
});

const soloSchema = yup.object().shape({
  productIds: yup
    .array()
    .min(1, "Please add at least one product")
    .of(yup.string()),
});

export default compose(
  withRouter,
  withProgramFormContext,
  withAccordionContext,
  withFormik<Props, ProductsValues>({
    mapPropsToValues: props => {
      return {
        sampleProductsSource: { key: "", description: "" },
        ...props.programForm.values,
      };
    },

    validate: validateAndNotify(transactionalSchema, soloSchema, soloSchema, ID),
    handleSubmit: () => {},
  }),
)(Products);
