import { flowRight as compose } from "lodash";
import * as React from "react";
import { withFormik, FormikProps } from "formik";
import _ from "lodash";
import * as yup from "yup";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";
import Text from "components/LegacyText";
import Form from "components/Form";
import AccordionPart from "components/AccordionLayout/AccordionPart";
import AccordionPartContent from "components/AccordionLayout/AccordionPartContent";
import AccordionPartHelper from "components/AccordionLayout/AccordionPartHelper";
import { AccordionContextProps, withAccordionContext } from "components/AccordionLayout/AccordionContext";
import Button from "components/Button";
import { inputProps } from "utilities/formik";
import SaveButton from "../SaveButton";
import DocumentsTable from "../../../LegacySettings/DocumentsTable";
import { Helper1, Helper2, HelperHiLi, HelperSubtitle } from "../FormHelpers";
import { withProgramFormContext, validateAndNotify, optionSchema, ProgramFormContext } from "../Context";
//import trainingTypesQuery from "./trainingTypes.gql";
import styles from "./styles.scss";
import { OptionConfig } from "modules/Dashboard/interfaces/OptionConfig";
import { OptionField } from "modules/Dashboard/interfaces/Option";
import { ProgramsRouteParams } from "../../index";
import { DocumentObjectType } from "gql-gen";

interface TrainingValues {
  trainingType: OptionField[];
  trainingMessage: string;
  trainingDocumentIds: string[];
}

const Fields: TrainingValues = {
  trainingType: [],
  trainingMessage: "",
  trainingDocumentIds: [],
};

const requiredFields = Object.keys(Fields);

export const ID = "training";

interface Props extends FormikProps<TrainingValues>, RouteComponentProps<ProgramsRouteParams> {
  accordionState: AccordionContextProps;
  programForm: ProgramFormContext;
  data: {
    options: OptionConfig[];
  };
}

class Education extends React.PureComponent<Props> {
  public render() {
    const {
      programForm: { canEdit },
      match: {
        url,
        params: { orgId },
      },

      values,
      touched,
      errors,
      setFieldValue,
      location,
      accordionState: { showHelpersColumn },
    } = this.props;
    const input = inputProps(this.props, requiredFields, !canEdit);

    const renderDocsCounter = (count: number) =>
      `${count === 1 ? "One document" : `${count} documents`} added to this program`;

    return (
      <React.Fragment>
        <AccordionPart>
          <AccordionPartHelper>
            <HelperSubtitle>Files for end users</HelperSubtitle>
            <Helper1>
              The files within your PINATA organization are shown in the table to the right. Select those that you would
              like to be visible to users assigned to tasks in this program (e.g. training files).
            </Helper1>
            <HelperHiLi>
              Remember: Any files attached to your selected Products will be accessible to end users already. You do not
              need to add them again here.
            </HelperHiLi>
            <Helper2>
              If you’d like to add product-specific training files, return to the Products section, expand a product in
              the table, and select Edit.
            </Helper2>
            {showHelpersColumn && (
              <Helper2>
                Don’t see a file you’re looking for?
                <br />
                <Link to={{ ...location, pathname: url + "/+add-documents" }}>
                  <Text.NewLink4>Add a new file</Text.NewLink4>
                </Link>
              </Helper2>
            )}
          </AccordionPartHelper>
          <AccordionPartContent>
            <Form.Section>
              <Form.TextBox
                {...input("trainingMessage")}
                label="KEY INFO"
                type={"text"}
                placeholder={"Write here..."}
              />
            </Form.Section>
            <DocumentsTable
              key={location.pathname}
              organizationId={orgId}
              documentObjectType={DocumentObjectType.Programs}
              tableProps={{
                itemsPerPage: 10,
                selected: values.trainingDocumentIds,
                onSelectionChange: canEdit && setFieldValue.bind(null, "trainingDocumentIds"),
                displaySelected: { renderCounter: renderDocsCounter, disableRemove: !canEdit },
              }}
            />

            {touched.trainingDocumentIds && <Text.Message kind={"error"}>{errors.trainingDocumentIds}</Text.Message>}

            <SaveButton id={ID} topMargin />
            {!showHelpersColumn && (
              <Link to={{ ...location, pathname: url + "/+add-documents" }} className={styles.inContentAddDocument}>
                <Button kind={"primary"}>ADD A NEW FILE</Button>
              </Link>
            )}
          </AccordionPartContent>
        </AccordionPart>
      </React.Fragment>
    );
  }
}

const transactionalSchema = yup.object().shape({
  trainingType: yup
    .array()
    .of(optionSchema)
    .min(1),
  trainingMessage: yup
    .string()
    .required()
    .nullable(),
  trainingDocumentIds: yup.array().nullable(),
});

const soloSchema = transactionalSchema;

export default compose(
  withRouter,
  withAccordionContext,
  //graphql(trainingTypesQuery),
  withProgramFormContext,
  withFormik({
    mapPropsToValues: props => ({ ...Fields, ...props.programForm.values }),
    validate: validateAndNotify(transactionalSchema, soloSchema, soloSchema, ID),
    handleSubmit: () => {},
  }),
)(Education);
