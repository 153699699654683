import * as React from "react";
import cx from "classnames";
import Button from "components/Button";
import Text from "components/LegacyText";
import styles from "./styles.scss";

interface Props {
  onAction: () => void;
  onCancel?: () => void;
  title: string;
  note?: string | React.ReactNode;
  errorMessage?: React.ReactNode;
  disabled?: boolean;
  cancelDisabled?: boolean;
  className?: string;
  testId?: string;
  header?: React.ReactNode;
  secondaryAction?: React.ReactNode;
}

export default function ActionFooter({
  onAction,
  onCancel,
  title,
  note,
  errorMessage,
  disabled,
  cancelDisabled,
  className,
  testId,
  header,
  secondaryAction,
}: Props) {
  return (
    <div className={cx(styles.container, className)}>
      {header && <div className={cx(styles.header)}>{header}</div>}
      <div className={styles.buttons}>
        {secondaryAction ? secondaryAction : null}
        {onCancel && (
          <Button
            kind="translucent"
            className={styles.cancel}
            onClick={onCancel}
            disabled={cancelDisabled}
            data-test={`${testId}.cancel`}
          >
            Cancel
          </Button>
        )}

        <Button
          kind="primary"
          type="submit"
          className={styles.button}
          onClick={onAction}
          disabled={disabled}
          data-test={testId}
        >
          {title}
        </Button>
      </div>
      <div className={styles.errorMessage}>{errorMessage}</div>
      {typeof note === "string" ? (
        <Text.H4 kind="reverse" className={styles.note}>
          {note}
        </Text.H4>
      ) : (
        note
      )}
    </div>
  );
}
