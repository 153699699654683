import React, { ReactNode, CSSProperties } from "react";
import { FontWeightProperty } from "csstype";
import cx from "classnames";
import { spacing, colors } from "styles/variables";
import styles from "./styles.scss";

type Spacing = keyof typeof spacing;
export type Color = keyof typeof colors;

export type Size = 8 | 10 | 12 | 14 | 16 | 18 | 20 | 22 | 24 | 26 | 28 | 30 | 32 | 34 | 36 | 50 | 62 | 64;

export type TextProps = {
  font?: "lato" | "wes" | "wesR" | "monospace";
  color?: Color;
  size?: Size;
  weight?: FontWeightProperty;
  bold?: boolean;
  /** Em changes the text-style to italic */
  em?: boolean;
  underline?: "always" | "hover" | "never";
  letterSpacing?: number;
  lineHeight?: number;
  top?: Spacing;
  right?: Spacing;
  bottom?: Spacing;
  left?: Spacing;
  inline?: boolean;
  ellipsis?: boolean;
  tooltip?: string;
  placeholder?: string;
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  role?: string;
  noSelect?: boolean;
  transform?: "uppercase" | "lowercase" | "capitalize" | "none";
  testId?: string;
  align?: "left" | "center" | "right";
};

function Text({
  font = "lato",
  color = "gray1",
  size = 12,
  weight,
  bold,
  em,
  underline = "never",
  letterSpacing,
  lineHeight,
  top,
  right,
  bottom,
  left,
  inline,
  ellipsis,
  align,
  tooltip,
  placeholder,
  children,
  className,
  style = {},
  role,
  noSelect,
  transform,
  testId,
}: TextProps) {
  const classNames: (string | false | undefined)[] = [
    styles.base,
    styles[font],
    bold && styles.bold,
    em && styles.em,
    underline && styles[underline],
    inline && styles.inline,
    ellipsis && styles.ellipsis,
    !children && placeholder && styles.placeholder,
    noSelect && styles.noSelect,
    role,
    className,
  ];

  const finalStyle: CSSProperties = {
    fontSize: size + "px",
    fontWeight: weight,
    color: colors[color],
    letterSpacing: letterSpacing + "px",
    lineHeight: lineHeight + "px",
    marginTop: top && spacing[top],
    marginRight: right && spacing[right],
    marginLeft: left && spacing[left],
    marginBottom: bottom && spacing[bottom],
    textTransform: transform,
    textAlign: align,
    ...style,
  };

  return (
    <div
      className={cx(classNames)}
      style={finalStyle}
      data-tooltip={tooltip}
      title={ellipsis && typeof children === "string" ? children : ""}
      data-test={testId}
    >
      {!children && children !== 0 ? placeholder : children}
    </div>
  );
}

const TextGroupCtx: React.Context<TextProps> = React.createContext({});

export function TextGroup(props: TextProps) {
  return (
    <TextGroupCtx.Provider value={props}>
      <Text {...props} />
    </TextGroupCtx.Provider>
  );
}

export function TextNode(props: TextProps) {
  return (
    <TextGroupCtx.Consumer>
      {({ top, right, bottom, left, ...parentProps }) => <Text inline {...parentProps} {...props} />}
    </TextGroupCtx.Consumer>
  );
}

export default Text;
