import React from "react";
import useRouter from "use-react-router";
import { option, function as fn, apply } from "fp-ts";
import { useTranslation } from "react-i18next";
import { Gig, GigReportOverviewFragment } from "gql-gen";
import { Switch, Route } from "react-router";
import { Link } from "react-router-dom";
import cx from "classnames";

import { ICell } from "modules/Connection/types";
import icon from "assets/report.svg";
import Text from "components/Text";
import Report from "modules/Report";
import { UserInfo, useUserInfo } from "modules/Dashboard/UserInfo";
import { useScopeContext } from "modules/Connection/Scope";
import Clickable from "components/Clickable";
import Spinner from "components/Spinner";

import Blurred from "../Blurred";
import FieldReportView from "./FieldReportView";

import styles from "./styles.scss";
import fragment from "./report.gql";
import { isEditable } from "utilities/gigAccess";

export const ReportOverviewCell: ICell<GigReportOverviewFragment> = {
  component: ({ data }, id) => {
    const userInfo = useUserInfo();

    const iAmTalentOrg = fn.pipe(
      apply.sequenceS(option.Apply)({
        userOrgId: option.fromNullable(userInfo.me?.organizationUser?.organizationId),
        talentOrgId: option.fromNullable(data?.talentOrganization?.id),
      }),
      option.exists(({ userOrgId, talentOrgId }) => userOrgId === talentOrgId),
    );

    const { t } = useTranslation();
    const { match, location, history } = useRouter();
    const isEditing = location.pathname.includes("/+edit-report");
    const windowGig = (data?.windowStartTime || data?.windowEndTime) && !data.startTime;
    const ctx = useScopeContext();

    if (userInfo.loading || !data) return <Spinner />;

    const { editable, gigIsVerified } = isEditable(data as Gig, userInfo);

    return (
      <div className={styles.reportOverview}>
        {editable && (
          <>
            <Link
              className={styles.viewEdit}
              to={{
                ...location,
                pathname: isEditing ? location.pathname.split("/+edit-report")[0] : location.pathname + "/+edit-report",
              }}
            >
              <Text font="wes" bold size={12} color="blue2" underline="hover">
                {t(`gigs.cells.reportOverview.${isEditing ? "view" : "edit"}`)}
              </Text>
            </Link>
            {windowGig && isEditing && (
              <Clickable
                className={cx(styles.viewEdit, styles.editDate)}
                onClick={async () => {
                  ctx.setFormMeta({ forcedDateType: "scheduled" });
                  ctx.setEditing("date");
                }}
              >
                <Text font="wes" bold size={12} color="blue2" underline="hover">
                  {t(`gigs.cells.reportOverview.editDates`)}
                </Text>
              </Clickable>
            )}
          </>
        )}

        <Switch>
          <Route
            path={`${match.path}/:vpage*/+edit-report`}
            render={props => (
              <Report
                {...props}
                gig={data}
                gigRefetch={() => {}}
                verifyReport
                onSaveAndClose={() =>
                  history.push({ ...location, pathname: `${match.url}/${props.match.params.vpage}` })
                }
              />
            )}
          />
          <Route
            path={`${match.path}/:vpage*`}
            render={() => {
              const sectionIsVisible = iAmTalentOrg || gigIsVerified;

              return (
                <Blurred
                  blur={!sectionIsVisible}
                  link={
                    <a href="https://help.gopinata.com/en/articles/4231577" target="_blank">
                      {t("gigs.cells.reportHelp")}
                    </a>
                  }
                  bannerText={t(`gigs.cells.blurredSectionBanner`)}
                >
                  <div className={styles.viewReport}>
                    <FieldReportView loadingGigDetails={!data} gig={data && data.report ? data : null} />
                  </div>
                </Blurred>
              );
            }}
          />
        </Switch>
      </div>
    );
  },
  icon,
  fragment,
};
