import * as React from "react";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose, isEqual } from "lodash";
import { MutationFunction } from "@apollo/client";
import { withFormik, FormikProps } from "formik";
import { RouteComponentProps } from "react-router";

import { EditUserPermissionsModalProgramOptionsFragment } from "gql-gen";

import LoadingContainer from "components/LoadingContainer";
import LegacyModal from "components/LegacyModal";
import Text from "components/LegacyText";
import Form from "components/Form";
import ModalHeader from "components/ModalHeader";
import ModalFooter from "components/ModalFooter";
import { articles } from "support-articles";
import { inputProps } from "utilities/formik";
import { getUrlQueries } from "utilities/routes";
import {
  UserType,
  USER_TYPES,
  USER_TYPE_NAMES,
  USER_TYPE_DESCRIPTIONS,
  getManageableUserTypes,
  canSpecifyPrograms,
  User,
  OrganizationUser,
} from "interfaces/user";
import { CloseModalHandler } from "utilities/modals";
import LegacySearchableSelect from "components/LegacySearchable/SearchableSelect";
import SelectedItemsList from "components/Table/SelectedItemsList";
import Switch from "components/Switch";
import { Program } from "modules/Dashboard/interfaces/Program";
import Clickable from "components/Clickable";
import { SearchableSelect } from "components/SearchableSelect";
import { connection, ConnectionConfig } from "utilities/connections";

import editUserIcon from "assets/edit-user.svg";
import styles from "./styles.scss";

import programFragment from "./program.gql";
import userQuery from "./user.gql";
import editMutation from "./edit.gql";
import inviteDetailsQuery from "./inviteDetails.gql";
import programGroupsQuery from "./programGroupsQuery.gql";

import { OrganizationRouteParams } from "../../index";
import SelectAllBar from "../../../../../components/SelectAllBar";
import { userProgramsCondition } from "../../Programs/userProgramsFilter";

interface UserPermissionsValues {
  userType: UserType;
  programs: string[];
  contactOnProgramIds: string[];
  editingAdminOrOwnerNotifications: boolean;
  programGroup: { name: string; id: string; programIds: string[] };
  canRequest: boolean;
  canSelfAssign: boolean;
}

interface EditUserPermissionsRouteParams extends OrganizationRouteParams {
  userId: string;
}

type OrganizationDetails = { gigRequestsActive: boolean; gigSelfAssignmentActive: boolean };

interface Props extends FormikProps<UserPermissionsValues>, RouteComponentProps<EditUserPermissionsRouteParams> {
  handleClose: CloseModalHandler;
  userTypes: UserType[];
  programs: Program[];
  data: {
    user: User;
    organizationUser: OrganizationUser;
    loading: boolean;
  };
  mutate: MutationFunction<unknown, unknown>;
  programGroups: { id: string; name: string; programIds: string[] }[];
  programGroupsVisible: boolean;
  organization: OrganizationDetails;
}

export const programConnection: ConnectionConfig<any> = connection({
  name: "EditUserPermissionsModalProgramOptions",
  entry: { name: "programConnections" },
  variables: { search: "String" },
});

class EditFormModal extends React.PureComponent<Props> {
  public componentDidUpdate({ data: { user: pUser } }: Props) {
    const {
      data: { user },
      setFieldValue,
    } = this.props;

    if (user && (!pUser || pUser.id !== user.id)) {
      setFieldValue("userType", user.userType);
      setFieldValue(
        "programs",
        user.programs.map(p => p.id),
      );
      setFieldValue(
        "contactOnProgramIds",
        (user.programs || []).filter(({ isContact }) => isContact).map(({ id }) => id),
      );
    }
  }

  getSelected() {
    if (!this.props.values?.programs?.length || !this.props.programs?.length) return [];
    return (this.props.programs.filter(({ id }) => this.props.values.programs.includes(id)) || []).map(p => ({
      ...p,
      label: `${p.name} ${p.managementState === "draft" ? " (DRAFT)" : ""}`,
    }));
  }

  public renderContent() {
    const {
      values,
      errors,
      setFieldValue,
      userTypes,
      programs,
      programGroups,
      programGroupsVisible,
      organization,
      data: { user },
    } = this.props;

    const input = inputProps(this.props);

    const learnMore = (
      <Text.NewLink4 href={articles.userPermissions} target={"_blank"}>
        Learn more.
      </Text.NewLink4>
    );

    const canSpecifyProgramsForSelectedUserType = canSpecifyPrograms(
      values.userType,
      values.canRequest,
      values.canSelfAssign,
    );

    return (
      <div className={styles.content}>
        <Text.P4 className={styles.copy}>
          Use this form to define a user's permissions around programs access and functionality.&nbsp;
          {learnMore}
          <br />
          <br />
          <span className={styles.proTip}>User: </span>
          <span className={styles.pink}>
            {user.firstName} {user.lastName}
          </span>
          &nbsp;(
          {user.email ?? user.unconfirmedEmail})
        </Text.P4>
        <Form.Section>
          <Form.Dropdown
            value={values.userType === "gogetter" ? "go" : "dashboard"}
            onChange={e => {
              setFieldValue("canRequest", false);
              setFieldValue("canSelfAssign", false);

              if (e.target.value === "dashboard") {
                setFieldValue("userType", "member");
              } else {
                setFieldValue("userType", "gogetter");
              }
            }}
            label={"USER TYPE"}
          >
            <option value="dashboard">Power User (Access to this Dashboard)</option>
            <option value="go">GoGetter (Access to Go Portal)</option>
          </Form.Dropdown>

          {values.userType === "gogetter" ? (
            <>
              {organization.gigRequestsActive && (
                <Switch
                  id="can-request"
                  checked={values.canRequest}
                  onChange={() => setFieldValue("canRequest", !values.canRequest)}
                  labelText="Requester (can request tasks in shared programs)"
                  className={styles.canSelfAssign}
                  labelTextClassName={styles.canSelfAssignLabel}
                />
              )}

              {organization.gigSelfAssignmentActive && (
                <Switch
                  id="can-self-assign"
                  checked={values.canSelfAssign}
                  onChange={() => setFieldValue("canSelfAssign", !values.canSelfAssign)}
                  labelText="Autonomous (can create tasks for themselves in shared programs)"
                  className={styles.canSelfAssign}
                  labelTextClassName={styles.canSelfAssignLabel}
                />
              )}
            </>
          ) : (
            <Form.Dropdown
              {...input("userType")}
              onChange={v => {
                setFieldValue("editingAdminOrOwnerNotifications", false);
                if (
                  canSpecifyProgramsForSelectedUserType &&
                  !canSpecifyPrograms(v.target.value as UserType, values.canRequest, values.canSelfAssign)
                ) {
                  setFieldValue(
                    "programs",
                    programs.map(p => p.id),
                  );
                }
                input("userType").onChange(v);
              }}
              label={"LEVEL"}
            >
              {userTypes
                .filter(t => t !== "gogetter")
                .map(ut => (
                  <option key={ut} value={ut}>
                    {USER_TYPE_NAMES[ut]}
                  </option>
                ))}
            </Form.Dropdown>
          )}
          {canSpecifyProgramsForSelectedUserType && programGroupsVisible && (
            <LegacySearchableSelect
              name={"FILTER PROGRAMS BY PROGRAM GROUP"}
              placeholder={"Search by name"}
              selectedItem={values.programGroup?.id}
              items={[{ id: "all", name: "All" }, ...(programGroups ?? [])]}
              onChange={v => setFieldValue("programGroup", v)}
            />
          )}

          {canSpecifyProgramsForSelectedUserType && (
            <React.Fragment>
              <SearchableSelect<EditUserPermissionsModalProgramOptionsFragment>
                label="SELECT PROGRAMS"
                placeholder={"Search by name"}
                multiple
                value={values.programs}
                fragment={programFragment}
                connection={programConnection}
                variables={{
                  programIds: [],
                  filters: userProgramsCondition(values.programGroup.id),
                }}
                renderName={program => program?.name ?? "Unnamed"}
                onChange={v => setFieldValue("programs", v)}
                itemNamePlural="programs"
              />

              <SelectAllBar
                items={(values.programGroup.id === "all"
                  ? programs
                  : programs.filter(p => values.programGroup.programIds.includes(p.id))
                ).map(p => p.id)}
                value={values.programs}
                onChange={v => setFieldValue("programs", v)}
                label={"Select All Active Programs"}
              />
            </React.Fragment>
          )}

          {(canSpecifyProgramsForSelectedUserType || values.userType === "admin" || values.userType === "owner") && (
            <div className={styles.manageNotificationsContainer}>
              <Switch
                onChange={() => {
                  setFieldValue("editingAdminOrOwnerNotifications", !values.editingAdminOrOwnerNotifications);
                }}
                checked={values.editingAdminOrOwnerNotifications}
                labelText={"Manage notifications?"}
                id="notificationsControl"
                labelTextClassName={styles.manageNotificationsLabel}
              />
            </div>
          )}

          {(canSpecifyProgramsForSelectedUserType || values.userType === "admin" || values.userType === "owner") &&
            values.editingAdminOrOwnerNotifications && (
              <SelectedItemsList
                selected={this.getSelected()}
                renderCounter={count => `${count} ${count > 1 ? "programs" : "program"} selected.`}
                renderItemToggle={id => (
                  <Switch
                    onChange={() => {
                      setFieldValue(
                        "contactOnProgramIds",
                        this.props.values.contactOnProgramIds.includes(id)
                          ? this.props.values.contactOnProgramIds.filter(pId => pId !== id)
                          : [...this.props.values.contactOnProgramIds, id],
                      );
                    }}
                    checked={this.props.values.contactOnProgramIds.includes(id)}
                    labelText={"Receive notifications?"}
                    id={id}
                  />
                )}
                renderToggleAll={
                  <Clickable
                    onClick={() => {
                      if (isEqual(this.props.values.contactOnProgramIds, this.props.values.programs)) {
                        setFieldValue("contactOnProgramIds", []);
                      } else {
                        setFieldValue("contactOnProgramIds", this.props.values.programs);
                      }
                    }}
                  >
                    <Text.H3 className={styles.toggleAll}>Toggle All</Text.H3>
                  </Clickable>
                }
              />
            )}
        </Form.Section>
        <Text.P4>
          {USER_TYPE_DESCRIPTIONS[values.userType]} {learnMore}
        </Text.P4>

        {errors.userType && <Text.Message kind={"error"}>{errors.userType}</Text.Message>}
      </div>
    );
  }

  public render() {
    const {
      handleSubmit,
      handleClose,
      isSubmitting,
      data: { loading },
    } = this.props;

    return (
      <LegacyModal noPadding className={styles.modal}>
        <ModalHeader icon={editUserIcon} mainAction={"Edit team member"} onClose={this.props.handleClose} />

        {loading ? (
          <LoadingContainer className={styles.loadingUser} message={"Loading user details..."} />
        ) : (
          this.renderContent()
        )}

        <ModalFooter
          actionName={"UPDATE USER"}
          actionButtonType={"submit"}
          actionDisabled={loading || isSubmitting}
          onAction={() => handleSubmit()}
          onCancel={() => handleClose()}
        />
      </LegacyModal>
    );
  }
}

export default compose(
  graphql<any, { me: User; organization: OrganizationDetails }, any, any>(inviteDetailsQuery, {
    options: props => ({
      variables: {
        orgId: props.match.params.orgId,
      },
      fetchPolicy: "network-only",
    }),
    props: props => {
      const { data } = props;

      if (!data || !data.me) {
        return {
          programs: [],
          userTypes: [],

          organization: { gigRequestsActive: false, gigSelfAssignmentActive: false },
        };
      }

      const { programs, organizationUser } = data.me;

      let userTypes = getManageableUserTypes(data.me);

      if (
        organizationUser.organizationType !== "agency" &&
        !programs.find(program => program.executionType === "solo")
      ) {
        userTypes = userTypes.filter(u => u !== "gogetter");
      }

      return {
        userTypes,
        programs: programs.map(({ id, name, managementState }) => ({ id, name, managementState })),
        organization: data.organization,
      };
    },
  }),

  graphql<any, any, any, any>(userQuery, {
    options: props => ({
      variables: {
        userId: props.match.params.userId,
        orgId: props.match.params.orgId,
      },
      fetchPolicy: "network-only",
    }),
  }),
  graphql<any, any, any, any>(programGroupsQuery, {
    options: props => ({
      variables: {
        orgId: props.match.params.orgId,
      },
    }),
    props: ({ data: { programGroups, organization } = {} as any }) => ({
      programGroups,
      programGroupsVisible: organization?.programGroupsVisible,
    }),
  }),

  graphql(editMutation),

  withFormik<Props, UserPermissionsValues>({
    mapPropsToValues: ({ data: { user, organizationUser }, location: { search } }: Props) => {
      const queries = getUrlQueries(search);
      return {
        userType: (user && user.userType) || (queries.userType as UserType),
        programs: user ? user.programs.map(p => p.id) : [],
        contactOnProgramIds: (user?.programs ?? []).filter(({ isContact }) => isContact).map(({ id }) => id),
        editingAdminOrOwnerNotifications: false,
        programGroup: { id: "all", name: "All", programIds: [] },
        canRequest: organizationUser?.canRequest,
        canSelfAssign: organizationUser?.canSelfAssign,
      };
    },

    enableReinitialize: true,

    validate: async values => {
      const errors: { userType?: string } = {};

      if (!USER_TYPES.includes(values.userType)) {
        errors.userType = "Please select a permission level.";
      }

      if (Object.keys(errors).length > 0) {
        throw errors;
      }
    },

    handleSubmit: async (values, formikProps) => {
      const {
        mutate,
        match: {
          params: { userId },
        },

        location: { search },
        history,
        handleClose,
      } = formikProps.props;

      const canSpecifyProgramsForSelectedUserType = canSpecifyPrograms(
        values.userType,
        values.canRequest,
        values.canSelfAssign,
      );

      await mutate({
        variables: {
          input: {
            userType: values.userType,
            programIds:
              canSpecifyProgramsForSelectedUserType || values.editingAdminOrOwnerNotifications ? values.programs : [],
            userIds: [userId],
            contactOnProgramIds: values.contactOnProgramIds,
            canRequest: values.canRequest,
            canSelfAssign: values.canSelfAssign,
          },
          userId,
        },
      });

      const { next } = getUrlQueries(search);

      if (next) {
        history.replace(next);
      } else {
        handleClose();
      }
    },
  }),
)(EditFormModal);
