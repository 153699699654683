import { useQuery, useMutation } from "@apollo/client";
import React from "react";
import { useState, useMemo } from "react";
import * as _ from "lodash";
import * as yup from "yup";
import { RouteComponentProps, Switch, Route, Redirect } from "react-router-dom";
import moment from "moment";
import { User } from "interfaces/user";
import LoadingContainer from "components/LoadingContainer";
import LegacyModal from "components/LegacyModal";
import ModalFooter from "components/ModalFooter";
import RouteTabs from "components/RouteTabs";
import LegacyText from "components/LegacyText";
import UserAvatar from "components/UserAvatar";
import Button from "components/Button";
import RadioButton from "components/RadioButton";
import RadioGroup from "components/RadioGroup";
import Form from "components/Form";
import ImageGroup from "components/ImageGroup";
import ImageOpener from "components/ImageGroup/ImageOpener";
import FilePicker from "components/Form/FilePicker";
import Shortcuts, { shortcut } from "components/Shortcuts";
import { getUrlQueries } from "utilities/routes";
import { Formik } from "formik";
import { inputProps } from "utilities/formik";
import TagsInput from "../../../../../components/TagsInput";
import styles from "./styles.scss";
import userQuery from "./userQuery.gql";
import organizationUserQuery from "./organizationUserQuery.gql";
import upsertOrganizationUser from "./upsertOrganizationUser.gql";
import { CloseModalHandler } from "utilities/modals";
import { OrganizationRouteParams } from "../../index";
import { OrganizationUser, Document, TagGroup, Tag } from "gql-gen";
import { DocumentLink } from "modules/Dashboard/Schema/Documents/DocumentLink";
import cx from "classnames";
import { useUserInfo } from "modules/Dashboard/UserInfo";
import useRouter from "use-react-router";

interface GoGetterRouteParams extends OrganizationRouteParams {
  userId: string;
}

interface Props extends RouteComponentProps<GoGetterRouteParams> {
  handleClose: CloseModalHandler;
  user: User;
}

interface UserDocument {
  id: string;
  url: string;
  name: string;
}

interface InternalValues {
  legalName: string | null;
  employeeId: string | null;
  tagIds: string[];
  organizationNotes: string;
  documents: Document[];
  defaultRate: number | null;
  salaried: boolean;
}

interface Column {
  title?: string;
  content?: React.ReactNode | null;
}

interface OrgUserQuery {
  organizationUser: OrganizationUser;
}

interface UserQuery {
  user: User;
}

interface RouteProps {
  userId: string;
  orgId: string;
  gigId?: string;
}

const INTERNAL_DATA_SCHEMA = yup.object().shape({
  name: yup.string().required("Required"),
  description: yup.string(),
});

function GogetterModal({ handleClose }: { handleClose: CloseModalHandler }) {
  const { me, loading: meLoading } = useUserInfo();
  const { location, match } = useRouter<RouteProps>();
  const { orgId, userId } = match.params;
  const gigId = useGigId();

  const { data: orgUserData, loading: orgUserLoading } = useQuery<OrgUserQuery>(organizationUserQuery, {
    variables: { userId, orgId },
  });

  const { data: userData, loading: userLoading } = useQuery<UserQuery>(userQuery, {
    variables: { userId, gigId },
  });

  const [isEditing, setIsEditing] = useState(false);
  const user = userData?.user;
  const organizationUser = orgUserData?.organizationUser;

  if (meLoading || orgUserLoading || userLoading || !user) {
    return (
      <LegacyModal noPadding className={styles.modal}>
        <LoadingContainer message="Loading details..." center className={styles.loadingDetails} />
      </LegacyModal>
    );
  }

  const viewingOwner = location.pathname.includes("/owner");

  const showContactTab =
    !!(user && (user.email || user.unconfirmedEmail)) &&
    (viewingOwner || organizationUser || me?.organizationUser?.organizationType !== "client");

  const showInternalTab = !!organizationUser;

  const showProfileTab =
    user && user.isCorporate
      ? organizationUser
        ? !!user.profile
        : false
      : !!user.profile && (organizationUser || me?.organizationUser?.organizationType !== "client");

  const showCount = [showProfileTab, showContactTab, showInternalTab].filter(f => f).length;

  const pics = user?.assets
    ? _.filter(
        _.sortBy(user.assets, a => (a.isPrimary ? 0 : 1)),
        { assetType: "profile_image" },
      )
    : [];

  const hc = () => handleClose();

  return (
    <Shortcuts shortcuts={[shortcut("esc", hc)]}>
      <LegacyModal noPadding className={styles.modal}>
        {user ? (
          <ImageGroup>
            {pics.map((p, i) => (
              <ImageOpener src={p.url} caption="" key={i}>
                {i === 0 && <UserAvatar user={user} square fadeToWhite className={styles.userModal} />}
              </ImageOpener>
            ))}
          </ImageGroup>
        ) : (
          <UserAvatar user={user} square fadeToWhite className={styles.userModal} />
        )}

        <div className={styles.userInfo}>
          <LegacyText.Display2 className={styles.userName}>
            {user.firstName} {user.kind === "human" && !showInternalTab ? `${user.lastName.charAt(0)}.` : user.lastName}
          </LegacyText.Display2>
          {showCount > 1 && (
            <RouteTabs
              compact
              invertedBg
              tabs={[
                showContactTab ? { title: "Contact", path: "contact/:gigId" } : null,
                showProfileTab ? { title: "Profile", path: "profile" } : null,
                showInternalTab ? { title: "Internal", path: "internal" } : null,
              ]}
            />
          )}
          <Switch>
            {showContactTab && (
              <Route
                path={`${match.path}/contact/:gigId`}
                render={_ => <ContactTab user={user} orgUser={organizationUser} />}
              />
            )}
            {showProfileTab && <Route path={match.path + "/profile"} render={_ => <ProfileTab user={user} />} />}
            {showInternalTab && (
              <Route
                path={match.path + "/internal"}
                render={_ => (
                  <InternalTab
                    setIsEditing={setIsEditing}
                    isEditing={isEditing}
                    orgUser={orgUserData.organizationUser}
                  />
                )}
              />
            )}
            {showCount > 0 && <Redirect to={{ ...location, pathname: match.url + "/profile" }} />}
          </Switch>
        </div>

        <ModalFooter cancelButtonText="CLOSE" onCancel={hc} hideAction />
      </LegacyModal>
    </Shortcuts>
  );
}

function InfoTable({ columns }: { columns: Column[] }) {
  return (
    <div className={styles.infoTable}>
      {columns.map(({ title, content }) =>
        content ? (
          <div className={styles.infoRow} key={title}>
            <LegacyText.H4 className={styles.title}>{title}</LegacyText.H4>
            <LegacyText.P4 kind={content ? "primary" : "tertiary"}>{content || "Not specified"}</LegacyText.P4>
          </div>
        ) : null,
      )}
    </div>
  );
}

const ProfileTab: React.FC<{ user: User }> = ({ user }) => {
  const profile = user?.profile;
  const documents = user?.documents || [];

  const goGetterInfoColumns = useMemo(() => {
    const profileColumns = profile
      ? [
          { title: "About", content: profile.selfSummary },
          { title: "Home", content: profile.zip },
          { title: "Shirt Size", content: profile.shirtSize },
          {
            title: "Birthday",
            content:
              profile.birthday &&
              moment(profile.birthday)
                .format("MM/DD/YYYY")
                .toString(),
          },
          { title: "Can drive?", content: profile.canDrive === null ? undefined : profile.canDrive ? "Yes" : "No" },
          { title: "Has car?", content: profile.hasCar === null ? undefined : profile.hasCar ? "Yes" : "No" },
          { title: "Languages spoken", content: profile.languages?.map(({ label }) => label).join(", ") },
          { title: "Work area", content: profile.areas },
          { title: "Address", content: profile.address },
          { title: "Apt/Floor/Unit", content: profile.apartment },
        ]
      : [];

    const documentColumns =
      documents.length > 0
        ? [
            {
              title: "Files",
              content: (
                <div className={styles.filesContainer}>
                  {documents.map(d => (
                    <DocumentLink key={d.id} document={d} />
                  ))}
                </div>
              ),
            },
          ]
        : [];

    return [...profileColumns, ...documentColumns].filter(({ content }) => Boolean(content));
  }, [profile, documents]);

  return goGetterInfoColumns.length > 0 ? (
    <InfoTable columns={goGetterInfoColumns} />
  ) : (
    <LegacyText.P4 kind="tertiary">This user has no profile information</LegacyText.P4>
  );
};

interface ProfileFormProps {
  onSubmit: () => void;
  orgUser: OrganizationUser;
}

const ProfileForm: React.FC<ProfileFormProps> = ({ onSubmit, orgUser }) => {
  const { orgId, userId } = useRouter<RouteProps>().match.params;
  const [mutate] = useMutation(upsertOrganizationUser);

  const initialValues: InternalValues = {
    legalName: orgUser.legalName ?? null,
    employeeId: orgUser.employeeId ?? null,
    tagIds: orgUser.tags ? orgUser.tags.filter((tag): tag is Tag => tag !== null).map(t => t.id) : [],
    organizationNotes: orgUser.organizationNotes ?? "",
    defaultRate: orgUser.defaultRate ?? null,
    documents: orgUser.documents ? orgUser.documents.filter((doc): doc is Document => doc !== null) : [],
    salaried: orgUser.salaried ?? false,
  };

  const handleSubmit = async (values: InternalValues) => {
    await mutate({
      variables: {
        input: {
          ...values,
          id: userId,
          documents: values.documents.map(d => ({ id: d.id, name: d.name, url: d.url })),
          organizationId: orgId,
        },
      },
      update: (cache, { data: { upsertOrganizationUser } }) => {
        const query = {
          query: organizationUserQuery,
          variables: { userId, orgId },
        };
        const orgUserQuery = cache.readQuery<OrgUserQuery>(query);

        if (orgUserQuery && orgUserQuery.organizationUser) {
          cache.writeQuery({
            ...query,
            data: { organizationUser: upsertOrganizationUser },
          });
        }
      },
    });

    onSubmit();
  };

  return (
    <Formik<InternalValues>
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={INTERNAL_DATA_SCHEMA}
      onSubmit={handleSubmit}
    >
      {props => {
        const input = inputProps(props);
        const { values, setFieldValue } = props;

        return (
          <>
            <LegacyText.Label2 className={styles.label}>Basic info</LegacyText.Label2>
            <Form.Section className={styles.section}>
              <Form.HorizontalGroup>
                <Form.TextBox
                  {...input("legalName")}
                  className={cx(styles.textBox, styles.legalName)}
                  label="Legal Name"
                  placeholder="Type here..."
                />
                <Form.TextBox
                  {...input("employeeId")}
                  className={styles.textBox}
                  label="Employee Id"
                  placeholder="Type here..."
                />
              </Form.HorizontalGroup>
            </Form.Section>
            <Form.Section className={styles.section}>
              <TagsInput
                organizationId={orgId || ""}
                group={TagGroup.Users}
                value={values.tagIds}
                onChange={t => setFieldValue("tagIds", t)}
              />
            </Form.Section>
            <LegacyText.Label2 className={styles.label}>My notes</LegacyText.Label2>
            <Form.Section className={styles.section}>
              <Form.TextBox {...input("organizationNotes")} placeholder={"Organization Notes"} multiline />
            </Form.Section>
            <LegacyText.Label2 className={styles.label}>Default hourly rate</LegacyText.Label2>
            <Form.Section className={styles.section}>
              <div className={styles.moneyBoxInputContainer}>
                <LegacyText.H3 className={styles.rate}>Default hourly rate</LegacyText.H3>
                <Form.DecimalBox
                  onChange={rate => setFieldValue("defaultRate", rate)}
                  value={values.defaultRate}
                  className={styles.noBorderMoneyBox}
                />
              </div>
            </Form.Section>
            <LegacyText.Label2 className={styles.label}>Employment type</LegacyText.Label2>
            <RadioGroup
              className={styles.section}
              value={values.salaried ? "w2" : "1099"}
              onChange={value => setFieldValue("salaried", value === "w2")}
            >
              <RadioButton value="w2">Salaried/W2</RadioButton>
              <RadioButton value="1099">Freelancer/1099</RadioButton>
            </RadioGroup>
            <div className={styles.section}>
              <LegacyText.Label2 className={styles.label}>Documents</LegacyText.Label2>
              <FilePicker
                size={77}
                value={values.documents.map(({ url, name }) => ({ url, caption: name })) || []}
                onChange={vals =>
                  setFieldValue(
                    "documents",
                    vals.map(({ url, caption }) => ({ url, name: caption })),
                  )
                }
                caption
                accepts={".pdf,.doc,.txt,.docx,.xls,.xlsx"}
                secure
              />
            </div>
            <Button kind="primary" onClick={() => handleSubmit(values)} type="submit" className={styles.button}>
              Save internal info on gogetter
            </Button>
          </>
        );
      }}
    </Formik>
  );
};

function InternalTab({
  setIsEditing,
  isEditing,
  orgUser,
}: {
  setIsEditing: (b: boolean) => void;
  isEditing: boolean;
  orgUser: OrganizationUser;
}) {
  const tags = orgUser.tags ? orgUser.tags.filter((tag): tag is Tag => tag !== null) : [];

  const documents: Document[] = orgUser.documents
    ? orgUser.documents.filter((doc): doc is Document => doc !== null)
    : [];

  const tableColumns = [
    { title: "Legal Name", content: orgUser.legalName },
    { title: "Employee Id", content: orgUser.employeeId },
    {
      title: "Tags",
      content: tags.length > 0 && (
        <div className={styles.tagsContainer}>
          {tags.map((tag, i, arr) => (
            <LegacyText.P3 key={i} className={styles.tag}>
              {tag.name}
              {arr.length - 1 > i && ", "}
            </LegacyText.P3>
          ))}
        </div>
      ),
    },
    { title: "My notes", content: orgUser.organizationNotes },
    {
      title: "Default hourly rate",
      content: orgUser.defaultRate ? `$${orgUser.defaultRate}/h` : null,
    },
    { title: "Employment type", content: orgUser.salaried ? "Salaried/W2" : "Freelancer/1099" },
    {
      title: "Files",
      content: documents?.length > 0 && (
        <div className={styles.filesContainer}>
          {documents.map(d => (
            <DocumentLink key={d.id} document={d} />
          ))}
        </div>
      ),
    },
  ].filter(({ content }) => Boolean(content));

  return (
    <>
      {isEditing ? (
        <ProfileForm onSubmit={() => setIsEditing(false)} orgUser={orgUser} />
      ) : (
        <InfoTable columns={tableColumns} />
      )}
      {!isEditing && (
        <Button kind="primary" onClick={() => setIsEditing(true)} className={styles.button}>
          Edit internal info on gogetter
        </Button>
      )}
    </>
  );
}

function ContactTab({ user, orgUser }: { user: User; orgUser?: OrganizationUser }) {
  const { gigId } = useRouter<RouteProps>().match.params;

  const { firstName, lastName, email, unconfirmedEmail, phone, unconfirmedPhone, isCorporate } = user;

  const baseInfo = [{ title: "Full Name", content: `${firstName} ${lastName}` }];
  const contact = [
    { title: "Email", content: email || unconfirmedEmail },
    { title: "Mobile", content: phone || unconfirmedPhone },
  ];
  const contactInfo = isCorporate ? (orgUser || gigId ? contact : []) : contact;
  return <InfoTable columns={[...baseInfo, ...contactInfo]} />;
}

function useGigId(): string | undefined {
  const { location } = useRouter();

  const params = getUrlQueries(location.search);
  let gigId;

  if (params.continue) {
    const match = params.continue.match(/\+assign-gigs\/([0-9a-f-]+)\//);
    if (match && match.length > 1) {
      gigId = match[1];
    }
  } else if (location.pathname.includes("/contact/")) {
    const match = location.pathname.match(/\/contact\/([0-9a-f-]+)\//);
    if (match && match.length > 1) {
      gigId = match[1];
    }
  } else {
    const match = location.pathname.match(/\+view\/([0-9a-f-]+)\//);
    if (match && match.length > 1) {
      gigId = match[1];
    }
  }

  return gigId;
}

export default GogetterModal;
