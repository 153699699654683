import React, { useState } from "react";
import cx from "classnames";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import useRouter from "use-react-router";
import { useTranslation } from "react-i18next";

import searchPlusIcon from "assets/search-plus-solid.svg";
import ellipsisIcon from "assets/ellipsis.svg";

import variables, { colors } from "styles/variables";
import { useScopeContext, useScope, ScopeCtx } from "modules/Connection/Scope";
import Icon from "components/Icon";
import Clickable from "components/Clickable";
import Checkbox from "components/Form/Checkbox";
import Text from "components/Text";
import { Block } from "modules/Connection/Block";
import { ImpatientPopup } from "components/ImpatientPopup";
import Button from "components/Button";
import { PopupBodyProps } from "components/Popup";
import { ActionTitle } from "modules/Connection/ActionTitle";
import { ScopeErrors } from "modules/Connection/ScopeErrors";

import styles from "./styles.scss";

import { Column, Item, AvailableAction } from "../../types";
import { TableMode } from "..";

const MAX_WIDTH = parseInt(variables.desktop, 10) + 200;

export interface RowProps<T> {
  mode: TableMode;
  columns: Column<T>[];
  data: T;
  checked: boolean;
  onCheck: Function;
  className?: string;
  rowNumber?: number;
  highlight?: boolean;
  buildViewLink: (id: string) => string;
  onCellClick?: (id: string) => void;
  noActionsRenderer?: (inPopup: boolean) => React.ReactNode;
  isSelectable?: boolean;
  isEditable?: boolean;
}

export function Row<T extends Item>({
  columns,
  data,
  checked,
  onCheck,
  mode,
  className,
  rowNumber,
  highlight,
  buildViewLink,
  onCellClick,
  noActionsRenderer,
  isSelectable = true,
  isEditable = true,
}: RowProps<T>) {
  const { location } = useRouter();

  const ctx = useScopeContext();

  const rowScope = useScope({
    connection: ctx.connection,
    variables: ctx.variables,
    ids: [data.id],
    blocks: {},
    ready: true,
    onSave: ctx.onSave,
    actions: ctx.actions,
    getErrorMessage: ctx.getErrorMessage,
  });

  const anyEditing = ctx.editing;
  const editing = rowScope.editing;
  const editable = isEditable && (!anyEditing || editing);

  const cells = columns.map((column, index) => {
    return (
      <Block<T>
        index={index}
        id={column.id}
        key={column.id}
        form={column.form}
        cell={column.cell}
        node={data}
        mode={mode}
        em={false}
        className={styles.cell}
        onCellClick={onCellClick}
        isEditable={column.isEditable ?? isEditable}
      />
    );
  });

  const viewLink = { ...location, pathname: buildViewLink(data.id) };

  return (
    <ScopeCtx.Provider value={rowScope}>
      <div
        className={cx(
          styles.rowWrapper,
          editable && styles.actionable,
          (editing || highlight) && styles.inAction,
          highlight && styles.highlight,
        )}
      >
        <div tabIndex={0} role="row" data-row-id={data.id} className={cx(styles.row, className)}>
          {isSelectable && (
            <div
              className={cx(
                styles.checkboxWrapper,
                mode === "compact" && styles.compactCheckbox,
                checked && styles.checked,
              )}
            >
              {rowNumber && (
                <div>
                  <Text font={"wes"} color="gray1" size={10}>
                    {rowNumber}
                  </Text>
                </div>
              )}
              <Checkbox
                checked={checked}
                onCheck={() => {
                  onCheck(data.id);
                }}
              />
            </div>
          )}

          {mode === "compact" ? <div className={styles.mobileCells}>{cells}</div> : cells}

          <div className={styles.actions}>
            <Link to={viewLink} className={styles.mainAction} data-test="connections.table.row.expand">
              <Icon src={searchPlusIcon} fill={colors.white} size={22} />
            </Link>
            <div className={styles.actionDiv} />
            <ImpatientPopup
              childrenWrapperProps={{ className: styles.allActions, testId: "connections.table.row.actions.more" }}
              fetch={() => rowScope.getActions().then(({ actions }) => ({ actions, gigId: data.id }))}
              dependencies={[data.id]}
              component={ActionsPopup(noActionsRenderer)}
              containerClassName={styles.actionsPopup}
            >
              <Icon src={ellipsisIcon} rotate={90} fill={colors.white} size={18} />
            </ImpatientPopup>
          </div>
        </div>
      </div>
    </ScopeCtx.Provider>
  );
}

function ActionsPopup(noActionsRenderer?: (inPopup: boolean) => React.ReactNode) {
  return function({
    data: { actions, gigId },
    close,
  }: { data: { actions: AvailableAction[]; gigId: string } } & PopupBodyProps) {
    const { t } = useTranslation();
    const { connection, variables, reload } = useScopeContext();
    const [active, setActive] = useState<AvailableAction | null>(null);
    const { history, location } = useRouter();

    if (active) {
      return (
        <div className={styles.actionContent} data-test="connections.table.row.actions.active">
          <ActionTitle>{t(active.action.labelKey)}</ActionTitle>

          {React.createElement(active.action.component, {
            isBatchAction: false,
            done: () => {
              close();
              reload();
            },
            count: active.count,
            id: gigId,
            connection,
            variables,
            renderSubmit: ({ handleSubmit, i18n, disabled }) => {
              return (
                <>
                  <ScopeErrors className={styles.actionErrors} />
                  <Button
                    testId={`connections.table.row.actions.active.submit`}
                    type="submit"
                    kind="primaryGradient"
                    onClick={handleSubmit}
                    className={styles.actionSubmit}
                    disabled={disabled}
                  >
                    {t(`${i18n}.submit`)}
                  </Button>
                </>
              );
            },
            renderNavButtons: () => {
              return (
                <>
                  <ScopeErrors className={styles.actionErrors} />
                  <Button testId={`connections.table.row.actions.close`} onClick={close}>
                    {t("components.table.row.closeAction")}
                  </Button>
                </>
              );
            },
          })}
        </div>
      );
    }

    const setActiveOrRedirect = (availableAction: AvailableAction, redirect: boolean) => {
      if (redirect) {
        history.push({ ...location, pathname: `${location.pathname}/+view/${gigId}/${availableAction.action.key}` });
      } else {
        setActive(availableAction);
      }
    };

    return (
      <div className={styles.actionList} data-test="connections.table.row.actions.items">
        {actions.length > 0
          ? actions.map(availableAction => {
              const { action } = availableAction;
              return (
                <Clickable
                  key={action.key}
                  testId={`connections.table.row.actions.item.${action.key}`}
                  className={styles.actionItem}
                  onClick={() => setActiveOrRedirect(availableAction, !action.allowInline)}
                >
                  <Icon src={action.icon} size={18} fill={"white"} />
                  <Text font="wes" bold noSelect left={"xs"} size={14} color="white">
                    {t(action.labelKey)}
                  </Text>
                </Clickable>
              );
            })
          : noActionsRenderer && noActionsRenderer(true)}
      </div>
    );
  };
}

export function RowPlaceholder({ width, height }: { width: number; height?: number }) {
  const { t } = useTranslation();
  return (
    <div className={styles.placeholder}>
      <ContentLoader
        ariaLabel={t("components.table.row.loading")}
        width={Math.min(width, MAX_WIDTH)}
        height={height || 80}
        primaryColor={"rgba(0, 0, 0, 0.03)"}
        secondaryColor={"rgba(0, 0, 0, 0.08)"}
      >
        <rect x="0" y="0" rx="7" ry="7" width="100%" style={{ height: "calc(100% - 4px)" }} />
      </ContentLoader>
    </div>
  );
}
