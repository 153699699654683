import * as React from "react";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { MutationFunction } from "@apollo/client";
import { Formik, FormikProps, FormikHelpers } from "formik";
import { RouteComponentProps } from "react-router-dom";
import _ from "lodash";
import * as yup from "yup";

import {
  DocumentObjectType,
  ProductFormModalBrandOptionsFragment,
  ProductFormModalProgramOptionsFragment,
  TagGroup,
} from "gql-gen";

import Form from "components/Form";
import LegacyModal from "components/LegacyModal";
import Button from "components/Button";
import Shortcuts, { shortcut } from "components/Shortcuts";
import Text from "components/LegacyText";
import NewText from "components/Text";
import LoadingContainer from "components/LoadingContainer";
import { addQueries, stringifyQuery, getProgramIdFromSearch, getUrlQueries } from "utilities/routes";
import { inputProps } from "utilities/formik";
import ModalFooter from "components/ModalFooter";
import ModalHeader from "components/ModalHeader";
import LegacySearchableSelect from "components/LegacySearchable/SearchableSelect";
import { SearchableSelect } from "components/SearchableSelect";
import TagsInput from "components/TagsInput";
import { CloseModalHandler } from "utilities/modals";
import { TableProps } from "components/Table";
import SelectAllBar from "components/SelectAllBar";
import { fromBase64, toBase64 } from "utilities/base64";
import { bin, buildLogicalExpression } from "utilities/knueppel";
import { connection, ConnectionConfig } from "utilities/connections";
import { Document } from "modules/Dashboard/interfaces/Document";
import ImageGroup from "components/ImageGroup";
import FilePicker, { IFile } from "components/Form/FilePicker";

import styles from "./styles.scss";

import productsQuery from "../ProductsTable/products.gql";
import productCategoriesQuery from "./productCategories.gql";
import productDetailsQuery from "./productDetails.gql";
import orgProgramsQuery from "./orgProgramsQuery.gql";
import upsertProduct from "./upsertProduct.gql";
import upsertProfilePictures from "./upsertProfilePictures.gql";
import programGroupsQuery from "./programGroupsQuery.gql";

import { OrganizationRouteParams } from "../../index";
import { OptionConfig } from "../../../interfaces/OptionConfig";
import { Product } from "../../../interfaces/Product";
import { Program } from "../../../interfaces/Program";
import DocumentsTable from "../DocumentsTable";
import { NEW_ID_PLACEHOLDER } from "../DocumentFormModal";

import programFragment from "./program.gql";
import brandFragment from "./brand.gql";

interface ProductRouteParams extends OrganizationRouteParams {
  productId: string;
}

interface Props extends FormikProps<ProductFormValues>, RouteComponentProps<ProductRouteParams> {
  handleClose: CloseModalHandler;
  upsertProductInfo: MutationFunction<any, any>;
  upsertProfilePictures: MutationFunction<{ upsertDocuments: Document[] }>;
  productCategories: OptionConfig[];
  product: Product;
  programs: Program[];
  programGroups: { id: string; name: string; programIds: string[] }[];
  programGroupsVisible: boolean;
}

interface ProductFormValues {
  id: string | null;
  name: string;
  sku: string;
  categoryKey: string | null;
  tagIds: string[];
  description: string;
  msrp: number | null;
  brandId: string;
  documentIds: string[];
  programIds: string[];
  programGroup: { name: string; id: string; programIds: string[] };
  profilePictures: IFile[];
}

interface ProductsQuery {
  products: Product[];
}

interface State {
  savingState: "none" | "saving" | "error";
  hasDocuments: boolean;
  initialValues: ProductFormValues | null;
}

const INITIAL_STATE: State = {
  savingState: "none",
  hasDocuments: false,
  initialValues: null,
};

export const programConnection: ConnectionConfig<ProductFormModalProgramOptionsFragment> = connection({
  name: "ProductFormModalProgramOptions",
  entry: { name: "programConnections" },
  variables: { search: "String" },
});

export const brandConnection: ConnectionConfig<ProductFormModalBrandOptionsFragment> = connection({
  name: "ProductFormModalBrandOptions",
  entry: { name: "brands" },
  variables: { search: "String" },
});

class ProductFormModal extends React.Component<Props, State> {
  readonly state: State = INITIAL_STATE;

  private addAnother: boolean = false;

  private get editing() {
    return this.props.match.path.includes("+edit-products");
  }

  private handleSubmit = async (values: ProductFormValues, { resetForm }: FormikHelpers<ProductFormValues>) => {
    const {
      upsertProductInfo,
      upsertProfilePictures,
      match: {
        params: { orgId },
      },
    } = this.props;

    this.setState({ savingState: "saving" });

    try {
      const profilePictures = await upsertProfilePictures({
        variables: { input: values.profilePictures.map(p => ({ ...p, name: "" })), organizationId: orgId },
      });
      const valuesWithPics = profilePictures?.data?.upsertDocuments
        ? { ...values, profilePictureIds: profilePictures?.data?.upsertDocuments.map(pp => pp.id) }
        : values;

      await upsertProductInfo({
        variables: {
          input: {
            ..._.omit(valuesWithPics, ["programGroup", "profilePictures"]),
            organizationId: this.editing ? null : orgId,
          },
        },

        update: async (cache, { data: { upsertProduct } }) => {
          if (!this.editing) {
            const query = { query: productsQuery, variables: { orgId, state: "active" } };
            const productQuery = cache.readQuery<ProductsQuery>(query);

            if (productQuery && productQuery.products) {
              cache.writeQuery({
                ...query,
                data: { products: productQuery.products.concat([upsertProduct]) },
              });
            }
          }
        },
      });

      if (this.addAnother) {
        this.addAnother = false;
        this.setState(INITIAL_STATE);
        resetForm();
      } else {
        this.handleClose();
      }
    } catch (e) {
      this.setState({ savingState: "error" });
      throw e;
    }
  };
  private handleClose = () => {
    this.props.handleClose(["values", "newDocId"]);
  };

  private goToNewDocument(formikProps: FormikProps<ProductFormValues>) {
    const { history, match, location } = this.props;

    history.push({
      pathname: `${match.url.split("/+")[0]}/+add-documents`,
      search: stringifyQuery({
        next: addQueries(match.url, {
          values: toBase64(JSON.stringify(formikProps.values)),
          newDocId: NEW_ID_PLACEHOLDER,
        }),
        program: getProgramIdFromSearch(location.search),
      }),
    });
  }

  private renderCounter = (count: number) =>
    `${count === 1 ? "One document" : `${count} documents`} added to this product`;

  private renderForm = (formikProps: FormikProps<ProductFormValues>) => {
    const { values, setFieldValue, handleSubmit } = formikProps;
    const {
      programs,
      programGroups,
      programGroupsVisible,
      match: {
        params: { orgId },
      },
    } = this.props;

    const { savingState } = this.state;
    let { hasDocuments } = this.state;

    if (!hasDocuments && values.documentIds && values.documentIds.length > 0) {
      hasDocuments = true;
    }

    let message = <Text.Message>&nbsp;&nbsp;</Text.Message>;

    if (savingState === "saving") {
      message = <Text.Message kind={"neutral"}>Saving…</Text.Message>;
    } else if (savingState === "error") {
      message = <Text.Message kind={"error"}>An error has occurred. Try again.</Text.Message>;
    }

    const input = inputProps(formikProps);
    const hs = () => handleSubmit();

    const editing = this.editing;

    return (
      <Shortcuts shortcuts={[shortcut("s s", hs)]}>
        <div className={styles.form}>
          <Form.Section>
            <Form.DesktopHorizontalGroup>
              <Form.TextBox {...input("name")} label={"PRODUCT NAME"} placeholder={"Write here..."} />
              <Form.TextBox {...input("sku")} className={styles.sku} label={"ID"} placeholder={"ABC123"} />
            </Form.DesktopHorizontalGroup>
          </Form.Section>

          <Form.Section>
            <Form.TextBox
              {...input("description")}
              multiline
              rows={3}
              label={"DESCRIPTION"}
              placeholder={
                "Add a description for this product, which is available as a reference for the person assigned to the task."
              }
            />
          </Form.Section>

          <Form.Section>
            <SearchableSelect<ProductFormModalBrandOptionsFragment>
              label="GROUP"
              placeholder={"Search by name"}
              multiple={false}
              value={values.brandId ? [values.brandId] : []}
              fragment={brandFragment}
              connection={brandConnection}
              variables={{}}
              renderName={brand => brand?.name ?? "Unnamed"}
              onChange={id => setFieldValue("brandId", Array.isArray(id) && id.length > 0 ? id[0] : undefined)}
              itemNamePlural="brands"
            />
          </Form.Section>

          <Form.Section>
            <TagsInput
              organizationId={orgId}
              group={TagGroup.Products}
              value={values.tagIds}
              onChange={t => setFieldValue("tagIds", t)}
            />
          </Form.Section>

          <Form.Section>
            <Form.DecimalBox
              onChange={msrp => setFieldValue("msrp", msrp)}
              value={values && values.msrp}
              label={"MSRP"}
              className={styles.noBorderMoneyBox}
            />
          </Form.Section>

          <Form.Section>
            {programGroupsVisible && (
              <LegacySearchableSelect
                name={"FILTER PROGRAMS BY PROGRAM GROUP"}
                placeholder={"Search by name"}
                selectedItem={values.programGroup?.id}
                items={[{ id: "all", name: "All" }, ...(programGroups ?? [])]}
                onChange={v => setFieldValue("programGroup", v)}
              />
            )}

            <SearchableSelect<ProductFormModalProgramOptionsFragment>
              label="SELECT PROGRAMS"
              placeholder={"Search by name"}
              multiple
              value={values.programIds}
              fragment={programFragment}
              connection={programConnection}
              variables={{
                programIds: [],
                filters: buildLogicalExpression(
                  "&&",
                  [bin("active", "=", true), bin("isowner", "=", true)].concat(
                    values.programGroup.id === "all"
                      ? []
                      : [
                          {
                            type: "BinaryExpression",
                            left: {
                              type: "Literal",
                              value: values.programGroup.id,
                            },
                            operator: "in",
                            right: {
                              type: "Identifier",
                              name: "programgroupids",
                            },
                          },
                        ],
                  ),
                ),
              }}
              renderName={program => program?.name ?? "Unnamed"}
              onChange={ids => setFieldValue("programIds", ids)}
              itemNamePlural="programs"
            />
            <SelectAllBar
              items={(values.programGroup.id === "all"
                ? programs
                : programs.filter(p => values.programGroup.programIds.includes(p.id))
              ).map(p => p.id)}
              value={values.programIds}
              onChange={v => {
                setFieldValue("programIds", v);
              }}
            />
          </Form.Section>
          <hr className={styles.hr} />

          <div className={styles.productPics}>
            <span className={styles.refImageText}>Reference Image</span>
            <NewText color="gray1" font="lato" size={12}>
              Optional. Image only (jpeg, png)
            </NewText>
            <a className={styles.picsHelp} href="https://help.gopinata.com/en/articles/3897578" target="_blank">
              What's this?
            </a>
            <ImageGroup>
              <FilePicker
                size={77}
                value={values.profilePictures || []}
                onChange={pics => setFieldValue("profilePictures", pics)}
                accepts={"image/*, image/heic"}
                testId={"product.image"}
              />
            </ImageGroup>
          </div>

          <hr className={styles.hr} />

          <div className={styles.docCheckbox}>
            <Form.Checkbox
              checked={hasDocuments}
              onCheck={isChecked => {
                this.setState(() => ({
                  hasDocuments: isChecked,
                }));
              }}
            />
            Add product-specific documents?
          </div>

          {hasDocuments && (
            <React.Fragment>
              <DocumentsTable
                organizationId={orgId}
                documentObjectType={DocumentObjectType.Products}
                tableProps={
                  {
                    visibleColumns: 1,
                    itemsPerPage: 10,
                    smallPagination: true,
                    displaySelected: { renderCounter: this.renderCounter },
                    selected: values.documentIds,
                    onSelectionChange: documents => setFieldValue("documentIds", documents),
                  } as TableProps
                }
              />

              <Button kind={"primary"} className={styles.newDocument} onClick={() => this.goToNewDocument(formikProps)}>
                NEW DOCUMENT
              </Button>
            </React.Fragment>
          )}

          {message}
        </div>
        <ModalFooter
          actionName={"SAVE PRODUCT"}
          actionDisabled={savingState === "saving"}
          secondaryActionName={editing ? undefined : "SAVE AND ADD ANOTHER"}
          onAction={() => this.handleSubmit(values, formikProps)}
          onSecondaryAction={() => {
            this.addAnother = true;
            handleSubmit();
          }}
          onCancel={this.handleClose}
        />
      </Shortcuts>
    );
  };

  private static getValuesFromSearch(props: Props) {
    const {
      location: { search },
    } = props;
    const { values, newDocId } = getUrlQueries(search);

    if (values) {
      const parsedValues = JSON.parse(fromBase64(values));

      if (newDocId && newDocId !== NEW_ID_PLACEHOLDER) {
        parsedValues.documentIds = [...parsedValues.documentIds, newDocId];
      }

      return parsedValues;
    }

    return null;
  }

  static getDerivedStateFromProps(props: Props, { initialValues }: State) {
    const {
      location: { search },
      match: {
        params: { productId },
      },
      product,
    } = props;

    if (productId) {
      if (product) {
        return {
          initialValues: {
            ..._.omit(product, ["__typename", "category", "tags", "documents", "programs", "profilePictures"]),
            categoryKey: product.category && product.category.key,
            tagIds: product.tags.map(t => t.id),
            programIds: product.programs?.map(p => p.id),
            documentIds: product.documents && product.documents.map(d => d.id),
            ...(ProductFormModal.getValuesFromSearch(props) || {}),
            programGroup: { id: "all", name: "All", programIds: [] },
            profilePictures: product.profilePictures?.map(p => ({ id: p.id, url: p.url })),
          },
        };
      }
    } else if (search && !initialValues) {
      return {
        initialValues: ProductFormModal.getValuesFromSearch(props),
      };
    }

    return null;
  }

  public render() {
    const { initialValues } = this.state;
    const {
      match: {
        params: { productId },
      },
    } = this.props;
    const editing = this.editing;

    return (
      <LegacyModal noPadding className={styles.modal}>
        <ModalHeader mainAction={editing ? "Edit this product" : "Add products"} onClose={this.handleClose} />
        {productId && !initialValues ? (
          <LoadingContainer message={"Loading your product..."} />
        ) : (
          <Formik
            initialValues={
              initialValues || {
                id: null,
                name: "",
                sku: "",
                categoryKey: null,
                msrp: null,
                brandId: "",
                tagIds: [],
                description: "",
                documentIds: [],
                programIds: [],
                programGroup: { id: "all", name: "All", programIds: [] },
                profilePictures: [],
              }
            }
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={this.handleSubmit}
            render={this.renderForm}
          />
        )}
      </LegacyModal>
    );
  }
}

const VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().nullable(),
  sku: yup.string().nullable(),
  categoryId: yup.string().nullable(),
  tagIds: yup.array().of(yup.string().nullable()),
  documentIds: yup.array().of(yup.string().nullable()),
  pictures: yup.array().of(yup.object().shape({ url: yup.string() })),
});

export default compose(
  graphql(productCategoriesQuery, {
    props: ({ data: { productCategories } = {} as any }) => ({ productCategories }),
  }),

  graphql<any, any, any, any>(orgProgramsQuery, {
    options: props => ({
      variables: {
        id: props.match.params.orgId,
      },
    }),

    skip: props => !props.match.params.orgId,
    props: props => {
      return {
        programGroupsVisible: props.data?.organization?.programGroupsVisible,
        programs: props.data?.organization?.programs ?? [],
      };
    },
  }),

  graphql<any, any, any, any>(productDetailsQuery, {
    options: props => ({
      variables: {
        id: props.match.params.productId,
      },
      fetchPolicy: "no-cache",
    }),

    skip: props => !props.match.params.productId,
    props: ({ data: { product } = {} as any }) => ({ product }),
  }),

  graphql<any, any, any, any>(programGroupsQuery, {
    props: ({ data: { programGroups } = {} as any }) => ({ programGroups }),
  }),

  graphql<any, any, any, any>(upsertProduct, { props: ({ mutate }) => ({ upsertProductInfo: mutate }) }),
  graphql<any, any, any, any>(upsertProfilePictures, { props: ({ mutate }) => ({ upsertProfilePictures: mutate }) }),
)(ProductFormModal);
