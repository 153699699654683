import * as React from "react";
import { useMemo } from "react";
import _ from "lodash";
import ImageGroup from "components/ImageGroup";
import LoadingContainer from "components/LoadingContainer";
import SiblingBox from "components/SiblingBox";
import StatsBoard from "components/StatsBoard";
import StatsItem, { StatItemFormat } from "components/StatsBoard/StatsItem";
import Text from "components/LegacyText";
import Thumbnail from "components/Thumbnail";
import Money from "components/Money";
import cx from "classnames";
import variables from "styles/variables";
import styles from "./styles.scss";
import { Mobile } from "components/Layout/Responsive";
import { GigReportOverviewFragment, TemperatureUnit } from "gql-gen";
import Button from "components/Button";
import { zipFile } from "utilities/filestack";
import { toCelsius, toFahrenheit } from "utilities/temperature";
import { isHayday } from "utilities/flavor";
import { downloadUrl } from "utilities/files";
import moment from "moment-timezone";
import { format } from "date-fns";

type Group = GigReportOverviewFragment["report"][0];
type Subgroup = Group["subgroups"][0];
type Question = Subgroup["questions"][0];

interface Props {
  gig: GigReportOverviewFragment | null;
  loadingGigDetails: boolean;
}

function GroupView({ questionGroup, isMobile }: { questionGroup: Group; isMobile: boolean }) {
  const { productName, name, productId } = questionGroup;
  const [sidebarQuestions, otherQuestions] = _.partition(questionGroup.subgroups, subgroup => {
    return subgroup.type === "sidebar";
  });

  return (
    <div className={cx(styles.questionGroup, isMobile && styles.mobileQuestionGroup)}>
      <ImageGroup>
        <div className={cx(styles.sidebar, isMobile && styles.mobileSidebar)}>
          {productName && (
            <React.Fragment>
              <Text.Display5 kind="secondary" className={styles.productLabel}>
                Product
              </Text.Display5>
            </React.Fragment>
          )}

          <Text.Display5 className={styles.sidebarTitle}>{productName || name}</Text.Display5>
          {sidebarQuestions.map(sidebarSubgroup => {
            return <SubgroupView key={sidebarSubgroup.id} subgroup={sidebarSubgroup} isMobile={isMobile} />;
          })}
        </div>

        <div className={styles.content}>
          {otherQuestions.map(subgroup => {
            return <SubgroupView key={subgroup.id} subgroup={subgroup} isMobile={isMobile} productId={productId} />;
          })}
        </div>
      </ImageGroup>
      {isMobile && <hr className={styles.underline} />}
    </div>
  );
}

function AnswerView(
  question: Question & {
    selectable?: boolean;
    updateSelected?: (image: string) => any;
    productId?: string | null;
    isLastQuestion: boolean;
  },
) {
  const { answerType, answerState, answerJson, id, shortTitle, selectable, updateSelected, productId } = question;
  const parsedAnswer = answerJson ? JSON.parse(answerJson) : undefined;

  const gig = React.useContext(GigContext);

  if (answerState === "unasked") {
    return null;
  }

  switch (answerType) {
    case "image": {
      const profilePictures =
        gig?.products
          .filter(p => p.id === productId)
          .flatMap(({ profilePictures }) =>
            (profilePictures ?? [])
              .filter((p): p is { url: string; id: string; __typename: "LinkDocument" } => !!p)
              .map(p => ({ url: p.url, shortTitle: "Reference Img" })),
          ) || [];

      if (!parsedAnswer && profilePictures?.length === 0) {
        return <Thumbnail key={id} image={null} size={80} title={shortTitle} missing selectable={selectable} />;
      }

      if (parsedAnswer) {
        let fileName: string | undefined = undefined;

        if (gig && gig.organizationLocation && gig.assignedGogetter) {
          const date = gig.startTime && gig.timezone ? moment.tz(gig.startTime, gig.timezone).format("YYYMMDD") : "";
          fileName = `${date}-${gig.customLocationName}`;

          if (gig.organizationLocation) {
            fileName = fileName + `-${gig.organizationLocation?.internalIdentifier}`;
          }

          fileName = fileName + `-${gig.assignedGogetter.firstName} ${gig.assignedGogetter.lastName}`;
        }

        const parsedWithProfilePictures = [
          ...parsedAnswer.map((url: string) => ({ url, shortTitle })),
          ...profilePictures,
        ];

        return (
          <>
            {parsedWithProfilePictures.map(({ url, shortTitle }) => {
              return (
                <Thumbnail
                  key={`${url}${id}`}
                  image={url}
                  size={80}
                  title={shortTitle}
                  selectable={selectable}
                  updateSelected={updateSelected}
                  fileName={fileName}
                />
              );
            })}
          </>
        );
      } else {
        return (
          <>
            {profilePictures.map(({ url, shortTitle }) => {
              return (
                <Thumbnail
                  key={`${url}${id}`}
                  image={url}
                  size={80}
                  title={shortTitle}
                  selectable={selectable}
                  updateSelected={updateSelected}
                  fileName={""}
                />
              );
            })}
          </>
        );
      }
    }

    case "text":
    case "number":
    case "single_select":
    case "multiple_choice_one":
      return (
        <React.Fragment key={id}>
          {shortTitle && (
            <Text.H4 kind={parsedAnswer}>
              {shortTitle}
              :&nbsp;
            </Text.H4>
          )}

          <Text.P5>
            <AnswerContent question={question}>{parsedAnswer}</AnswerContent>
          </Text.P5>
        </React.Fragment>
      );
    case "atom_choice_one":
      if (!parsedAnswer) {
        return null;
      }
      const atom = parsedAnswer?.atom;
      const other = parsedAnswer?.other;
      return (
        <React.Fragment key={id}>
          <Text.H4>
            {shortTitle ? shortTitle : null}
            :&nbsp;
          </Text.H4>

          <AnswerContent question={question}>
            {atom ? `${atom.name} ${atom.identifier ? atom.identifier : ""}` : other}
          </AnswerContent>
        </React.Fragment>
      );
    case "atom_choice_many":
      if (!parsedAnswer) {
        return null;
      }
      const hasAtoms = parsedAnswer?.atoms?.length ?? 0 > 0;
      return (
        <React.Fragment key={id}>
          <Text.H4>
            {shortTitle ? shortTitle : null}
            :&nbsp;
          </Text.H4>

          <Text.P5>
            <AnswerContent question={question}>
              {hasAtoms
                ? parsedAnswer.atoms
                    .map((atom: any) => `${atom.name} ${atom.identifier ? atom.identifier : ""}`)
                    .join(", ")
                : null}
              <br />
              {parsedAnswer?.other ?? null}
            </AnswerContent>
          </Text.P5>
        </React.Fragment>
      );
    case "toggle":
      return (
        <React.Fragment key={id}>
          <Text.H4>
            {shortTitle ? shortTitle : null}
            :&nbsp;
          </Text.H4>

          <Text.P5>
            <AnswerContent question={question}>{parsedAnswer === true ? "Yes" : "No"}</AnswerContent>
          </Text.P5>
        </React.Fragment>
      );
    case "date":
      let formattedDate;
      try {
        formattedDate = parsedAnswer && format(new Date(parsedAnswer), "MM/dd/yyyy");
      } catch (err) {
        console.warn("Couldn't format date", parsedAnswer);
      }
      return (
        <React.Fragment key={id}>
          {shortTitle && (
            <Text.H4 kind={parsedAnswer}>
              {shortTitle}
              :&nbsp;
            </Text.H4>
          )}

          <Text.P5>
            <AnswerContent question={question}>{formattedDate}</AnswerContent>
          </Text.P5>
        </React.Fragment>
      );

    case "multiple_choice_many":
    case "multi_select":
      return (
        <Text.P5 key={id}>
          <AnswerContent question={question}>
            {Array.isArray(parsedAnswer) ? parsedAnswer.join(", ") : parsedAnswer}
          </AnswerContent>
        </Text.P5>
      );

    case "expense":
      return (
        <div key={id}>
          <div className={styles.expenseAmount}>
            <Text.H4 kind="secondary">Amount:&nbsp;</Text.H4>
            <Text.P5>
              <AnswerContent question={question}>{parsedAnswer?.amount}</AnswerContent>
            </Text.P5>
          </div>
          {parsedAnswer &&
            parsedAnswer.images &&
            parsedAnswer.images.map(({ url, caption }: { url: string; caption: string }) => {
              return (
                <div key={`${url}${id}`} className={styles.expenseItem}>
                  <Thumbnail image={url} size={80} />
                  <Text.P5>{caption}</Text.P5>
                </div>
              );
            })}
        </div>
      );

    case "money":
    case "money_with_negative":
      return (
        <Text.P5 key={id}>
          <AnswerContent question={question}>{parsedAnswer && <Money value={parsedAnswer} />}</AnswerContent>
        </Text.P5>
      );

    case "location":
      return (
        <Text.P5 key={id}>
          <AnswerContent question={question}>{parsedAnswer && parsedAnswer.description}</AnswerContent>
        </Text.P5>
      );
    case "temperature":
      return (
        <Text.P5 key={id}>
          <AnswerContent question={question}>
            {parsedAnswer.kelvin &&
              (parsedAnswer.unit === "celsius"
                ? toCelsius(parsedAnswer.kelvin, TemperatureUnit.Kelvin).toFixed(1) + " °C"
                : toFahrenheit(parsedAnswer.kelvin, TemperatureUnit.Kelvin).toFixed(1) + " °F")}
          </AnswerContent>
        </Text.P5>
      );
    case "agreement_signature":
      return (
        <Text.P5 key={id}>
          <AnswerContent question={question}>
            Signature: {parsedAnswer && parsedAnswer.signature}, agreed:{" "}
            {parsedAnswer && parsedAnswer.agreed ? "Yes" : "No"}
          </AnswerContent>
        </Text.P5>
      );
    case "contact":
      return (
        <Text.P5 key={id}>
          <AnswerContent question={question}>Contact name: {parsedAnswer && parsedAnswer.name}</AnswerContent>
        </Text.P5>
      );

    default:
      return (
        <Text.P5 key={id}>
          <AnswerContent question={question}>{(parsedAnswer || parsedAnswer === 0) && parsedAnswer}</AnswerContent>
        </Text.P5>
      );
  }
}

function getStatItemValue(question: Question) {
  const { answerState, answerJson, answerType } = question;

  if (answerState === "unasked") {
    return "N/A";
  }

  const parsedAnswer = JSON.parse(answerJson!);

  if (answerState === "answered" && parsedAnswer) {
    if (answerType === "temperature") {
      return (
        parsedAnswer.kelvin &&
        (parsedAnswer.unit === "celsius"
          ? toCelsius(parsedAnswer.kelvin, TemperatureUnit.Kelvin).toFixed(1) + " °C"
          : toFahrenheit(parsedAnswer.kelvin, TemperatureUnit.Kelvin).toFixed(1) + " °F")
      );
    }
    return parsedAnswer;
  }

  if (answerState === "na") {
    return "N/A";
  }

  return <span className={styles.missingLabel}>Missing</span>;
}

type Questions = Subgroup["questions"];

const shouldHideGroup = (questions: Questions) => questions.every(q => q.answerState === "unasked");

function SubgroupView({
  subgroup,
  isMobile,
  productId,
}: {
  subgroup: Subgroup;
  isMobile: boolean;
  productId?: string | null;
}) {
  const { name, questions: qs } = subgroup;
  const questions = useMemo(() => qs || [], [qs]);

  const hideGroup = useMemo(() => shouldHideGroup(questions), [questions]);

  if (hideGroup) {
    return null;
  }

  switch (subgroup.type) {
    case "sibling":
      return (
        <div className={styles.sibling}>
          <SiblingBox bgColor={variables.gray3} className={styles.variableSibling}>
            <Text.Display5 className={styles.siblingLabel}>{name}</Text.Display5>
          </SiblingBox>

          {questions.map((question, index) => {
            return (
              <QuestionWrapper question={question}>
                <SiblingBox bgColor={variables.gray5} className={styles.variableSibling} key={question.id}>
                  <AnswerView {...question} productId={productId} isLastQuestion={index === questions.length - 1} />
                </SiblingBox>
              </QuestionWrapper>
            );
          })}
        </div>
      );

    case "sidebar":
      return (
        <React.Fragment>
          <Text.Display5 kind="secondary" className={styles.sidebarQuestion}>
            {name}
          </Text.Display5>
          {questions.map(question => {
            const answer = JSON.parse(question.answerJson!);

            if (question.answerType === "contact") {
              const answerKeys = answer === null || answer === undefined ? [] : Object.keys(answer);

              return (
                <QuestionWrapper question={question} key={question.id}>
                  {answerKeys.map(key => (
                    <React.Fragment key={key}>
                      <Text.H4 kind="secondary">{key.charAt(0).toUpperCase() + key.slice(1) + ": "}</Text.H4>
                      <Text.P4 className={styles.sidebarAnswer}>
                        <AnswerContent question={question}>{answer[key]}</AnswerContent>
                      </Text.P4>
                    </React.Fragment>
                  ))}
                </QuestionWrapper>
              );
            }

            if (question.answerType === "atom_choice_one") {
              if (answer === null || answer === undefined) {
                return null;
              }
              return (
                <QuestionWrapper question={question} key={question.id}>
                  <Text.P4 className={styles.sidebarAnswer}>
                    <AnswerContent question={question}>
                      {answer.atom ? `${answer.atom.name} - ${answer.atom.identifier}` : answer.other}
                    </AnswerContent>
                  </Text.P4>
                </QuestionWrapper>
              );
            }
            if (question.answerType === "atom_choice_many") {
              if (answer === null || answer === undefined) {
                return null;
              }
              const hasAtoms = answer.atoms && answer.atoms.length > 0;
              return (
                <QuestionWrapper question={question} key={question.id}>
                  <Text.P4 className={styles.sidebarAnswer}>
                    <AnswerContent question={question}>
                      {hasAtoms
                        ? answer.atoms.map((atom: any) => `${atom.name} - ${atom.identifier}`).join(", ")
                        : null}
                      <br />
                      {answer.other ? answer.other : null}
                    </AnswerContent>
                  </Text.P4>
                </QuestionWrapper>
              );
            }
            return (
              <QuestionWrapper question={question}>
                <Text.P4 key={question.id} className={styles.sidebarAnswer}>
                  <AnswerContent question={question}>{answer}</AnswerContent>
                </Text.P4>
              </QuestionWrapper>
            );
          })}
        </React.Fragment>
      );

    case "simple":
      return (
        <React.Fragment>
          {!isMobile && (
            <Text.Display5 kind="secondary" className={styles.secondaryLabel}>
              {name}
            </Text.Display5>
          )}

          <div className={styles.simpleAnswers}>
            {questions.map((question, index) => {
              return (
                <QuestionWrapper key={question.id} question={question}>
                  <AnswerView {...question} productId={productId} isLastQuestion={index === questions.length - 1} />
                </QuestionWrapper>
              );
            })}
          </div>
        </React.Fragment>
      );

    case "stats":
      return (
        <div className={styles.statsBoard}>
          <StatsBoard loading={false} size={"medium"}>
            {questions.map(question => {
              return (
                <StatsItem
                  bgColor={variables.gray5}
                  key={question.id}
                  name={question.shortTitle || ""}
                  textColor={variables.gray1}
                  value={getStatItemValue(question)}
                  format={question.answerType === "temperature" ? "temperature" : undefined}
                />
              );
            })}
          </StatsBoard>
        </div>
      );

    default:
      return null;
  }
}

function QuestionWrapper({ children, question }: { children: React.ReactNode; question: Question }) {
  if (question.answerState === "unasked" && question.answerType !== "image") {
    return null;
  }

  return (
    <div
      style={{ opacity: question.answerState === "unasked" ? 0.6 : 1 }}
      className={question.answerType === "image" ? styles.imagesWrapper : ""}
    >
      {children}
    </div>
  );
}

function AnswerContent({ question, children }: { question: Question; children: any }) {
  if (question.answerState === "unasked") {
    return null;
  }

  if (question.answerState === "answered" && children) {
    return children;
  }

  if (question.answerState === "na") {
    return question.notApplicableDesc || "Not Applicable";
  }

  if (question.answerState === "answered" && question.answerType === "number" && question.answerJson === "0") {
    return question.answerJson;
  }

  return <span className={styles.missingLabel}>Missing</span>;
}

// @ts-ignore: FIXME Georg
function generateStatsItems(
  highLevelStats: GigReportOverviewFragment,
  isMobile: boolean,
): null | React.ReactElement<typeof StatsItem>[] {
  if (highLevelStats.interactions === null && highLevelStats.samples === null && highLevelStats.unitsSold === null) {
    return null;
  }

  const ratio: number | void =
    highLevelStats && highLevelStats.unitsSold && highLevelStats.samples
      ? parseFloat(((highLevelStats.unitsSold * 100) / highLevelStats.samples).toFixed(1))
      : undefined;

  interface StatItem {
    value?: number;
    name: string;
    transformVertically?: boolean;
    className?: string;
    format?: StatItemFormat;
  }

  const className = isMobile ? cx(styles.customItemBorder) : "";

  return ([
    {
      value: highLevelStats && highLevelStats.interactions,
      name: "Interactions",
      className,
    },

    {
      value: highLevelStats && highLevelStats.samples,
      name: "Samples",
      className,
    },

    {
      value: highLevelStats && highLevelStats.unitsSold,
      name: "Sold",
      transformVertically: isMobile,
      className,
    },

    {
      value: ratio,
      name: "Sold : Sampled",
      transformVertically: isMobile,
      className,
      format: "percentage",
    },
  ] as StatItem[]).map((statItem: StatItem) => (
    <StatsItem
      key={statItem.name}
      bgColor={variables.gray1}
      textColor={variables.teal1}
      titleColor={variables.white}
      {...statItem}
    />
  ));
}

const GigContext = React.createContext<GigReportOverviewFragment | null>(null);

function renderStatsAndReports(gig: GigReportOverviewFragment) {
  const statsView = (isMobile: boolean) => {
    const statsItems = generateStatsItems(gig, isMobile);
    if (!statsItems) return null;

    if (isMobile) {
      const [firstRow, secondRow] = _.chunk(statsItems, 2);
      return (
        <GigContext.Provider value={gig}>
          <StatsBoard loading={false} size={"medium"} className={cx(styles.withMargin)}>
            {firstRow}
          </StatsBoard>
          <StatsBoard loading={false} size={"medium"}>
            {secondRow}
          </StatsBoard>
        </GigContext.Provider>
      );
    }
    return (
      <GigContext.Provider value={gig}>
        <StatsBoard loading={false} size={"medium"}>
          {statsItems}
        </StatsBoard>
      </GigContext.Provider>
    );
  };

  return (
    <GigContext.Provider value={gig}>
      <Mobile>
        {isMobile => (
          <div className={styles.generalStats}>
            {!isHayday && <>{statsView(isMobile)}</>}
            {gig.report.length > 0 &&
              gig.report.map(questionGroup => {
                return (
                  <GroupView
                    key={`${questionGroup.id}${questionGroup.productId}`}
                    questionGroup={questionGroup}
                    isMobile={isMobile}
                  />
                );
              })}

            <div className={styles.multipleDownload}>
              <Button
                kind={"primaryGradient"}
                onClick={() => {
                  const profilePictures = gig?.products.flatMap(({ profilePictures }) =>
                    (profilePictures || []).map(p => p!.url),
                  );
                  let allImages = [];

                  for (let group of gig.report) {
                    for (let subgroup of group.subgroups) {
                      for (let question of subgroup.questions) {
                        if (question.answerType === "image" && question.answerJson) {
                          const images = JSON.parse(question.answerJson);
                          if (images) {
                            for (let image of images) {
                              allImages.push(image);
                            }
                          }
                        }
                      }
                    }
                  }
                  allImages = [...allImages, ...profilePictures];

                  downloadUrl(zipFile(allImages), "report-images.zip");
                }}
              >
                Download all images
              </Button>
            </div>
          </div>
        )}
      </Mobile>
    </GigContext.Provider>
  );
}

export default function FieldReportView({ gig, loadingGigDetails }: Props) {
  return gig && !loadingGigDetails ? renderStatsAndReports(gig) : <LoadingContainer center message={""} />;
}
