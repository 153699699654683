import { editable } from "components/Operand/styles.scss";
import { Gig } from "gql-gen";
import { isUserAtLeast } from "interfaces/user";
import { UserInfo } from "modules/Dashboard/UserInfo";

const verifiedReportEditing = (data: Gig, orgId: string) => {
  const { talentOrganization, managerOrganization, ownerOrganization, partnerOrganization } = data;

  return [talentOrganization, managerOrganization, ownerOrganization, partnerOrganization].some(
    org => org?.id === orgId && org.verifiedReportEditing,
  );
};

export const isEditable = (data: Gig, userInfo: UserInfo) => {
  // See `gig_states.order` in the DB
  const gigIsVerified = data.workflowStateOrder > 140;
  const user = userInfo.me;

  if (user?.isAdmin || data.talent?.id === user?.id) {
    return { editable: true, gigIsVerified };
  }

  if (!isUserAtLeast(user, "member")) {
    return { editable: false, gigIsVerified };
  }

  const orgId = user?.organizationUser?.organizationId;
  if (!orgId) return { editable: false, gigIsVerified };

  return {
    editable: gigIsVerified ? verifiedReportEditing(data, orgId) : data.talentOrganization?.id === orgId,
    gigIsVerified,
  };
};
