import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import styles from "./styles.scss";
import { useQuery } from "@apollo/client";
import useRouter from "use-react-router";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import _ from "lodash";
import { pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";

import Icon from "components/Icon";
import Text from "components/Text";
import RoundButton from "components/RoundButton";
import DropdownMenu from "components/DropdownMenu";
import Button from "components/Button";
import { Mobile, useMobileQuery } from "components/Layout/Responsive";
import { helpCenter } from "support-articles";
import { getAppUrl, getBaseAppUrl, USER_ORGANIZATION_PLACEHOLDER, addQueries } from "utilities/routes";
import { logout } from "utilities/authentication";
import variables, { colors } from "styles/variables";
import SearchableBigSelect from "../../../../components/LegacySearchable/SearchableBigSelect";
import myOrganizationsQuery from "./myOrganizations.gql";
import organizationQuery from "./organization.gql";
import programGroupsQuery from "./programGroupsQuery.gql";
import { usesHashRouter } from "utilities/environment";

import userIcon from "assets/user-circle.svg";
import homeIcon from "assets/home.svg";
import secretIcon from "assets/secret.svg";
import menuIcon from "assets/tune.svg";
import { useUserInfo } from "modules/Dashboard/UserInfo";
import { UserLink } from "modules/Dashboard/UserLink";
import { NavHeaderOrganizationQueryVariables, NavHeaderOrganizationQuery, Maybe } from "gql-gen";
import { canSeeCompanySettings, isUserAtLeast, User } from "interfaces/user";
import { programsRouteName } from "../Programs";
import { isEmbedded } from "modules/Dashboard";
import LoadingContainer from "components/LoadingContainer";
import Spinner from "components/Spinner";
import { be } from "date-fns/locale";

interface NavAction {
  text: string;
  onClick?: React.MouseEventHandler;
  href?: string;
  testId?: string;
}

export interface Tab {
  name: string;
  link?: string;
  remote?: boolean;
  disabled?: string | null;
  icon: any;
}

interface Props {
  children?: React.ReactNode;
  action?: NavAction;
  tabs?: Tab[];
  client?: any;
}
export default function NavHeader(props: Props) {
  const {
    match: {
      params: { id, orgId, page, programGroupId },
    },
    location,
    history,
  } = useRouter<{ id: string; orgId: string; page: string; programGroupId: string }>();

  const { data: userOrgs, loading: organizationsLoading } = useQuery(myOrganizationsQuery, { variables: { id } });
  const {
    data: programGroupsData,
    loading: programGroupsLoading,
    refetch: refetchProgramGroups,
  } = useQuery(programGroupsQuery, { fetchPolicy: "network-only" });

  const { data: orgData, loading: orgDataLoading } = useQuery<
    NavHeaderOrganizationQuery,
    NavHeaderOrganizationQueryVariables
  >(organizationQuery, { variables: { orgId }, skip: orgId === USER_ORGANIZATION_PLACEHOLDER });

  const newNav = !!orgData?.organization?.newNav;

  function renderTabs(isMobile: boolean) {
    const { tabs } = props;

    const activeTab = tabs && tabs.find(tab => tab.link && location.pathname.includes(tab.link));

    const items =
      tabs &&
      tabs.map((tab, index) => {
        const active = tab === activeTab;

        const label = isMobile ? (
          <div className={cx(styles.mobileTab)}>
            <Icon src={tab.icon} fill={isMobile && active ? variables.gray1 : variables.white} width={30} />
            {active && (
              <Text
                size={isMobile ? 10 : 12}
                font={"wes"}
                bold
                color={active ? "gray1" : "white"}
                className={cx(tab.disabled ? styles.disabledLabel : styles.label)}
                tooltip={tab.disabled || undefined}
                transform={"uppercase"}
                letterSpacing={1.2}
              >
                {tab.name}
              </Text>
            )}
          </div>
        ) : (
          <Text
            size={12}
            font={"wes"}
            bold
            color={active ? "gray1" : "white"}
            className={cx(tab.disabled ? styles.disabledLabel : styles.label)}
            tooltip={tab.disabled || undefined}
            transform={"uppercase"}
            letterSpacing={1.2}
          >
            {tab.name}
          </Text>
        );

        return (
          <div
            className={cx(styles.tab, active && styles.activeTab)}
            key={index}
            data-test={`${tab.name.toLowerCase()}-tab`}
          >
            {tab.disabled ? (
              label
            ) : tab.remote ? (
              <a
                href={!tab.disabled && tab.link ? tab.link : "javascript:void()"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {label}
              </a>
            ) : (
              <Link to={{ pathname: tab.link || "", search: location.search }}>{label}</Link>
            )}
          </div>
        );
      });

    return (
      items &&
      items.length > 0 && (
        <div className={styles.tabBackground}>
          <div className={styles.contentWrapper}>
            <div className={styles.tabs}>{items}</div>
          </div>
        </div>
      )
    );
  }

  if (isEmbedded) {
    return null;
  }

  return (
    <>
      <Mobile>
        {mobile => (
          <>
            {newNav ? (
              <NewNav
                orgData={orgData}
                orgDataLoading={orgDataLoading}
                action={props.action}
                refetchProgramGroups={refetchProgramGroups}
              />
            ) : (
              <TopNav
                mobile={mobile}
                orgData={orgData}
                orgDataLoading={orgDataLoading}
                refetchProgramGroups={refetchProgramGroups}
                action={props.action}
              />
            )}
            <div className={cx(styles.children, newNav ? styles.newNavChildren : "")}>
              <div className={cx(styles.contentWrapper, mobile && styles.pickers)}>
                {!organizationsLoading && userOrgs?.me?.organizations?.length > 1 && !newNav && (
                  <SearchableBigSelect
                    testId={"navHeader.organizationSelector"}
                    className={styles.organizationPicker}
                    inputClassName={styles.orgInput}
                    clearSearchOnSelect={true}
                    items={userOrgs.me.organizations}
                    selectedItem={orgId}
                    onChange={item => {
                      if (usesHashRouter()) {
                        window.location.href = `#/${item.id}`;
                        window.location.reload();
                      } else {
                        window.location.href = `/${item.id}`;
                      }
                    }}
                    optionsYOffset={1}
                    selectProps={{
                      name: "Organization",
                      compact: mobile,
                    }}
                  />
                )}
                {!programGroupsLoading &&
                  orgId &&
                  location.pathname.includes("programs") &&
                  orgData?.organization?.programGroupsVisible && (
                    <SearchableBigSelect
                      testId={"navHeader.programGroupSelector"}
                      className={styles.organizationPicker}
                      inputClassName={styles.orgInput}
                      clearSearchOnSelect={true}
                      items={[{ name: "All", id: "all" }, ...programGroupsData?.programGroups]}
                      selectedItem={programGroupId}
                      onChange={item => {
                        if (item.id === "all") {
                          history.replace({
                            ...location,
                            pathname: `/${orgId}/program-group/all/${programsRouteName}/${page ? "/" + page : ""}`,
                          });
                        } else {
                          const programIds = item.programIds.join(",");
                          history.replace({
                            ...location,
                            pathname: `/${orgId}/program-group/${item.id}/${programsRouteName}/${
                              page ? "/" + page : ""
                            }`,
                            search: addQueries(location.search, { program: programIds }),
                          });
                        }
                      }}
                      optionsYOffset={1}
                      selectProps={{
                        name: "Program Group",
                        compact: mobile,
                      }}
                    />
                  )}
                <div className={cx(styles.programPicker)}>{props.children}</div>
              </div>
            </div>
            {renderTabs(mobile)}
          </>
        )}
      </Mobile>
    </>
  );
}

interface TopNavProps {
  mobile: boolean;
  orgData?: NavHeaderOrganizationQuery;
  refetchProgramGroups: () => void;
  orgDataLoading: boolean;
  action?: NavAction;
}

function TopNav(props: TopNavProps) {
  const { mobile, orgData, orgDataLoading, refetchProgramGroups, action } = props;
  const { me, impersonator } = useUserInfo();
  const { t } = useTranslation();
  const {
    match: {
      params: { orgId },
    },
  } = useRouter<{ orgId: string }>();

  return (
    <div className={cx(styles.topNav, styles.topNavShadow)}>
      <div className={styles.contentWrapper}>
        <div className={styles.whiteLeft}>
          <div className={styles.orgAndLogo}>
            {!orgDataLoading && !mobile && (
              <Text
                size={12}
                color={"gray1"}
                font={"wes"}
                letterSpacing={1.2}
                transform={"uppercase"}
                className={styles.alignOrg}
                lineHeight={10}
              >
                {_.get(orgData, "organization.name")}
              </Text>
            )}
          </div>

          {me && impersonator && (
            <div className={styles.impersonating}>
              <Icon src={secretIcon} size={14} fill={colors.purple} />
              <UserLink id={impersonator.id}>
                <Text size={12} font="wes" color="purple" bold lineHeight={10} left="xs">
                  {impersonator.firstName[0]} {impersonator.lastName[0]}
                </Text>
              </UserLink>
              <Text size={12} font="wes" color="purple" bold lineHeight={10} left="xs">
                as
              </Text>
              <UserLink id={me.id}>
                <Text size={12} font="wes" color="purple" bold lineHeight={10} left="xs">
                  {me.firstName} {me.lastName[0]}. ({me.organizationUser?.type})
                </Text>
              </UserLink>
            </div>
          )}
        </div>
        <div className={styles.accountAndGigs} style={{ display: "flex" }}>
          <DropdownMenu
            showArrow={!mobile}
            arrowColor={"gray4"}
            menuClassName={styles.menu}
            renderMenu={() => <TopNavMenuItems orgId={orgId} orgData={orgData} me={me} />}
            testId={"navHeader.settings"}
          >
            {mobile ? (
              <RoundButton>
                <Icon src={userIcon} size={42} fill={variables.gray4} />
              </RoundButton>
            ) : (
              <Button>
                <Text
                  size={12}
                  color={"gray1"}
                  font={"wes"}
                  letterSpacing={1.5}
                  transform={"uppercase"}
                  className={styles.alignOrg}
                  lineHeight={10}
                >
                  {t("menu")}
                </Text>
              </Button>
            )}
          </DropdownMenu>
          {action ? (
            <CustomAction
              onClick={() => (location.pathname.includes("settings") ? refetchProgramGroups : null)}
              action={action}
              newNav={!!orgData?.organization.newNav}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

interface TopNavMenuItemsProps {
  orgId: string;
  orgData?: NavHeaderOrganizationQuery;
  me: User | null;
  children?: React.ReactNode;
}

function TopNavMenuItems({ orgId, orgData, me, children }: TopNavMenuItemsProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.menuContent}>
      {children}
      <Button className={styles.menuItem} href={getAppUrl("go", `${orgId || "_"}`)}>
        <Text size={12} font={"wes"} bold>
          {t("goToGo")}
        </Text>
      </Button>
      {me?.isAdmin && (
        <Button className={styles.menuItem} href={getAppUrl("admin")}>
          <Text size={12} font={"wes"} bold>
            {t("goToAdmin")}
          </Text>
        </Button>
      )}

      <Button
        className={styles.menuItem}
        href={getAppUrl("accounts", `${orgId || "_"}/my-account`, { ref: "dashboard" })}
      >
        <Text size={12} font={"wes"} bold>
          {t("myaccount")}
        </Text>
      </Button>

      {me?.organizationUser &&
        (orgData?.organization?.isWorkspace === false || me.isAdmin || me?.organizationUser?.type === "owner") &&
        canSeeCompanySettings(me?.organizationUser?.type) && (
          <Link to={{ ...location, pathname: `/${orgId || ""}/settings` }}>
            <Button className={styles.menuItem}>
              <Text size={12} font={"wes"} bold>
                {t("mycompany")}
              </Text>
            </Button>
          </Link>
        )}
      {isUserAtLeast(me, "manager") && (
        <Link to={{ ...location, pathname: `/${orgId || ""}/invoices`, search: "" }}>
          <Button className={styles.menuItem}>
            <Text size={12} font={"wes"} bold>
              {t("invoices")}
            </Text>
          </Button>
        </Link>
      )}
      <a href="mailto:support@gopinata.com" className={cx(styles.menuItem, styles.mailto)}>
        <Text size={12} font={"wes"} bold>
          {t("chatWithUs")}
        </Text>
      </a>
      <Button className={styles.menuItem} href={helpCenter}>
        <Text size={12} font={"wes"} bold>
          {t("helpCenter")}
        </Text>
      </Button>
      <Button className={styles.menuItem} onClick={() => logout()}>
        <Text size={12} font={"wes"} bold>
          {t("logOut")}
        </Text>
      </Button>
    </div>
  );
}

interface CustomActionProps {
  action: NavAction;
  onClick?: () => void;
  newNav: boolean;
}

function CustomAction({ onClick, action, newNav }: CustomActionProps) {
  const { text, onClick: onAction, href, testId } = action;

  let actionView = (
    <Button
      testId={testId}
      kind="primaryGradient"
      className={newNav ? styles.topNavActionButton : ""}
      onClick={e => {
        onClick && onClick();
        onAction && onAction(e);
      }}
    >
      <Text color={"white"} font="wes">
        {text}
      </Text>
    </Button>
  );

  if (href) {
    actionView = <Link to={href}>{actionView}</Link>;
  }

  return <div>{actionView}</div>;
}

interface NewNavProps {
  orgData?: NavHeaderOrganizationQuery;
  orgDataLoading: boolean;
  action?: NavAction;
  refetchProgramGroups?: () => void;
}

function NewNav({ orgData, orgDataLoading, action, refetchProgramGroups }: NewNavProps) {
  const { me, loading: loadingMe, impersonator } = useUserInfo();
  const isMobile = useMobileQuery();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const branding = orgData?.organization?.primaryColor ?? "#00DEB0";
  const { location } = useRouter();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest("button")
      ) {
        setDropdownVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!orgData || orgDataLoading || loadingMe) {
    return (
      <div style={{ "--primary-color": branding } as React.CSSProperties} className={styles.newTopNav}>
        <Spinner color="white" />
      </div>
    );
  }
  const userDetailsFontSize = isMobile ? 14 : 16;

  return (
    <div style={{ "--primary-color": branding } as React.CSSProperties} className={styles.newTopNav}>
      <Logo
        url={orgData.organization.logoUrl}
        orgId={orgData.organization.id}
        title={isMobile ? "Home" : orgData.organization.name}
      />
      <div className={styles.navRight}>
        <div className={styles.currentUserDetailsContainer}>
          {impersonator && !isMobile ? <Icon src={secretIcon} size={14} fill={colors.white} /> : null}

          <Text font="wes" size={userDetailsFontSize} color="white">
            {impersonator ? `${impersonator.firstName[0]} ${impersonator.lastName[0]}` : me?.firstName}
          </Text>
          {impersonator ? (
            <Text size={userDetailsFontSize} font="wes" color="white">
              as
            </Text>
          ) : null}
          {impersonator && me ? (
            <UserLink id={me.id}>
              <Text size={userDetailsFontSize} font="wes" color="white">
                {me.firstName} {me.lastName[0]}. {!isMobile ? `(${me.organizationUser?.type})` : ""}
              </Text>
            </UserLink>
          ) : null}
        </div>
        <div className={styles.menuContainer}>
          <button onClick={() => setDropdownVisible(!dropdownVisible)}>
            <Icon src={menuIcon} size={36} />
          </button>
          {dropdownVisible && (
            <div ref={dropdownRef} className={styles.dropdownContainer}>
              <TopNavMenuItems orgId={orgData?.organization?.id} orgData={orgData} me={me}>
                {isMobile && action ? (
                  <Button className={styles.menuItem} onClick={action.onClick}>
                    <Text size={12} font={"wes"} bold>
                      {action.text}
                    </Text>
                  </Button>
                ) : null}
              </TopNavMenuItems>
            </div>
          )}
        </div>

        {action && !isMobile ? (
          <CustomAction
            onClick={() => (location.pathname.includes("settings") ? refetchProgramGroups : null)}
            action={action}
            newNav={!!orgData?.organization.newNav}
          />
        ) : null}
      </div>
    </div>
  );
}

interface LogoProps {
  url: Maybe<string | undefined>;
  orgId: string;
  title: string;
}

const routeMatchers: [RegExp, string][] = [
  [/\/gigs\/calendar/, "Calendar"],
  [/\/gigs/, "Tasks"],
  [/\/reports/, "Reports"],
  [/\/settings/, "Settings"],
  [/\/overview/, "Overview"],
  [/\/financials/, "Financials"],
  [/\/invoices/, "Invoices"],
];

function Logo(props: LogoProps) {
  const { location } = useRouter();
  const isMobile = useMobileQuery();
  const appUrl = getAppUrl("theApp");
  const breadcrumb = pipe(
    routeMatchers,
    A.findFirst(([regex]) => regex.test(location.pathname)),
    O.map(([, title]) => title),
    O.getOrElse(() => ""),
  );
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <div className={styles.logo}>
      <button onClick={() => (window.location.href = appUrl + props.orgId)}>
        {props.url ? (
          <>
            {!imageLoaded && <Spinner color="white" />}
            <img
              src={props.url}
              onLoad={() => setImageLoaded(true)}
              height={isMobile ? 24 : 36}
              style={{ display: imageLoaded ? "block" : "none" }}
            />
          </>
        ) : (
          <Icon src={homeIcon} width={isMobile ? 24 : 36} />
        )}
        <Text font="lato" size={16} color="white">
          {props.title}
        </Text>
        <Text font="lato" size={20} color="white" align="center">
          »
        </Text>
      </button>
      <Text font="wes" size={16} color="white" className={styles.breadcrumb}>
        {breadcrumb}
      </Text>
    </div>
  );
}
