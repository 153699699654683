import { bin, buildLogicalExpression } from "utilities/knueppel";

const programManagementStateCondition = bin("managementstate", "!=", "sow");

function programGroupCondition(programGroupUuid: string) {
  return programGroupUuid === "all"
    ? null
    : bin("programgroupids", "&&", {
        type: "UUIDListLiteral",
        values: [programGroupUuid],
      });
}

export function userProgramsCondition(programGroupUuid: string) {
  return programGroupCondition
    ? buildLogicalExpression("&&", [programManagementStateCondition, programGroupCondition(programGroupUuid)])
    : programManagementStateCondition;
}
