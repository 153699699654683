import * as React from "react";
import cx from "classnames";
import Icon from "components/Icon";
import Text from "components/LegacyText";
import { WorkflowState } from "interfaces/Gig";
import Button from "components/Button";
import Collapse from "components/Collapse";
import variables from "styles/variables";
import returnedIcon from "assets/form-status.svg";
import rejectedIcon from "assets/calendar-times.svg";
import verifiedIcon from "assets/celebrate.svg";
import highFiveIcon from "assets/handoff.svg";
import pendingIcon from "assets/clock.svg";
import styles from "./styles.scss";

interface Props {
  state?: WorkflowState;
  message?: string | null;
  reviewedByName: string | null;
  reviewedByOrganization: string | null;
}

interface State {
  messageOpen: boolean;
}

function getStateClass(state?: WorkflowState) {
  switch (state) {
    case "reportDraft":
      return styles.draft;
    case "reportVerificationReturned":
      return styles.returned;

    case "reportVerificationPending":
      return styles.submitted;

    case "reportVerificationRejectedPaymentPending":
    case "reportVerificationRejectedPaid":
      return styles.rejected;

    case "verifiedPaid":
    case "verifiedPaymentPending":
      return styles.verified;

    default:
      return "";
  }
}

export default class ReportStatus extends React.PureComponent<Props, State> {
  readonly state: State = { messageOpen: false };

  public render() {
    const { state, message, reviewedByName, reviewedByOrganization } = this.props;
    const { messageOpen } = this.state;
    const content = titleAndIcon(state);
    if (!content) return null;

    const { title, body, icon } = content;

    const hasMessage = message && message.trim().length;

    return (
      <div className={cx(styles.reportStatus, getStateClass(state), !message && styles.noMessage)}>
        <div className={styles.icon}>
          <Icon src={icon} fill={variables.white} size={27} />
        </div>

        <Text.Display4 kind={"reverse"} className={styles.title}>
          {title}
        </Text.Display4>

        {body && (
          <Text.P4 kind={"reverse"} className={hasMessage ? styles.bodyWithMessage : styles.body}>
            {body}
          </Text.P4>
        )}

        {hasMessage && (
          <React.Fragment>
            <Collapse open={messageOpen}>
              <div className={styles.message}>
                {reviewedByName && (
                  <Text.Display5 className={styles.reviewer}>
                    {reviewedByName}
                    {reviewedByOrganization ? "," : ""}
                    &nbsp;
                    {reviewedByOrganization}
                  </Text.Display5>
                )}

                <Text.P4 className={styles.messageText}>{message}</Text.P4>
              </div>
            </Collapse>
            <Button
              kind="primary"
              className={messageOpen ? styles.hideMessage : styles.viewMessage}
              onClick={() => this.setState({ messageOpen: !messageOpen })}
            >
              {messageOpen ? "HIDE" : state === "submitted" ? "VIEW PREVIOUS MESSAGE" : "VIEW MESSAGE FROM MANAGER"}
            </Button>
          </React.Fragment>
        )}
      </div>
    );
  }
}

function titleAndIcon(state?: WorkflowState) {
  switch (state) {
    case "reportDraft":
      return {
        icon: pendingIcon,
        title: <React.Fragment>Submit report when ready</React.Fragment>,
        body: null,
      };
    case "reportVerificationReturned":
      return {
        icon: returnedIcon,
        title: (
          <React.Fragment>
            Your report is <em>incomplete</em>.
          </React.Fragment>
        ),

        body: null,
      };

    case "reportVerificationPending":
      return {
        icon: highFiveIcon,
        title: "Report sent! Nice work!",
        body: "You’ll be alerted when your manager responds. You can still edit answers.",
      };

    case "reportVerificationRejectedPaymentPending":
    case "reportVerificationRejectedPaid":
      return {
        icon: rejectedIcon,
        title: (
          <React.Fragment>
            This task was <em>rejected</em>.
          </React.Fragment>
        ),

        body: null,
      };

    case "verifiedPaid":
    case "verifiedPaymentPending":
      return {
        icon: verifiedIcon,
        title: (
          <React.Fragment>
            Hooray! This task is <em>verified</em>.
          </React.Fragment>
        ),

        body: null,
      };

    default:
      return null;
  }
}
