import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { FieldProps } from "formik";

import { RoleFieldFragment, SkipReportingSteps } from "gql-gen";

import { SearchableSelect } from "components/SearchableSelect";
import { connection, ConnectionConfig } from "utilities/connections";
import { bin } from "utilities/knueppel";
import Section from "components/Form/Section";
import { isVirtualLocation, NO_LOCATION } from "utilities/location";

import roleFragment from "./role.gql";

import { FieldOptionsText, FieldOptionsBold } from "../../FieldOptionsText";
import { programListFilterVars } from "../../../Filters/makeProgramListFilter";

export const roleConnection: ConnectionConfig<RoleFieldFragment> = connection({
  name: "GigsWorkflowRoles",
  entry: { name: "roleConnectionsByPrograms" },
  variables: programListFilterVars,
});

export function FRolePicker({
  field,
  form,
  programId,
  testId,
  inlineForm,
  partialBatchUpdate,
}: FieldProps & { programId: string; testId?: string; inlineForm?: boolean; partialBatchUpdate?: boolean }) {
  const { t } = useTranslation();

  return (
    <SearchableSelect<RoleFieldFragment>
      testId={testId}
      label={t("gigs.fields.role.role")}
      multiple={false}
      connection={roleConnection}
      fragment={roleFragment}
      value={field.value ? [field.value] : []}
      disabled={!partialBatchUpdate && !programId}
      variables={{ programIds: partialBatchUpdate ? [] : [programId], filters: bin("active", "=", true) }}
      errorMessage={form.touched[field.name] ? (form.errors[field.name] as string) : undefined}
      renderName={({ title, skipReportingSteps }) =>
        `${title}${skipReportingSteps !== SkipReportingSteps.None ? " - no location required" : ""}`
      }
      onChange={(r, nodes) => {
        const selectedId = r[0];
        form.setFieldValue(field.name, selectedId);

        const locationField = form.getFieldProps("location");

        if (!locationField) {
          return;
        }

        const activity = nodes.find(n => n.id === selectedId);
        const { skipReportingSteps } = activity ?? { skipReportingSteps: SkipReportingSteps.None };

        const noLocationWasSelected = isVirtualLocation(locationField.value);

        if (skipReportingSteps !== SkipReportingSteps.None) {
          form.setFieldValue("location", NO_LOCATION);
          form.setFieldValue("locationName", "");
        } else if (noLocationWasSelected) {
          form.setFieldValue("location", undefined);
          form.setFieldValue("locationName", "");
        }
      }}
      selectFirst
    >
      {({ select, count }) => (
        <>
          {!partialBatchUpdate && (
            <FieldOptionsText inlineForm={inlineForm}>
              <Trans i18nKey={"gigs.fields.role.programRoles"} count={count ?? 0}>
                This program includes
                <FieldOptionsBold inlineForm={inlineForm}>3 field report options.</FieldOptionsBold>
                Select one for this task.
              </Trans>
            </FieldOptionsText>
          )}

          <Section>{select}</Section>
        </>
      )}
    </SearchableSelect>
  );
}
