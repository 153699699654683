import { MutationFunction } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { FormikProps, withFormik, FormikErrors } from "formik";
import { articles } from "support-articles";
import * as yup from "yup";
import LegacyModal from "components/LegacyModal";
import Text from "components/LegacyText";
import Form from "components/Form";
import ModalHeader from "components/ModalHeader";
import ModalFooter from "components/ModalFooter";
import SelectAllBar from "../../../../components/SelectAllBar";
import { inputProps } from "utilities/formik";
import { inflect } from "utilities/string";
import { getUrlQueries } from "utilities/routes";
import {
  UserType,
  USER_TYPES,
  USER_TYPE_NAMES,
  canSpecifyPrograms,
  getManageableUserTypes,
  User,
} from "interfaces/user";
import teamIcon from "assets/team.svg";
import styles from "./styles.scss";
import inviteMutation from "./invite.gql";
import inviteDetailsQuery from "./inviteDetails.gql";
import usersQuery from "../LegacySettings/UsersTable/users.gql";
import { CloseModalHandler } from "utilities/modals";
import { OrganizationRouteParams } from "..";
import LegacySearchableSelect from "components/LegacySearchable/SearchableSelect";
import programGroupsQuery from "./programGroupsQuery.gql";
import { withReloader, IReloadContext } from "modules/Connection/Reloader";
import { flowRight as compose } from "lodash";
import Switch from "components/Switch";
import { SearchableSelect } from "components/SearchableSelect";
import { connection, ConnectionConfig } from "utilities/connections";
import programFragment from "./program.gql";
import { InviteUsersProgramOptionsFragment } from "gql-gen";
import { userProgramsCondition } from "../Programs/userProgramsFilter";
interface InviteFormValues {
  userType?: UserType;
  programs: string[];
  emails: string;
  programGroup: { name: string; id: string; programIds: string[] };
  canRequest: boolean;
  canSelfAssign: boolean;
}

type OrganizationDetails = { gigRequestsActive: boolean; gigSelfAssignmentActive: boolean };

interface Props extends FormikProps<InviteFormValues>, RouteComponentProps<OrganizationRouteParams> {
  handleClose: CloseModalHandler;
  mutate: MutationFunction<any, unknown>;
  userTypes: UserType[];
  organization: OrganizationDetails;
  programs: Array<{ id: string; name: string }>;
  programGroups: { id: string; name: string; programIds: string[] }[];
  programGroupsVisible: boolean;
  reloader: IReloadContext;
}

export const programConnection: ConnectionConfig<any> = connection({
  name: "InviteUsersProgramOptions",
  entry: { name: "programConnections" },
  variables: { search: "String" },
});

class InviteUsersModal extends React.PureComponent<Props> {
  public render() {
    const {
      organization,
      handleSubmit,
      handleClose,
      isSubmitting,
      values,
      errors,
      setFieldValue,
      userTypes,
      programs,
      programGroups,
      programGroupsVisible,
    } = this.props;
    const input = inputProps(this.props);

    const hs = () => handleSubmit();
    const hc = () => handleClose();

    return (
      <LegacyModal noPadding className={styles.modal}>
        <ModalHeader icon={teamIcon} mainAction={"Invite team members"} onClose={hc} />

        <div className={styles.content}>
          <Text.P4 className={styles.copy}>
            Invite someone to join your company on PINATA. Use this form to define their permissions. You'll be able to
            edit these later.
            <br />
            <span className={styles.proTip}>PRO TIP: </span>
            You can create multiple users with the same permissions by entering an address per line below.
          </Text.P4>
          <Form.Section>
            <Form.TextBox
              {...input("emails")}
              label={"Email addresses"}
              multiline={true}
              rows={5}
              placeholder={"joe@example.com"}
            />
          </Form.Section>

          <Form.Section>
            <Form.Dropdown
              value={values.userType === undefined ? undefined : values.userType === "gogetter" ? "go" : "dashboard"}
              onChange={e => {
                setFieldValue("canRequest", false);
                setFieldValue("canSelfAssign", false);

                if (e.target.value === "dashboard") {
                  setFieldValue("userType", "member");
                } else if (e.target.value === "go") {
                  setFieldValue("userType", "gogetter");
                }
              }}
              label={"USER TYPE"}
            >
              {values.userType === undefined && <option>Choose User Type</option>}
              <option value="dashboard">Power User (Access to this Dashboard)</option>
              <option value="go">GoGetter (Access to Go Portal)</option>
            </Form.Dropdown>

            {values.userType !== undefined &&
              (values.userType === "gogetter" ? (
                <>
                  {organization.gigRequestsActive && (
                    <Switch
                      id="can-request"
                      checked={values.canRequest}
                      onChange={() => setFieldValue("canRequest", !values.canRequest)}
                      labelText="Requester (can request tasks in shared programs)"
                      className={styles.canSelfAssign}
                      labelTextClassName={styles.canSelfAssignLabel}
                    />
                  )}

                  {organization.gigSelfAssignmentActive && (
                    <Switch
                      id="can-self-assign"
                      checked={values.canSelfAssign}
                      onChange={() => setFieldValue("canSelfAssign", !values.canSelfAssign)}
                      labelText="Autonomous (can create tasks for themselves in shared programs)"
                      className={styles.canSelfAssign}
                      labelTextClassName={styles.canSelfAssignLabel}
                    />
                  )}
                </>
              ) : (
                <Form.Dropdown {...input("userType")} label={"LEVEL"}>
                  {userTypes
                    .filter(t => t !== "gogetter")
                    .map(ut => (
                      <option key={ut} value={ut}>
                        {USER_TYPE_NAMES[ut]}
                      </option>
                    ))}
                </Form.Dropdown>
              ))}

            {values.userType !== undefined &&
              canSpecifyPrograms(values.userType, values.canRequest, values.canSelfAssign) && (
                <React.Fragment>
                  {programGroupsVisible && (
                    <LegacySearchableSelect
                      name={"FILTER PROGRAMS BY PROGRAM GROUP"}
                      placeholder={"Search by name"}
                      selectedItem={values.programGroup?.id}
                      items={[{ id: "all", name: "All" }, ...(programGroups ?? [])]}
                      onChange={v => setFieldValue("programGroup", v)}
                    />
                  )}
                  <SearchableSelect<InviteUsersProgramOptionsFragment>
                    label="SELECT PROGRAMS"
                    placeholder={"Search by name"}
                    multiple
                    value={values.programs}
                    fragment={programFragment}
                    connection={programConnection}
                    variables={{
                      programIds: [],
                      filters: userProgramsCondition(values.programGroup.id),
                    }}
                    renderName={program => program?.name ?? "Unnamed"}
                    onChange={v => setFieldValue("programs", v)}
                    itemNamePlural="programs"
                  />

                  <SelectAllBar
                    items={(values.programGroup.id === "all"
                      ? programs
                      : programs.filter(p => values.programGroup.programIds.includes(p.id))
                    ).map(p => p.id)}
                    value={values.programs}
                    onChange={v => setFieldValue("programs", v)}
                  />
                </React.Fragment>
              )}
          </Form.Section>

          <Text.P4>
            <Text.NewLink4 href={articles.userPermissions} target={"_blank"}>
              Learn more.
            </Text.NewLink4>
          </Text.P4>

          {errors.userType && <Text.Message kind={"error"}>{errors.userType}</Text.Message>}
        </div>

        <ModalFooter
          actionName={"INVITE"}
          actionButtonType={"submit"}
          actionDisabled={values.userType === undefined || isSubmitting}
          onAction={hs}
          onCancel={hc}
        />
      </LegacyModal>
    );
  }
}

export default compose(
  graphql<Props, { me: User; organization: OrganizationDetails }, {}, unknown>(inviteDetailsQuery, {
    options: props => ({
      variables: {
        orgId: props.match.params.orgId,
      },
      fetchPolicy: "network-only",
    }),
    props: props => {
      const { data } = props;

      if (!data || !data.me) {
        return {
          programs: [],
          userTypes: [],
          organization: { gigRequestsActive: false, gigSelfAssignmentActive: false },
        };
      }

      const { programs, organizationUser } = data.me;

      let userTypes = getManageableUserTypes(data.me);

      if (
        organizationUser.organizationType !== "agency" &&
        !programs.find(program => program.executionType === "solo")
      ) {
        userTypes = userTypes.filter(u => u !== "gogetter");
      }

      return {
        userTypes,
        programs: programs.map(({ id, name }) => ({ id, name })),
        organization: data.organization,
      };
    },
  }),

  graphql(inviteMutation),

  graphql<any, any, any, any>(programGroupsQuery, {
    options: props => ({
      variables: {
        orgId: props.match.params.orgId,
      },
    }),
    props: ({ data: { programGroups, organization } = {} as any }) => ({
      programGroups,
      programGroupsVisible: organization?.programGroupsVisible,
    }),
  }),

  withReloader,

  withFormik<Props, InviteFormValues>({
    mapPropsToValues: ({ userTypes, location: { search } }) => {
      const { userType } = getUrlQueries(search);
      return {
        emails: "",
        userType: userType as UserType | undefined,
        programs: [],
        programGroup: { id: "all", name: "All", programIds: [] },
        canSelfAssign: false,
        canRequest: false,
      };
    },

    validate: async values => {
      const emails = values.emails.split("\n");
      const errors: FormikErrors<InviteFormValues> = {};

      if (values.userType === undefined) {
        errors.userType = "Please choose a user type.";
      }

      if (values.userType && !USER_TYPES.includes(values.userType)) {
        errors.userType = "Please select a permission level.";
      }

      if (values.emails.length === 0) {
        errors.emails = "You need to add at least one email for invitation.";
      }

      const emailValidator = yup.string().email();

      for (const email of emails) {
        if (!(await emailValidator.isValid(email))) {
          errors.emails = `${email} is not a valid email address.`;
        }
      }

      if (Object.keys(errors).length > 0) {
        return errors;
      }
    },

    handleSubmit: async (values, formikProps) => {
      const {
        mutate,
        match: {
          params: { orgId },
        },

        location: { search },
        history,
        handleClose,
        reloader,
      } = formikProps.props;

      try {
        const result = await mutate({
          variables: {
            emails: values.emails.split("\n"),
            userType:
              values.userType &&
              ({
                owner: "Owner",
                admin: "Admin",
                manager: "Manager",
                member: "Member",
                limited_user: "Limited",
                gogetter: "Talent",
              } as any)[values.userType as string],
            programUuids:
              values.userType !== undefined &&
              canSpecifyPrograms(values.userType, values.canRequest, values.canSelfAssign)
                ? values.programs
                : [],
            canSelfAssign: values.canSelfAssign,
            canRequest: values.canRequest,
          },

          context: { clientName: "new-api" },

          refetchQueries: [{ query: usersQuery, variables: { orgId } }],
        });

        const inviteUsers = (result && result.data && result.data.inviteUsers) || [];

        if (result && result.data && result.data.inviteToOrganization) {
          if (result?.data?.inviteToOrganization?.__typename === "NotAllowedToInviteUsersWithHigherRoles") {
            return formikProps.setFieldError("emails", "You're not allowed to invite users with higher roles.");
          }

          if (result?.data?.inviteToOrganization?.__typename === "NoNewUserInvited") {
            return formikProps.setFieldError("emails", "All invited users are already part of the company.");
          }

          if (result?.data?.inviteToOrganization?.__typename === "AlreadyMembersOfOrganization") {
            if (result?.data?.inviteToOrganization?.emails) {
              const emails = result?.data?.inviteToOrganization?.emails.join(", ");
              return formikProps.setFieldError(
                "emails",
                `These emails are already members of the organization: ${emails}`,
              );
            } else {
              return formikProps.setFieldError("emails", "Some of the emails are already member of the organization.");
            }
          }

          if (result?.data?.inviteToOrganization?.__typename === "AlreadyInvitedToOrganization") {
            return formikProps.setFieldError("emails", "Some of the emails were already invited.");
          }
        }

        const failedInvites = inviteUsers.filter(
          (user: { inviteResponseStatus: string }) => user.inviteResponseStatus === "failed",
        );

        if (failedInvites.length === 0) {
          const { next } = getUrlQueries(search);

          if (next) {
            history.replace(next);
          } else {
            handleClose();
          }
        } else {
          formikProps.setFieldValue(
            "emails",
            failedInvites.map((user: { email: string }) => user.email).join("\n"),
            false,
          );
          formikProps.setFieldError(
            "emails",
            `${inflect(failedInvites.length, "contact", "contacts", true)} could not be invited`,
          );
        }
      } catch (e) {
        throw e;
      } finally {
        formikProps.setSubmitting(false);
        reloader.reload("NachoUserInvites");
      }
    },
  }),
)(InviteUsersModal);
