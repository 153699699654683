export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
  /** DateTime custom scalar type */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** Posix miliseconds */
  Posix: any;
};

export enum AccessLevel {
  Full = 'full',
  Limited = 'limited',
  None = 'none'
}

export type AgentOfThePayee = {
  __typename: 'AgentOfThePayee';
  answer: Scalars['Boolean'];
  answeredBy: Scalars['Int'];
  organizationId: Scalars['Int'];
};

export type AgentOfThePayeeInput = {
  answer: Scalars['Boolean'];
  answeredBy: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type AgreementSignatureAnswer = Answer & {
  __typename: 'AgreementSignatureAnswer';
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  questionId: Scalars['ID'];
  reportId: Scalars['ID'];
  signature?: Maybe<Scalars['String']>;
  state: AnswerState;
};

export type Answer = {
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  questionId: Scalars['ID'];
  reportId: Scalars['ID'];
  state: AnswerState;
};

export type AnswerInput = {
  payload: Scalars['String'];
  productId?: Maybe<Scalars['ID']>;
  questionId: Scalars['ID'];
  reportId: Scalars['ID'];
  state: AnswerState;
};

export enum AnswerState {
  Answered = 'answered',
  Na = 'na',
  Pending = 'pending',
  Unasked = 'unasked'
}

export enum AnswerType {
  AgreementSignature = 'agreement_signature',
  AtomChoiceMany = 'atom_choice_many',
  AtomChoiceOne = 'atom_choice_one',
  Contact = 'contact',
  Date = 'date',
  Expense = 'expense',
  Image = 'image',
  Location = 'location',
  Money = 'money',
  MoneyWithNegative = 'money_with_negative',
  MultiSelect = 'multi_select',
  MultipleChoiceMany = 'multiple_choice_many',
  MultipleChoiceOne = 'multiple_choice_one',
  Number = 'number',
  SingleSelect = 'single_select',
  Temperature = 'temperature',
  Text = 'text',
  Toggle = 'toggle'
}

export type Application = {
  __typename: 'Application';
  createdAt?: Maybe<Scalars['DateTime']>;
  gig: Gig;
  hasConflictingGig: Scalars['Boolean'];
  id: Scalars['ID'];
  talent: User;
  waitlisted: Scalars['Boolean'];
  withdrawnAt?: Maybe<Scalars['DateTime']>;
};

export type ArchiveOrganizationLocationInput = {
  locationId: Scalars['ID'];
};

export type AreaGigsInput = {
  distance: Scalars['Float'];
  filters: Scalars['JSON'];
  programIds: Array<Scalars['ID']>;
};

export type AreaInput = {
  distance: Scalars['Float'];
  location: GeoLocationInput;
};

export type Asset = {
  __typename: 'Asset';
  assetType?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPrimary?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  program?: Maybe<Program>;
  public?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Role>;
  status?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Atom = {
  __typename: 'Atom';
  elementTypeKey?: Maybe<Scalars['String']>;
  hashId: Scalars['String'];
  name: Scalars['String'];
};

export type AtomChoiceManyAnswer = Answer & {
  __typename: 'AtomChoiceManyAnswer';
  atoms?: Maybe<Array<Maybe<Atom>>>;
  id: Scalars['ID'];
  other?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  questionId: Scalars['ID'];
  reportId: Scalars['ID'];
  state: AnswerState;
};

export type AtomChoiceOneAnswer = Answer & {
  __typename: 'AtomChoiceOneAnswer';
  atom?: Maybe<Atom>;
  id: Scalars['ID'];
  other?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  questionId: Scalars['ID'];
  reportId: Scalars['ID'];
  state: AnswerState;
};

export type AtomChoiceQuestion = Question & {
  __typename: 'AtomChoiceQuestion';
  answerType: AnswerType;
  eachProduct: Scalars['Boolean'];
  footer?: Maybe<Scalars['String']>;
  gogetterTitle?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inputPlaceholder?: Maybe<Scalars['String']>;
  notApplicableDesc?: Maybe<Scalars['String']>;
  optional: Scalars['Boolean'];
  shortTitle?: Maybe<Scalars['String']>;
};

export enum AtomScope {
  Gig = 'gig',
  Organization = 'organization',
  Program = 'program'
}

export type BatchUpdateErrors = {
  __typename: 'BatchUpdateErrors';
  errorDetailsCsvLink: Scalars['String'];
  invalidCount: Scalars['Int'];
};

export type BillingContact = {
  __typename: 'BillingContact';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  displayEmail?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  nickname: Scalars['String'];
  primary: Scalars['Boolean'];
  state?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  zip?: Maybe<Scalars['String']>;
};

export type BillingContactInput = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type BooleanAnswer = Answer & {
  __typename: 'BooleanAnswer';
  boolean?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  questionId: Scalars['ID'];
  reportId: Scalars['ID'];
  state: AnswerState;
};

export type Brand = {
  __typename: 'Brand';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum CalendarDateStatus {
  Booked = 'booked',
  Unavailable = 'unavailable'
}

export enum CalendarWeekday {
  Fri = 'Fri',
  Mon = 'Mon',
  Sat = 'Sat',
  Sun = 'Sun',
  Thu = 'Thu',
  Tue = 'Tue',
  Wed = 'Wed'
}

export type CancellationReason = {
  __typename: 'CancellationReason';
  key: Scalars['String'];
  label: Scalars['String'];
};

export enum CancellationReasonConstraint {
  Dashboard = 'dashboard',
  SelfAssigned = 'selfAssigned'
}

export type ChangeEmailInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  newEmail: Scalars['String'];
  password: Scalars['String'];
};

export type ChangePasswordInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type ChangePasswordWithTokenInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

export type ChangePasswordWithTokenPayload = {
  __typename: 'ChangePasswordWithTokenPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  jwt: Scalars['String'];
  me: User;
};

export type CheckInData = {
  __typename: 'CheckInData';
  lastCheckIn?: Maybe<Scalars['String']>;
  lastStatus?: Maybe<Scalars['String']>;
  nextCheckIn?: Maybe<Scalars['String']>;
};

export type CheckInOutStatus = {
  __typename: 'CheckInOutStatus';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  time: Scalars['String'];
};

export type CodeRequestResult = {
  __typename: 'CodeRequestResult';
  nextAllowedRetry: Scalars['Float'];
  userExists: Scalars['Boolean'];
};

export type ConfirmEmailInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export type ConfirmEmailPayload = {
  __typename: 'ConfirmEmailPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  jwt: Scalars['String'];
  me: User;
};

export type ConnectionPageInfo = {
  __typename: 'ConnectionPageInfo';
  endCursor: Scalars['ID'];
  hasNextPage: Scalars['Boolean'];
  offset?: Maybe<Scalars['Int']>;
  totalCount: Scalars['Int'];
};

export type ContactAnswer = Answer & {
  __typename: 'ContactAnswer';
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  questionId: Scalars['ID'];
  reportId: Scalars['ID'];
  state: AnswerState;
};

export type Coordinates = {
  __typename: 'Coordinates';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CreateGigsInput = {
  agencyGuidance?: Maybe<Scalars['String']>;
  clientOrganizationId?: Maybe<Scalars['ID']>;
  copies?: Maybe<Scalars['Int']>;
  documents?: Maybe<Array<UpsertDocumentInput>>;
  duration?: Maybe<Scalars['Int']>;
  expenseNotes?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
  locationName?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  partnerOrganizationId?: Maybe<Scalars['ID']>;
  /**
   * productSelection and productIds are mutualy exclusive options to specify the products on a gig.
   * productIds will take precedent out of concern for compatibility, but is considered deprecated.
   */
  productSelection?: Maybe<IdSelectionInput>;
  programId: Scalars['ID'];
  roleId: Scalars['ID'];
  startTime?: Maybe<Scalars['String']>;
  talentId?: Maybe<Scalars['ID']>;
  talentOrganizationRate?: Maybe<Scalars['Float']>;
  talentRate?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  windowAssign?: Maybe<Scalars['Boolean']>;
  windowEndTime?: Maybe<Scalars['String']>;
  windowStartTime?: Maybe<Scalars['String']>;
};

export type CreateInvoiceVersionInput = {
  recipientOrganizationId: Scalars['ID'];
};

export type CreateLocationInput = {
  address?: Maybe<Scalars['String']>;
  addressJson?: Maybe<Scalars['JSON']>;
  externalId?: Maybe<Scalars['ID']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
};



export enum Direction {
  Asc = 'asc',
  AscNullsFirst = 'ascNullsFirst',
  Desc = 'desc',
  DescNullsLast = 'descNullsLast'
}

export type Document = {
  createdAt: Scalars['String'];
  createdBy: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  pkey: Scalars['Int'];
  status: DocumentStatus;
  updatedAt: Scalars['String'];
  url: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export enum DocumentObjectType {
  Gigs = 'gigs',
  OrganizationUsers = 'organization_users',
  Products = 'products',
  Programs = 'programs',
  Users = 'users'
}

export enum DocumentStatus {
  Active = 'active',
  Failed = 'failed',
  Inactive = 'inactive',
  Pending = 'pending'
}

export type EditUserPermissionsInput = {
  canRequest?: Maybe<Scalars['Boolean']>;
  canSelfAssign?: Maybe<Scalars['Boolean']>;
  contactOnProgramIds?: Maybe<Array<Scalars['ID']>>;
  programIds?: Maybe<Array<Scalars['ID']>>;
  reportingProgramId?: Maybe<Scalars['ID']>;
  userIds: Array<Scalars['ID']>;
  userType: OrganizationUserType;
};

/**
 * TODO: After elements feature is more established, move this into a separate
 * ElementSchema.graphql file
 *
 * Also, later, we might want to expose the element type key
 */
export type Element = {
  __typename: 'Element';
  createdAt: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  hashId: Scalars['String'];
  name: Scalars['String'];
  state: ElementState;
  updatedAt: Scalars['String'];
};

export enum ElementState {
  Active = 'active',
  Deleted = 'deleted',
  Inactive = 'inactive'
}

export type EmptyInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EmptyPayload = {
  __typename: 'EmptyPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Expense = {
  __typename: 'Expense';
  amount: Scalars['Float'];
  classKey: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy: User;
  id: Scalars['ID'];
  images: Array<Maybe<Scalars['String']>>;
  issuedAt: Scalars['String'];
  issuedBy: User;
  memo?: Maybe<Scalars['String']>;
  modifiedByManager?: Maybe<Scalars['Boolean']>;
  personal: Scalars['Boolean'];
  updatedAt: Scalars['String'];
  updatedBy: User;
};

export type ExpenseAnswer = Answer & {
  __typename: 'ExpenseAnswer';
  amount?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  questionId: Scalars['ID'];
  receipts: Array<Maybe<Receipt>>;
  reportId: Scalars['ID'];
  state: AnswerState;
};

export type ExpenseInput = {
  amount: Scalars['Float'];
  classKey?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  memo?: Maybe<Scalars['String']>;
  personal?: Maybe<Scalars['Boolean']>;
};

export type ExpenseQuestion = Question & {
  __typename: 'ExpenseQuestion';
  answerType: AnswerType;
  eachProduct: Scalars['Boolean'];
  footer?: Maybe<Scalars['String']>;
  gogetterTitle?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inputPlaceholder?: Maybe<Scalars['String']>;
  notApplicableDesc?: Maybe<Scalars['String']>;
  optional: Scalars['Boolean'];
  shortTitle?: Maybe<Scalars['String']>;
};

export type FileDocument = Document & {
  __typename: 'FileDocument';
  createdAt: Scalars['String'];
  createdBy: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  metadata: FileMetadata;
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  pkey: Scalars['Int'];
  status: DocumentStatus;
  updatedAt: Scalars['String'];
  url: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type FileMetadata = {
  __typename: 'FileMetadata';
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  size: Scalars['Int'];
};

export type FloatAnswer = Answer & {
  __typename: 'FloatAnswer';
  float?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  questionId: Scalars['ID'];
  reportId: Scalars['ID'];
  state: AnswerState;
};

export type FullQuestion = Question & {
  __typename: 'FullQuestion';
  answerType: AnswerType;
  atomScope: AtomScope;
  atomTagSqids?: Maybe<Array<Scalars['String']>>;
  bombonAST?: Maybe<Scalars['JSON']>;
  eachProduct: Scalars['Boolean'];
  elementSqids?: Maybe<Array<Scalars['String']>>;
  elementTypeKey?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSON']>;
  footer?: Maybe<Scalars['String']>;
  gogetterTitle?: Maybe<Scalars['String']>;
  groupKey?: Maybe<Scalars['String']>;
  hasOtherOption: Scalars['Boolean'];
  helper?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inputPlaceholder?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  notApplicableDesc?: Maybe<Scalars['String']>;
  optional: Scalars['Boolean'];
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  roleIds: Array<Scalars['ID']>;
  shortTitle?: Maybe<Scalars['String']>;
};

export type GeoLocation = {
  __typename: 'GeoLocation';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type GeoLocationInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type Gig = {
  __typename: 'Gig';
  adminLabel?: Maybe<Scalars['String']>;
  agencyGuidance?: Maybe<Scalars['String']>;
  applicationCount: Scalars['Int'];
  applications?: Maybe<Array<Application>>;
  applyHash?: Maybe<Scalars['String']>;
  approvalRequestedAt?: Maybe<Scalars['String']>;
  approvalReviewMessage?: Maybe<Scalars['String']>;
  approvalReviewedAt?: Maybe<Scalars['String']>;
  approvalReviewedBy?: Maybe<User>;
  assignedApplication?: Maybe<Application>;
  /** @deprecated Name changed. Please use talent instead. Same result. */
  assignedGogetter?: Maybe<User>;
  blocked?: Maybe<Scalars['Boolean']>;
  cancellationReason?: Maybe<CancellationReason>;
  cancellationReasonDescription?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['String']>;
  cancelledBy?: Maybe<User>;
  cancelledByOrganization?: Maybe<Organization>;
  checkinStatus?: Maybe<CheckInOutStatus>;
  checkoutStatus?: Maybe<CheckInOutStatus>;
  clientOrganization?: Maybe<Organization>;
  compareWorkflowStateOrder: Scalars['Int'];
  confirmationHistory?: Maybe<Array<GigConfirmation>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  customLocationName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  distanceFromSearchCenterInMeters?: Maybe<Scalars['Float']>;
  documents?: Maybe<Array<Document>>;
  duration?: Maybe<Scalars['Int']>;
  /** editable means "there exists someone who may edit this gig". It does not indicate whether the current user, in particular, may edit the gig. */
  editable: Scalars['Boolean'];
  endTime?: Maybe<Scalars['String']>;
  expenseMemo?: Maybe<Scalars['String']>;
  expenseNotes?: Maybe<Scalars['String']>;
  expenses: Array<GigExpense>;
  finalVerification?: Maybe<GigFinalVerification>;
  id: Scalars['ID'];
  interactions?: Maybe<Scalars['Float']>;
  invoice?: Maybe<InvoiceVersion>;
  invoiceManagementState?: Maybe<Scalars['String']>;
  isReportComplete: Scalars['Boolean'];
  kind?: Maybe<Scalars['String']>;
  localEndTime?: Maybe<Scalars['String']>;
  localStartTime?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  locationNameDistance?: Maybe<Scalars['Int']>;
  manager?: Maybe<User>;
  managerAmount: Scalars['Float'];
  managerExpenseAmount: Scalars['Float'];
  managerLaborAmount: Scalars['Float'];
  managerOrganization?: Maybe<Organization>;
  myApplication?: Maybe<Application>;
  myRate?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  organizationLocation?: Maybe<OrganizationLocation>;
  organizationLocationId?: Maybe<Scalars['ID']>;
  ownerOrganization: Organization;
  partnerOrganization?: Maybe<Organization>;
  pkey?: Maybe<Scalars['Int']>;
  products: Array<Product>;
  program: Program;
  programId: Scalars['ID'];
  programRequest: ProgramRequest;
  programRole?: Maybe<ProgramRole>;
  questions: Array<Question>;
  report: Array<ReportGroup>;
  reportAnswers: Array<Answer>;
  reportReviewMessage?: Maybe<Scalars['String']>;
  reportReviewedAt?: Maybe<Scalars['String']>;
  reportReviewedBy?: Maybe<User>;
  requestedBy?: Maybe<User>;
  role: Role;
  samples?: Maybe<Scalars['Float']>;
  selfAssignUserCanEditWhenGigHasSelfAssignableActivity: Scalars['Boolean'];
  startTime?: Maybe<Scalars['String']>;
  state?: Maybe<GigState>;
  stateGroup: Scalars['String'];
  talent?: Maybe<User>;
  talentContacts: Array<User>;
  talentExpenses?: Maybe<Scalars['Boolean']>;
  talentLocation?: Maybe<Location>;
  talentLocationDistance?: Maybe<Scalars['Float']>;
  talentOrganization?: Maybe<Organization>;
  talentOrganizationRate?: Maybe<Scalars['Float']>;
  talentRate?: Maybe<Scalars['Float']>;
  talentSelection?: Maybe<UserSelection>;
  talentTagSelection?: Maybe<TagSelection>;
  taskState: TaskState;
  taskStateGroup: TaskGroup;
  taskStateSection: TaskSection;
  timezone?: Maybe<Scalars['String']>;
  timezoneOffset?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  totalExpenses?: Maybe<Scalars['Float']>;
  totalHours: Scalars['Float'];
  unitsSold?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['String'];
  visibility?: Maybe<GigVisibility>;
  windowAssign: Scalars['Boolean'];
  windowEndTime?: Maybe<Scalars['String']>;
  windowLocalEndTime?: Maybe<Scalars['String']>;
  windowLocalStartTime?: Maybe<Scalars['String']>;
  windowStartTime?: Maybe<Scalars['String']>;
  workflowState: Scalars['String'];
  workflowStateGroup: Scalars['String'];
  workflowStateKey: Scalars['String'];
  workflowStateNameKey: Scalars['String'];
  workflowStateOrder: Scalars['Int'];
  workflowStateSection: Scalars['String'];
};


export type GigCompareWorkflowStateOrderArgs = {
  compareToKey: Scalars['String'];
};


export type GigReportAnswersArgs = {
  state?: Maybe<AnswerState>;
};


export type GigTitleArgs = {
  useDefault?: Maybe<Scalars['Boolean']>;
};

export type GigActionCount = {
  __typename: 'GigActionCount';
  actionKey: Scalars['String'];
  actionKeyCount: Scalars['Int'];
  actionOrder: Scalars['Int'];
  actionPrimary: Scalars['Boolean'];
};

export type GigAssignableUser = {
  __typename: 'GigAssignableUser';
  distance?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  status: GigAssignableUserStatus;
  user: User;
};

export enum GigAssignableUserStatus {
  Applied = 'applied',
  Available = 'available',
  Conflict = 'conflict',
  Pending = 'pending',
  Unavailable = 'unavailable',
  Unknown = 'unknown',
  Withdrawn = 'withdrawn'
}

export type GigConfirmation = {
  __typename: 'GigConfirmation';
  createdAt: Scalars['DateTime'];
  createdBy: User;
  gig: Gig;
  message?: Maybe<Scalars['String']>;
  state: GigConfirmationState;
};

export enum GigConfirmationState {
  Confirmed = 'confirmed',
  Rejected = 'rejected',
  Requested = 'requested'
}

export type GigCreationResult = {
  __typename: 'GigCreationResult';
  gigIds: Array<Scalars['ID']>;
  uploadId: Scalars['ID'];
};

export type GigExpense = {
  __typename: 'GigExpense';
  byAgency: Scalars['Boolean'];
  chargeAmount?: Maybe<Scalars['Float']>;
  expense: Expense;
  gigId: Scalars['ID'];
  invoiceId: Scalars['ID'];
  reimburseAmount?: Maybe<Scalars['Float']>;
};

export type GigExpenseInput = {
  byAgency?: Maybe<Scalars['Boolean']>;
  chargeAmount?: Maybe<Scalars['Float']>;
  expense: ExpenseInput;
  expenseId?: Maybe<Scalars['ID']>;
  reimburseAmount?: Maybe<Scalars['Float']>;
};

export type GigFinalVerification = {
  __typename: 'GigFinalVerification';
  createdAt: Scalars['String'];
  createdBy: User;
  gig: Gig;
  id: Scalars['ID'];
  isVerified: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GigGroup = {
  __typename: 'GigGroup';
  count: Scalars['Int'];
  key: Scalars['String'];
  states: Array<GigState>;
};

export type GigIcsInfo = {
  __typename: 'GigIcsInfo';
  ics: Scalars['String'];
  id: Scalars['ID'];
  startTime?: Maybe<Scalars['String']>;
  windowStartTime?: Maybe<Scalars['String']>;
};

export enum GigOrganizationType {
  Manager = 'manager',
  Owner = 'owner',
  Talent = 'talent'
}

export type GigPartnerAssignment = {
  __typename: 'GigPartnerAssignment';
  canAssign: Scalars['Boolean'];
  hasPartners: Scalars['Boolean'];
  isProgramOwnerOrganization: Scalars['Boolean'];
};

export enum GigReportState {
  Pending = 'pending',
  Rejected = 'rejected',
  Returned = 'returned',
  Submitted = 'submitted',
  Verified = 'verified'
}

export type GigReportStep = {
  __typename: 'GigReportStep';
  id: Scalars['String'];
  key: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
  productBrandId?: Maybe<Scalars['ID']>;
  productId?: Maybe<Scalars['ID']>;
  productTagIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  progress: Scalars['Float'];
  questions: Array<FullQuestion>;
  shortDesc: Scalars['String'];
  state: Scalars['JSON'];
  stateChecksum: Scalars['String'];
};

export type GigSection = {
  __typename: 'GigSection';
  count: Scalars['Int'];
  groups: Array<GigGroup>;
};

export type GigState = {
  __typename: 'GigState';
  count: Scalars['Int'];
  isActionable?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
};

export type GigStateSections = {
  __typename: 'GigStateSections';
  active: GigSection;
  finished: GigSection;
  other: GigSection;
};

export type GigStats = {
  __typename: 'GigStats';
  past: Scalars['Int'];
  upcoming: Scalars['Int'];
};

export enum GigVisibility {
  Apply = 'apply',
  Claim = 'claim',
  Private = 'private'
}

export type IdSelectionInput = {
  type: SelectionType;
  values: Array<Scalars['ID']>;
};

export type ImageAnswer = Answer & {
  __typename: 'ImageAnswer';
  id: Scalars['ID'];
  images?: Maybe<Array<Scalars['String']>>;
  payload?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  questionId: Scalars['ID'];
  reportId: Scalars['ID'];
  state: AnswerState;
};

export type ImageQuestion = Question & {
  __typename: 'ImageQuestion';
  answerType: AnswerType;
  eachProduct: Scalars['Boolean'];
  footer?: Maybe<Scalars['String']>;
  gogetterTitle?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  notApplicableDesc?: Maybe<Scalars['String']>;
  optional: Scalars['Boolean'];
  shortTitle?: Maybe<Scalars['String']>;
};

export type ImpersonateInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  impersonation: Scalars['String'];
  password: Scalars['String'];
};

export type Industry = {
  __typename: 'Industry';
  id: Scalars['ID'];
  name: Scalars['String'];
  shortName: Scalars['String'];
};

export enum InitialGroup {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  NonAlpha = 'NonAlpha',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  T = 'T',
  U = 'U',
  V = 'V',
  W = 'W',
  X = 'X',
  Y = 'Y',
  Z = 'Z'
}

export type InitialGroupIndex = {
  __typename: 'InitialGroupIndex';
  group: InitialGroup;
  index: Scalars['Int'];
};

export type IntAnswer = Answer & {
  __typename: 'IntAnswer';
  id: Scalars['ID'];
  int?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  questionId: Scalars['ID'];
  reportId: Scalars['ID'];
  state: AnswerState;
};

export type InvitationCode = {
  __typename: 'InvitationCode';
  codeDeliveryExplanation?: Maybe<Scalars['String']>;
  defaultRate?: Maybe<Scalars['Float']>;
  explanation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  organization: Organization;
  reportingProgram: Program;
  token: Scalars['String'];
  userType: OrganizationUserType;
};

export type InvitationCodeInput = {
  defaultRate?: Maybe<Scalars['Float']>;
  reportingProgramId?: Maybe<Scalars['ID']>;
  userType: OrganizationUserType;
};

export type InvitationCodeSignIn = {
  __typename: 'InvitationCodeSignIn';
  jwt: Scalars['String'];
  me: User;
};

export type InviteInput = {
  emails: Array<Maybe<Scalars['String']>>;
  organizationId: Scalars['ID'];
  programIds?: Maybe<Array<Scalars['ID']>>;
  reportingProgramId?: Maybe<Scalars['ID']>;
  userType: OrganizationUserType;
};

export type InvitePayloadItem = {
  __typename: 'InvitePayloadItem';
  email: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  inviteResponseStatus: InviteResponseStatus;
  name?: Maybe<Scalars['String']>;
};

export enum InviteResponseStatus {
  Added = 'added',
  Existing = 'existing',
  Failed = 'failed',
  Invited = 'invited'
}

export enum InvoiceAction {
  Approve = 'approve',
  ApproveTreasurer = 'approveTreasurer',
  Delete = 'delete',
  Download = 'download',
  Duplicate = 'duplicate',
  Finalize = 'finalize',
  Issue = 'issue',
  MarkPaymentProcessed = 'markPaymentProcessed',
  MarkPaymentReceived = 'markPaymentReceived',
  Reject = 'reject',
  Retract = 'retract',
  Unapprove = 'unapprove',
  UndoMarkPaymentProcessed = 'undoMarkPaymentProcessed',
  UndoMarkPaymentReceived = 'undoMarkPaymentReceived',
  Update = 'update',
  Void = 'void'
}

export type InvoiceActionCreateVersion = {
  __typename: 'InvoiceActionCreateVersion';
  id: Scalars['ID'];
  newInvoiceVersionId: Scalars['ID'];
};

export type InvoiceActionCreateVersionResult = {
  __typename: 'InvoiceActionCreateVersionResult';
  updated: Array<InvoiceActionCreateVersion>;
};

export type InvoiceActionUpdateVersionResult = {
  __typename: 'InvoiceActionUpdateVersionResult';
  updated: Array<Scalars['ID']>;
};

export type InvoiceExpense = {
  __typename: 'InvoiceExpense';
  expense: Expense;
  expenseId: Scalars['ID'];
  invoiceVersionId: Scalars['ID'];
};

export type InvoiceGig = {
  __typename: 'InvoiceGig';
  gig: Gig;
  invoiceVersionId: Scalars['ID'];
};

export type InvoiceHistory = {
  __typename: 'InvoiceHistory';
  action: InvoiceHistoryAction;
  createdAt: Scalars['DateTime'];
  notes?: Maybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  organizationName: Scalars['String'];
  userFirstName?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
  userLastName?: Maybe<Scalars['String']>;
};

export enum InvoiceHistoryAction {
  Approve = 'approve',
  ApproveTreasurer = 'approveTreasurer',
  Create = 'create',
  Delete = 'delete',
  Download = 'download',
  Duplicate = 'duplicate',
  Finalize = 'finalize',
  Issue = 'issue',
  MarkPaymentProcessed = 'markPaymentProcessed',
  MarkPaymentReceived = 'markPaymentReceived',
  ProcessFinalize = 'processFinalize',
  ProcessIssue = 'processIssue',
  Queue = 'queue',
  Reject = 'reject',
  Retract = 'retract',
  UndoMarkPaymentProcessed = 'undoMarkPaymentProcessed',
  UndoMarkPaymentReceived = 'undoMarkPaymentReceived',
  Update = 'update',
  Void = 'void'
}

export type InvoiceVersion = {
  __typename: 'InvoiceVersion';
  actions: Array<InvoiceAction>;
  downloadToken: Scalars['String'];
  dueDate?: Maybe<Scalars['String']>;
  dueDays?: Maybe<Scalars['Int']>;
  firstGigDate?: Maybe<Scalars['String']>;
  gigAvgRate?: Maybe<Scalars['Float']>;
  gigCount?: Maybe<Scalars['Int']>;
  gigExpenseAmount?: Maybe<Scalars['Float']>;
  gigLaborAmount?: Maybe<Scalars['Float']>;
  gigTotalAmount?: Maybe<Scalars['Float']>;
  gigTotalHours?: Maybe<Scalars['Float']>;
  history: Array<InvoiceHistory>;
  id: Scalars['ID'];
  invoiceExpenseAmount?: Maybe<Scalars['Float']>;
  invoiceId: Scalars['ID'];
  issuedAt?: Maybe<Scalars['DateTime']>;
  issuedBy?: Maybe<User>;
  issuerContact?: Maybe<BillingContact>;
  issuerOrganization: Organization;
  lastGigDate?: Maybe<Scalars['String']>;
  managerNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  programNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  recipientContact?: Maybe<BillingContact>;
  recipientOrganization: Organization;
  startDate?: Maybe<Scalars['String']>;
  stateKey: InvoiceVersionState;
  totalAmount?: Maybe<Scalars['Float']>;
  treasurerCompliant?: Maybe<Scalars['Boolean']>;
  treasurerInvoiceId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
};

export type InvoiceVersionProgramTotal = {
  __typename: 'InvoiceVersionProgramTotal';
  accountingCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  total: Scalars['Float'];
};

export enum InvoiceVersionState {
  Approved = 'approved',
  Draft = 'draft',
  DraftRejected = 'draftRejected',
  DraftRetracted = 'draftRetracted',
  Finalized = 'finalized',
  Issued = 'issued',
  PaymentProcessed = 'paymentProcessed',
  PaymentProcessedAndReceived = 'paymentProcessedAndReceived',
  PaymentReceived = 'paymentReceived',
  ProcessingFinalize = 'processingFinalize',
  ProcessingFinalizeError = 'processingFinalizeError',
  ProcessingFinalizePending = 'processingFinalizePending',
  ProcessingIssue = 'processingIssue',
  ProcessingIssueError = 'processingIssueError',
  ProcessingIssuePending = 'processingIssuePending',
  Rejected = 'rejected',
  Retracted = 'retracted',
  Treasurer = 'treasurer',
  TreasurerPending = 'treasurerPending',
  TreasurerQueued = 'treasurerQueued',
  TreasurerRejected = 'treasurerRejected',
  Voided = 'voided'
}

export type InvoiceVersionStats = {
  __typename: 'InvoiceVersionStats';
  gigAvgRate: Scalars['Float'];
  gigExpenseAmount: Scalars['Float'];
  gigLaborAmount: Scalars['Float'];
  gigTotalAmount: Scalars['Float'];
  gigTotalHours: Scalars['Float'];
  invoiceExpenseAmount: Scalars['Float'];
  programs: Array<InvoiceVersionProgramTotal>;
  totalAmount: Scalars['Float'];
};


export type LinkDocument = Document & {
  __typename: 'LinkDocument';
  createdAt: Scalars['String'];
  createdBy: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  pkey: Scalars['Int'];
  status: DocumentStatus;
  updatedAt: Scalars['String'];
  url: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type Location = {
  __typename: 'Location';
  address?: Maybe<Scalars['String']>;
  addressJson?: Maybe<Scalars['JSON']>;
  adminLabel?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  state?: Maybe<Scalars['String']>;
};

export type LocationAnswer = Answer & {
  __typename: 'LocationAnswer';
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  questionId: Scalars['ID'];
  reportId: Scalars['ID'];
  state: AnswerState;
};

export type LoginInput = {
  acceptedTOS?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginPayload = {
  __typename: 'LoginPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  jwt: Scalars['String'];
  me: User;
};

export enum MagicLoginErrorType {
  AlreadyUsed = 'alreadyUsed',
  Expired = 'expired'
}

export type MagicLoginInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export type MagicLoginPayload = {
  __typename: 'MagicLoginPayload';
  continueUrl?: Maybe<Scalars['String']>;
  jwt?: Maybe<Scalars['String']>;
  loginError?: Maybe<MagicLoginErrorType>;
  me?: Maybe<User>;
};

export type MoneyAnswer = Answer & {
  __typename: 'MoneyAnswer';
  id: Scalars['ID'];
  money?: Maybe<Scalars['Float']>;
  payload?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  questionId: Scalars['ID'];
  reportId: Scalars['ID'];
  state: AnswerState;
};

export type MoneyWithNegativeAnswer = Answer & {
  __typename: 'MoneyWithNegativeAnswer';
  id: Scalars['ID'];
  money?: Maybe<Scalars['Float']>;
  payload?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  questionId: Scalars['ID'];
  reportId: Scalars['ID'];
  state: AnswerState;
};

export type MoneyWithNegativeQuestion = Question & {
  __typename: 'MoneyWithNegativeQuestion';
  answerType: AnswerType;
  eachProduct: Scalars['Boolean'];
  footer?: Maybe<Scalars['String']>;
  gogetterTitle?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inputPlaceholder?: Maybe<Scalars['String']>;
  notApplicableDesc?: Maybe<Scalars['String']>;
  optional: Scalars['Boolean'];
  shortTitle?: Maybe<Scalars['String']>;
};

export type MultipleChoiceManyAnswer = Answer & {
  __typename: 'MultipleChoiceManyAnswer';
  choices?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  questionId: Scalars['ID'];
  reportId: Scalars['ID'];
  state: AnswerState;
};

export type MultipleChoiceOneAnswer = Answer & {
  __typename: 'MultipleChoiceOneAnswer';
  choice?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  questionId: Scalars['ID'];
  reportId: Scalars['ID'];
  state: AnswerState;
};

export type MultipleChoiceQuestion = Question & {
  __typename: 'MultipleChoiceQuestion';
  answerType: AnswerType;
  eachProduct: Scalars['Boolean'];
  footer?: Maybe<Scalars['String']>;
  gogetterTitle?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  notApplicableDesc?: Maybe<Scalars['String']>;
  optional: Scalars['Boolean'];
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortTitle?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename: 'Mutation';
  addBillingContact?: Maybe<BillingContact>;
  addGigsToInvoice?: Maybe<Scalars['Int']>;
  applyToGig?: Maybe<Application>;
  approvalCancelGig: Scalars['Int'];
  approveGig: Scalars['Int'];
  approveInvoice?: Maybe<InvoiceActionUpdateVersionResult>;
  archiveOrganizationLocation?: Maybe<OrganizationLocation>;
  authorizePayment: Scalars['Int'];
  batchUpdateGigs?: Maybe<BatchUpdateErrors>;
  cancelGigs?: Maybe<Scalars['Int']>;
  checkIn?: Maybe<Gig>;
  checkOut?: Maybe<Gig>;
  cloneOrganization?: Maybe<Scalars['Boolean']>;
  confirmGig: Scalars['Int'];
  createGigs: GigCreationResult;
  createInvitationCode?: Maybe<InvitationCode>;
  createInvoice?: Maybe<InvoiceVersion>;
  createReportDashboardPdf: Scalars['ID'];
  createRoles: Array<Scalars['ID']>;
  deleteDraftGigs?: Maybe<Scalars['Int']>;
  deleteGigExpense?: Maybe<Scalars['Boolean']>;
  deleteInvoice?: Maybe<InvoiceActionUpdateVersionResult>;
  deleteInvoiceExpense?: Maybe<Scalars['Boolean']>;
  disableInvitationCode?: Maybe<InvitationCode>;
  duplicateInvoice?: Maybe<InvoiceActionCreateVersionResult>;
  duplicateProgram?: Maybe<Scalars['ID']>;
  editUserPermissions?: Maybe<Array<Maybe<User>>>;
  finalizeInvoice?: Maybe<InvoiceActionUpdateVersionResult>;
  issueInvoice?: Maybe<InvoiceActionUpdateVersionResult>;
  markGigsClosed?: Maybe<Scalars['Int']>;
  markPaymentProcessedInvoice?: Maybe<InvoiceActionUpdateVersionResult>;
  markPaymentReceivedInvoice?: Maybe<InvoiceActionUpdateVersionResult>;
  ownerReturnGigReport: Scalars['Int'];
  ownerVerifyGigReport: Scalars['Int'];
  rejectGigConfirmation: Scalars['Int'];
  rejectGigReport: Scalars['Int'];
  rejectInvoice?: Maybe<InvoiceActionCreateVersionResult>;
  removeGigsFromInvoice?: Maybe<Scalars['Boolean']>;
  removePartners: Scalars['Boolean'];
  removeProgramGroup: Scalars['Boolean'];
  requestGigApproval: Scalars['Int'];
  requestGigConfirmation: Scalars['Int'];
  retractInvoice?: Maybe<InvoiceActionCreateVersionResult>;
  returnGig: Scalars['Int'];
  returnGigReport: Scalars['Int'];
  saveGigReportStep?: Maybe<GigReportStep>;
  setProductActive?: Maybe<Array<Maybe<Scalars['String']>>>;
  setProgramManagementState?: Maybe<ProgramRequest>;
  submitGigReport?: Maybe<Gig>;
  undoMarkGigsClosed?: Maybe<Scalars['Int']>;
  undoMarkPaymentProcessedInvoice?: Maybe<InvoiceActionUpdateVersionResult>;
  undoMarkPaymentReceivedInvoice?: Maybe<InvoiceActionUpdateVersionResult>;
  undoReviewGigs?: Maybe<Scalars['Int']>;
  untrashGigs?: Maybe<Scalars['Int']>;
  updateBillingContact?: Maybe<BillingContact>;
  updateCalendarWeekdays: Array<CalendarWeekday>;
  updateGigExpenses?: Maybe<Array<Maybe<Scalars['String']>>>;
  updateGigRoster?: Maybe<Scalars['Int']>;
  updateGigs?: Maybe<Array<Maybe<Gig>>>;
  updateInvoice?: Maybe<InvoiceActionUpdateVersionResult>;
  updateMyAssets?: Maybe<Array<Asset>>;
  updateMyDetails?: Maybe<UpdateMyDetailsPayload>;
  updateRoles: Scalars['Int'];
  updateUnavailableDatesByMonth: Array<UserCalendarDate>;
  upsertAgentOfThePayee?: Maybe<AgentOfThePayee>;
  upsertDocument?: Maybe<Document>;
  upsertDocuments?: Maybe<Array<Maybe<Document>>>;
  upsertGigExpense?: Maybe<GigExpense>;
  upsertInvoiceExpense?: Maybe<InvoiceExpense>;
  upsertOrganizationLocations: UpsertOrganizationLocationResult;
  upsertOrganizationProducts: UpsertOrganizationProductResult;
  upsertOrganizationUser?: Maybe<OrganizationUser>;
  upsertPartnerPrograms: Scalars['Boolean'];
  upsertProduct?: Maybe<Product>;
  upsertProgramGroup?: Maybe<Scalars['ID']>;
  upsertProgramRequest?: Maybe<ProgramRequest>;
  upsertTag?: Maybe<Tag>;
  upsertUICustomization: UiCustomization;
  upsertUserProfile?: Maybe<UserProfile>;
  verifyGigReport: Scalars['Int'];
  voidInvoice?: Maybe<InvoiceActionUpdateVersionResult>;
  withdrawApplication?: Maybe<Application>;
};


export type MutationAddBillingContactArgs = {
  contact: BillingContactInput;
  organizationId?: Maybe<Scalars['String']>;
};


export type MutationAddGigsToInvoiceArgs = {
  filters?: Maybe<Scalars['JSON']>;
  invoiceVersionId: Scalars['ID'];
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationApplyToGigArgs = {
  applyHash?: Maybe<Scalars['String']>;
  gigId?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};


export type MutationApprovalCancelGigArgs = {
  filters?: Maybe<Scalars['JSON']>;
  message?: Maybe<Scalars['String']>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationApproveGigArgs = {
  filters?: Maybe<Scalars['JSON']>;
  message?: Maybe<Scalars['String']>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationApproveInvoiceArgs = {
  filters?: Maybe<Scalars['JSON']>;
  notes?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


export type MutationArchiveOrganizationLocationArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationAuthorizePaymentArgs = {
  filters?: Maybe<Scalars['JSON']>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  willPay?: Maybe<Scalars['Boolean']>;
};


export type MutationBatchUpdateGigsArgs = {
  filters?: Maybe<Scalars['JSON']>;
  input: UpdateGigsInput;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationCancelGigsArgs = {
  cancellationType?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSON']>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  reasonDescription?: Maybe<Scalars['String']>;
  reasonKey?: Maybe<Scalars['String']>;
};


export type MutationCheckInArgs = {
  gigId?: Maybe<Scalars['ID']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
};


export type MutationCheckOutArgs = {
  gigId?: Maybe<Scalars['ID']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
};


export type MutationCloneOrganizationArgs = {
  organizationId: Scalars['ID'];
};


export type MutationConfirmGigArgs = {
  filters?: Maybe<Scalars['JSON']>;
  message?: Maybe<Scalars['String']>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationCreateGigsArgs = {
  gigs: Array<Maybe<CreateGigsInput>>;
  uploadId?: Maybe<Scalars['ID']>;
};


export type MutationCreateInvitationCodeArgs = {
  input?: Maybe<InvitationCodeInput>;
};


export type MutationCreateInvoiceArgs = {
  input: CreateInvoiceVersionInput;
};


export type MutationCreateReportDashboardPdfArgs = {
  app: ReportDashboardApp;
  dashboardKey?: Maybe<Scalars['ID']>;
  exclude?: Maybe<Scalars['Boolean']>;
  programIds?: Maybe<Array<Scalars['ID']>>;
  viewport?: Maybe<Scalars['JSON']>;
};


export type MutationCreateRolesArgs = {
  roles: Array<RoleInput>;
};


export type MutationDeleteDraftGigsArgs = {
  filters: Scalars['JSON'];
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationDeleteGigExpenseArgs = {
  expenseId: Scalars['ID'];
  gigId: Scalars['ID'];
};


export type MutationDeleteInvoiceArgs = {
  filters?: Maybe<Scalars['JSON']>;
  notes?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


export type MutationDeleteInvoiceExpenseArgs = {
  expenseId: Scalars['ID'];
  invoiceVersionId: Scalars['ID'];
};


export type MutationDisableInvitationCodeArgs = {
  id: Scalars['ID'];
};


export type MutationDuplicateInvoiceArgs = {
  filters?: Maybe<Scalars['JSON']>;
  notes?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


export type MutationDuplicateProgramArgs = {
  programId: Scalars['ID'];
};


export type MutationEditUserPermissionsArgs = {
  input: EditUserPermissionsInput;
};


export type MutationFinalizeInvoiceArgs = {
  filters?: Maybe<Scalars['JSON']>;
  notes?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


export type MutationIssueInvoiceArgs = {
  filters?: Maybe<Scalars['JSON']>;
  notes?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


export type MutationMarkGigsClosedArgs = {
  filters: Scalars['JSON'];
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationMarkPaymentProcessedInvoiceArgs = {
  filters?: Maybe<Scalars['JSON']>;
  notes?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


export type MutationMarkPaymentReceivedInvoiceArgs = {
  filters?: Maybe<Scalars['JSON']>;
  notes?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


export type MutationOwnerReturnGigReportArgs = {
  filters: Scalars['JSON'];
  message?: Maybe<Scalars['String']>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationOwnerVerifyGigReportArgs = {
  filters: Scalars['JSON'];
  message?: Maybe<Scalars['String']>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationRejectGigConfirmationArgs = {
  filters?: Maybe<Scalars['JSON']>;
  message?: Maybe<Scalars['String']>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationRejectGigReportArgs = {
  filters: Scalars['JSON'];
  message?: Maybe<Scalars['String']>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationRejectInvoiceArgs = {
  filters?: Maybe<Scalars['JSON']>;
  notes?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


export type MutationRemoveGigsFromInvoiceArgs = {
  gigIds: Array<Scalars['ID']>;
  invoiceVersionId: Scalars['ID'];
};


export type MutationRemovePartnersArgs = {
  partnerId: Scalars['ID'];
};


export type MutationRemoveProgramGroupArgs = {
  groupId: Scalars['ID'];
};


export type MutationRequestGigApprovalArgs = {
  filters?: Maybe<Scalars['JSON']>;
  message?: Maybe<Scalars['String']>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationRequestGigConfirmationArgs = {
  filters?: Maybe<Scalars['JSON']>;
  message?: Maybe<Scalars['String']>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationRetractInvoiceArgs = {
  filters?: Maybe<Scalars['JSON']>;
  notes?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


export type MutationReturnGigArgs = {
  filters?: Maybe<Scalars['JSON']>;
  message?: Maybe<Scalars['String']>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationReturnGigReportArgs = {
  filters: Scalars['JSON'];
  message?: Maybe<Scalars['String']>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationSaveGigReportStepArgs = {
  input: SaveGigReportStepInput;
};


export type MutationSetProductActiveArgs = {
  input: SetProductActiveInput;
};


export type MutationSetProgramManagementStateArgs = {
  managementState: ProgramManagementState;
  programId: Scalars['ID'];
};


export type MutationSubmitGigReportArgs = {
  gigId?: Maybe<Scalars['ID']>;
};


export type MutationUndoMarkGigsClosedArgs = {
  filters: Scalars['JSON'];
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationUndoMarkPaymentProcessedInvoiceArgs = {
  filters?: Maybe<Scalars['JSON']>;
  notes?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


export type MutationUndoMarkPaymentReceivedInvoiceArgs = {
  filters?: Maybe<Scalars['JSON']>;
  notes?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


export type MutationUndoReviewGigsArgs = {
  filters: Scalars['JSON'];
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationUntrashGigsArgs = {
  filters: Scalars['JSON'];
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationUpdateBillingContactArgs = {
  contact: BillingContactInput;
  id: Scalars['ID'];
  organizationId?: Maybe<Scalars['String']>;
};


export type MutationUpdateCalendarWeekdaysArgs = {
  calendarWeekdays: Array<CalendarWeekday>;
};


export type MutationUpdateGigExpensesArgs = {
  gigId: Scalars['ID'];
  hasTalentExpenses?: Maybe<Scalars['Boolean']>;
  input?: Maybe<Array<Maybe<GigExpenseInput>>>;
};


export type MutationUpdateGigRosterArgs = {
  filters?: Maybe<Scalars['JSON']>;
  input: UpdateGigRosterInput;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationUpdateGigsArgs = {
  filters?: Maybe<Scalars['JSON']>;
  input: UpdateGigsInput;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationUpdateInvoiceArgs = {
  filters?: Maybe<Scalars['JSON']>;
  input: UpdateInvoiceVersionInput;
  search?: Maybe<Scalars['String']>;
};


export type MutationUpdateMyAssetsArgs = {
  assets: Array<UserAsset>;
};


export type MutationUpdateMyDetailsArgs = {
  input: UpdateMyDetailsInput;
};


export type MutationUpdateRolesArgs = {
  filters?: Maybe<Scalars['JSON']>;
  role: RoleInput;
};


export type MutationUpdateUnavailableDatesByMonthArgs = {
  days: Array<Scalars['Int']>;
  month: Scalars['Int'];
  year: Scalars['Int'];
};


export type MutationUpsertAgentOfThePayeeArgs = {
  input: AgentOfThePayeeInput;
};


export type MutationUpsertDocumentArgs = {
  input: UpsertDocumentInput;
};


export type MutationUpsertDocumentsArgs = {
  input?: Maybe<Array<UpsertDocumentInput>>;
  organizationId: Scalars['ID'];
};


export type MutationUpsertGigExpenseArgs = {
  chargeAmount?: Maybe<Scalars['Float']>;
  expenseId?: Maybe<Scalars['ID']>;
  gigId: Scalars['ID'];
  input?: Maybe<ExpenseInput>;
  reimburseAmount?: Maybe<Scalars['Float']>;
};


export type MutationUpsertInvoiceExpenseArgs = {
  expenseId?: Maybe<Scalars['ID']>;
  input?: Maybe<ExpenseInput>;
  invoiceVersionId: Scalars['ID'];
};


export type MutationUpsertOrganizationLocationsArgs = {
  input: Array<UpsertOrganizationLocationInput>;
};


export type MutationUpsertOrganizationProductsArgs = {
  input: Array<UpsertOrganizationProductInput>;
};


export type MutationUpsertOrganizationUserArgs = {
  input: OrganizationUserInput;
};


export type MutationUpsertPartnerProgramsArgs = {
  filters?: Maybe<Scalars['JSON']>;
  partnerId: Scalars['ID'];
};


export type MutationUpsertProductArgs = {
  input: UpsertProductInput;
};


export type MutationUpsertProgramGroupArgs = {
  groupId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  programFilters?: Maybe<Scalars['JSON']>;
};


export type MutationUpsertProgramRequestArgs = {
  input: ProgramRequestInput;
};


export type MutationUpsertTagArgs = {
  input: UpsertTagInput;
};


export type MutationUpsertUiCustomizationArgs = {
  input?: Maybe<UiCustomizationInput>;
};


export type MutationUpsertUserProfileArgs = {
  input: UserProfileInput;
};


export type MutationVerifyGigReportArgs = {
  expensePolicy?: Maybe<VerifyExpensePolicy>;
  filters: Scalars['JSON'];
  message?: Maybe<Scalars['String']>;
  overriddenDuration?: Maybe<Scalars['Float']>;
  overriddenTotalExpenses?: Maybe<Scalars['Float']>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationVoidInvoiceArgs = {
  filters?: Maybe<Scalars['JSON']>;
  notes?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


export type MutationWithdrawApplicationArgs = {
  applicationId: Scalars['ID'];
};

export type NumberQuestion = Question & {
  __typename: 'NumberQuestion';
  answerType: AnswerType;
  eachProduct: Scalars['Boolean'];
  footer?: Maybe<Scalars['String']>;
  gogetterTitle?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inputPlaceholder?: Maybe<Scalars['String']>;
  notApplicableDesc?: Maybe<Scalars['String']>;
  optional: Scalars['Boolean'];
  shortTitle?: Maybe<Scalars['String']>;
};

export type Option = {
  __typename: 'Option';
  active?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  group: Scalars['String'];
  key: Scalars['String'];
  label: Scalars['String'];
};

export type OptionConfig = {
  __typename: 'OptionConfig';
  group: Scalars['String'];
  key: Scalars['String'];
  label: Scalars['String'];
  meta?: Maybe<Scalars['JSON']>;
  order: Scalars['Int'];
};

export type OptionInput = {
  description?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type Ordering = {
  direction?: Direction;
  sort: Scalars['String'];
};

export type OrderingAgentOfThePayee = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingAgentOfThePayeeEnum>;
};

export enum OrderingAgentOfThePayeeEnum {
  Answer = 'answer'
}

export type OrderingBillingContact = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingBillingContactEnum>;
};

export enum OrderingBillingContactEnum {
  Nickname = 'nickname'
}

export type OrderingBrand = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingBrandEnum>;
};

export enum OrderingBrandEnum {
  Name = 'name'
}

export type OrderingExpense = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingExpenseEnum>;
};

export enum OrderingExpenseEnum {
  Name = 'name'
}

export type OrderingGig = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingGigEnum>;
};

export type OrderingGigAssignableUser = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingGigAssignableUserEnum>;
};

export enum OrderingGigAssignableUserEnum {
  City = 'city',
  Distance = 'distance',
  Firstname = 'firstname',
  Lastname = 'lastname',
  Name = 'name',
  Status = 'status'
}

export enum OrderingGigEnum {
  Combinedtime = 'combinedtime',
  Customlocationname = 'customlocationname',
  Id = 'id',
  Startdate = 'startdate',
  Starttime = 'starttime',
  Updatedat = 'updatedat',
  Windowenddate = 'windowenddate'
}

export type OrderingGigExpense = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingGigExpenseEnum>;
};

export enum OrderingGigExpenseEnum {
  Name = 'name'
}

export type OrderingInvitationCode = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingInvitationCodeEnum>;
};

export enum OrderingInvitationCodeEnum {
  CreatedAt = 'createdAt'
}

export type OrderingInvoiceExpense = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingInvoiceExpenseEnum>;
};

export enum OrderingInvoiceExpenseEnum {
  Name = 'name'
}

export type OrderingInvoiceGig = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingInvoiceGigEnum>;
};

export enum OrderingInvoiceGigEnum {
  Gigid = 'gigid',
  Id = 'id',
  Name = 'name',
  Startdate = 'startdate',
  Starttime = 'starttime'
}

export type OrderingInvoiceVersion = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingInvoiceVersionEnum>;
};

export enum OrderingInvoiceVersionEnum {
  Id = 'id',
  Name = 'name',
  Number = 'number',
  Updatedatdate = 'updatedatdate'
}

export type OrderingOption = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingOptionEnum>;
};

export enum OrderingOptionEnum {
  Group = 'group',
  Key = 'key',
  Order = 'order'
}

export type OrderingOrganization = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingOrganizationEnum>;
};

export enum OrderingOrganizationEnum {
  Name = 'name'
}

export type OrderingOrganizationLocation = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingOrganizationLocationEnum>;
};

export enum OrderingOrganizationLocationEnum {
  Createdat = 'createdat'
}

export type OrderingProduct = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingProductEnum>;
};

export enum OrderingProductEnum {
  Name = 'name'
}

export type OrderingProgram = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingProgramEnum>;
};

export enum OrderingProgramEnum {
  Atcapacity = 'atcapacity',
  Name = 'name'
}

export type OrderingProgramGroup = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingProgramGroupEnum>;
};

export enum OrderingProgramGroupEnum {
  Name = 'name'
}

export type OrderingProgramPartnership = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingProgramPartnershipEnum>;
};

export enum OrderingProgramPartnershipEnum {
  Name = 'name'
}

export type OrderingProgramRequest = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingProgramRequestEnum>;
};

export enum OrderingProgramRequestEnum {
  Name = 'name'
}

export type OrderingProgramRole = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingProgramRoleEnum>;
};

export enum OrderingProgramRoleEnum {
  Title = 'title'
}

export type OrderingReportForm = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingReportFormEnum>;
};

export enum OrderingReportFormEnum {
  Name = 'name',
  Searchorder = 'searchorder'
}

export type OrderingReportFormLibrary = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingReportFormLibraryEnum>;
};

export enum OrderingReportFormLibraryEnum {
  Name = 'name',
  Searchorder = 'searchorder'
}

export type OrderingRole = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingRoleEnum>;
};

export enum OrderingRoleEnum {
  Title = 'title'
}

export type OrderingSchedule = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingScheduleEnum>;
};

export enum OrderingScheduleEnum {
  Name = 'name'
}

export type OrderingState = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingStateEnum>;
};

export enum OrderingStateEnum {
  Key = 'key'
}

export type OrderingTag = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingTagEnum>;
};

export enum OrderingTagEnum {
  Description = 'description',
  Name = 'name'
}

export type OrderingUser = {
  args?: Maybe<Scalars['JSON']>;
  direction?: Maybe<Direction>;
  sort?: Maybe<OrderingUserEnum>;
};

export enum OrderingUserEnum {
  FirstName = 'firstName',
  LastName = 'lastName'
}

export type Organization = {
  __typename: 'Organization';
  acceptedAgentOfThePayee: Scalars['Boolean'];
  accountMandateApp: Scalars['Boolean'];
  /** Flags --- */
  active: Scalars['Boolean'];
  adminLabel?: Maybe<Scalars['String']>;
  availabilityCalendarActive?: Maybe<Scalars['Boolean']>;
  backgroundUrl?: Maybe<Scalars['String']>;
  billingContacts?: Maybe<Array<Maybe<BillingContact>>>;
  brandingActive: Scalars['Boolean'];
  bulkUploaderActive: Scalars['Boolean'];
  creators?: Maybe<Array<Maybe<User>>>;
  customerType?: Maybe<Scalars['String']>;
  dataExportEnabled: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  enableNewGigRequestSelectPartnerUi: Scalars['Boolean'];
  enabledFeatures: Array<Scalars['String']>;
  expenseTrackingApp: Scalars['Boolean'];
  finalApprovalActive: Scalars['Boolean'];
  financialPackageActive?: Maybe<Scalars['Boolean']>;
  galloTheapDashboards: Scalars['Boolean'];
  gigApplyActive: Scalars['Boolean'];
  gigConfirmationActive: Scalars['Boolean'];
  gigRequestsActive: Scalars['Boolean'];
  gigSelfAssignmentActive: Scalars['Boolean'];
  hasAcceptedTerms: Scalars['Boolean'];
  hasInvitedUsers: Scalars['Boolean'];
  id: Scalars['ID'];
  industry?: Maybe<Industry>;
  invoicingActive: Scalars['Boolean'];
  isDemo?: Maybe<Scalars['Boolean']>;
  isWorkspace: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  newNav: Scalars['Boolean'];
  oneOffLocations: Scalars['Boolean'];
  parentOrganization?: Maybe<Organization>;
  partnershipsVisible?: Maybe<Scalars['Boolean']>;
  pcards: Scalars['Boolean'];
  primaryBillingContact?: Maybe<BillingContact>;
  primaryColor?: Maybe<Scalars['String']>;
  products: Array<Product>;
  programCapacityActive: Scalars['Boolean'];
  programCreationActive?: Maybe<Scalars['Boolean']>;
  programGroupsVisible?: Maybe<Scalars['Boolean']>;
  programs: Array<Program>;
  redirectToTheApp: Scalars['Boolean'];
  roles: Array<Role>;
  roster?: Maybe<Array<Maybe<User>>>;
  samplingEventsApp: Scalars['Boolean'];
  slidesActive?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  subscriptionStatus?: Maybe<Scalars['String']>;
  ticketing: Scalars['Boolean'];
  tradeAdvocacyApp: Scalars['Boolean'];
  treasurerActive: Scalars['Boolean'];
  type: OrganizationType;
  uiCustomization?: Maybe<UiCustomization>;
  verifiedReportEditing?: Maybe<Scalars['Boolean']>;
  website?: Maybe<Scalars['String']>;
  workspacesActive?: Maybe<Scalars['Boolean']>;
};


export type OrganizationCreatorsArgs = {
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type OrganizationProgramsArgs = {
  includeArchived?: Maybe<Scalars['Boolean']>;
  onlyOwnedByMyOrg?: Maybe<Scalars['Boolean']>;
};


export type OrganizationRolesArgs = {
  active?: Maybe<Scalars['Boolean']>;
  pseudo?: Maybe<Scalars['Boolean']>;
};

export type OrganizationInput = {
  description?: Maybe<Scalars['String']>;
  hasAcceptedTerms: Scalars['Boolean'];
  industryId?: Maybe<Scalars['ID']>;
  industryKey?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  teamSizeKey?: Maybe<Scalars['String']>;
  uiCustomizationId?: Maybe<Scalars['ID']>;
  website?: Maybe<Scalars['String']>;
};

export type OrganizationLocation = {
  __typename: 'OrganizationLocation';
  contacts?: Maybe<Array<Maybe<OrganizationLocationContact>>>;
  displayAddress: Scalars['String'];
  distanceFromSearchCenterInMeters?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  internalIdentifier?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  otherNotes?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  products: Array<Product>;
  schedulingNotes?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export type OrganizationLocationContact = {
  __typename: 'OrganizationLocationContact';
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type OrganizationLocationContactInput = {
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type OrganizationLocationsEdges = {
  __typename: 'OrganizationLocationsEdges';
  cursor?: Maybe<Scalars['ID']>;
  node?: Maybe<OrganizationLocation>;
};

export type OrganizationLocationsResult = {
  __typename: 'OrganizationLocationsResult';
  edges?: Maybe<Array<Maybe<OrganizationLocationsEdges>>>;
  pageInfo?: Maybe<ConnectionPageInfo>;
};

export type OrganizationPartnerships = {
  __typename: 'OrganizationPartnerships';
  inviteeOrganizations: Array<Organization>;
  inviterOrganization: Organization;
};

export type OrganizationProgram = {
  __typename: 'OrganizationProgram';
  accessLevel: AccessLevel;
  isOwner?: Maybe<Scalars['Boolean']>;
  organization: Organization;
  program: Program;
  roleRates: Array<ProgramRolePartnerRate>;
};

export enum OrganizationType {
  Agency = 'agency',
  Client = 'client'
}

export type OrganizationUser = {
  __typename: 'OrganizationUser';
  bulkUploaderActive?: Maybe<Scalars['Boolean']>;
  canRequest: Scalars['Boolean'];
  canSelfAssign: Scalars['Boolean'];
  defaultRate?: Maybe<Scalars['Float']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  employeeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  legalName?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['ID']>;
  organizationInvoicingActive?: Maybe<Scalars['Boolean']>;
  organizationNotes?: Maybe<Scalars['String']>;
  organizationType?: Maybe<OrganizationType>;
  organizationUserId: Scalars['ID'];
  redirectToTheApp?: Maybe<Scalars['Boolean']>;
  salaried?: Maybe<Scalars['Boolean']>;
  slidesActive?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  treasurerActive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<OrganizationUserType>;
};

export type OrganizationUserInput = {
  defaultRate?: Maybe<Scalars['Float']>;
  documents?: Maybe<Array<Maybe<UpsertDocumentInput>>>;
  employeeId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  legalName?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organizationNotes?: Maybe<Scalars['String']>;
  salaried?: Maybe<Scalars['Boolean']>;
  tagIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export enum OrganizationUserType {
  Admin = 'admin',
  Gogetter = 'gogetter',
  LimitedUser = 'limited_user',
  Manager = 'manager',
  Member = 'member',
  Owner = 'owner',
  SuperAdmin = 'super_admin'
}

export type PaginatedAgentOfThePayee = {
  __typename: 'PaginatedAgentOfThePayee';
  edges: Array<PaginatedAgentOfThePayeeEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedAgentOfThePayeeEdge = {
  __typename: 'PaginatedAgentOfThePayeeEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: AgentOfThePayee;
};

export type PaginatedApplication = {
  __typename: 'PaginatedApplication';
  edges: Array<PaginatedApplicationEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedApplicationEdge = {
  __typename: 'PaginatedApplicationEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: Application;
};

export type PaginatedBillingContact = {
  __typename: 'PaginatedBillingContact';
  edges: Array<PaginatedBillingContactEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedBillingContactEdge = {
  __typename: 'PaginatedBillingContactEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: BillingContact;
};

export type PaginatedBrand = {
  __typename: 'PaginatedBrand';
  edges: Array<PaginatedBrandEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedBrandEdge = {
  __typename: 'PaginatedBrandEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: Brand;
};

export type PaginatedExpense = {
  __typename: 'PaginatedExpense';
  edges: Array<PaginatedExpenseEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedExpenseEdge = {
  __typename: 'PaginatedExpenseEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: Expense;
};

export type PaginatedGig = {
  __typename: 'PaginatedGig';
  edges: Array<PaginatedGigEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedGigAssignableUser = {
  __typename: 'PaginatedGigAssignableUser';
  edges: Array<PaginatedGigAssignableUserEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedGigAssignableUserEdge = {
  __typename: 'PaginatedGigAssignableUserEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: GigAssignableUser;
};

export type PaginatedGigEdge = {
  __typename: 'PaginatedGigEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: Gig;
};

export type PaginatedGigExpense = {
  __typename: 'PaginatedGigExpense';
  edges: Array<PaginatedGigExpenseEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedGigExpenseEdge = {
  __typename: 'PaginatedGigExpenseEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: GigExpense;
};

export type PaginatedInvitationCode = {
  __typename: 'PaginatedInvitationCode';
  edges: Array<PaginatedInvitationCodeEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedInvitationCodeEdge = {
  __typename: 'PaginatedInvitationCodeEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: InvitationCode;
};

export type PaginatedInvoiceExpense = {
  __typename: 'PaginatedInvoiceExpense';
  edges: Array<PaginatedInvoiceExpenseEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedInvoiceExpenseEdge = {
  __typename: 'PaginatedInvoiceExpenseEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: InvoiceExpense;
};

export type PaginatedInvoiceGig = {
  __typename: 'PaginatedInvoiceGig';
  edges: Array<PaginatedInvoiceGigEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedInvoiceGigEdge = {
  __typename: 'PaginatedInvoiceGigEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: InvoiceGig;
};

export type PaginatedInvoiceVersion = {
  __typename: 'PaginatedInvoiceVersion';
  edges: Array<PaginatedInvoiceVersionEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedInvoiceVersionEdge = {
  __typename: 'PaginatedInvoiceVersionEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: InvoiceVersion;
};

export type PaginatedOption = {
  __typename: 'PaginatedOption';
  edges: Array<PaginatedOptionEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedOptionEdge = {
  __typename: 'PaginatedOptionEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: Option;
};

export type PaginatedOrganization = {
  __typename: 'PaginatedOrganization';
  edges: Array<PaginatedOrganizationEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedOrganizationEdge = {
  __typename: 'PaginatedOrganizationEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: Organization;
};

export type PaginatedOrganizationLocation = {
  __typename: 'PaginatedOrganizationLocation';
  edges: Array<PaginatedOrganizationLocationEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedOrganizationLocationEdge = {
  __typename: 'PaginatedOrganizationLocationEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: OrganizationLocation;
};

export type PaginatedProduct = {
  __typename: 'PaginatedProduct';
  edges: Array<PaginatedProductEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedProductEdge = {
  __typename: 'PaginatedProductEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: Product;
};

export type PaginatedProgram = {
  __typename: 'PaginatedProgram';
  edges: Array<PaginatedProgramEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedProgramEdge = {
  __typename: 'PaginatedProgramEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: Program;
};

export type PaginatedProgramGroup = {
  __typename: 'PaginatedProgramGroup';
  edges: Array<PaginatedProgramGroupEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedProgramGroupEdge = {
  __typename: 'PaginatedProgramGroupEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: ProgramGroup;
};

export type PaginatedProgramPartnership = {
  __typename: 'PaginatedProgramPartnership';
  edges: Array<PaginatedProgramPartnershipEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedProgramPartnershipEdge = {
  __typename: 'PaginatedProgramPartnershipEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: ProgramPartnership;
};

export type PaginatedProgramRequest = {
  __typename: 'PaginatedProgramRequest';
  edges: Array<PaginatedProgramRequestEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedProgramRequestEdge = {
  __typename: 'PaginatedProgramRequestEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: ProgramRequest;
};

export type PaginatedProgramRole = {
  __typename: 'PaginatedProgramRole';
  edges: Array<PaginatedProgramRoleEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedProgramRoleEdge = {
  __typename: 'PaginatedProgramRoleEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: ProgramRole;
};

export type PaginatedReportForm = {
  __typename: 'PaginatedReportForm';
  edges: Array<PaginatedReportFormEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedReportFormEdge = {
  __typename: 'PaginatedReportFormEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: ReportForm;
};

export type PaginatedReportFormLibrary = {
  __typename: 'PaginatedReportFormLibrary';
  edges: Array<PaginatedReportFormLibraryEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedReportFormLibraryEdge = {
  __typename: 'PaginatedReportFormLibraryEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: ReportFormLibrary;
};

export type PaginatedRole = {
  __typename: 'PaginatedRole';
  edges: Array<PaginatedRoleEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedRoleEdge = {
  __typename: 'PaginatedRoleEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: Role;
};

export type PaginatedSchedule = {
  __typename: 'PaginatedSchedule';
  edges: Array<PaginatedScheduleEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedScheduleEdge = {
  __typename: 'PaginatedScheduleEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: Schedule;
};

export type PaginatedState = {
  __typename: 'PaginatedState';
  edges: Array<PaginatedStateEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedStateEdge = {
  __typename: 'PaginatedStateEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: State;
};

export type PaginatedTag = {
  __typename: 'PaginatedTag';
  edges: Array<PaginatedTagEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedTagEdge = {
  __typename: 'PaginatedTagEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: Tag;
};

export type PaginatedUser = {
  __typename: 'PaginatedUser';
  edges: Array<PaginatedUserEdge>;
  pageInfo: ConnectionPageInfo;
};

export type PaginatedUserEdge = {
  __typename: 'PaginatedUserEdge';
  cursor?: Maybe<Scalars['ID']>;
  node: User;
};

export type PartnerOrganizationInput = {
  accessLevel?: Maybe<AccessLevel>;
  organizationId: Scalars['ID'];
  partnerRates?: Maybe<Array<PartnerOrganizationRoleRateInput>>;
};

export type PartnerOrganizationRoleRateInput = {
  pinataMaestroRate?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  roleId: Scalars['ID'];
};

export type Pass = {
  __typename: 'Pass';
  color1: Scalars['String'];
  color2: Scalars['String'];
  color3: Scalars['String'];
};

export enum PhotoTemplate {
  BeforeAfter = 'beforeAfter',
  OnePerPage = 'onePerPage'
}


export type Product = {
  __typename: 'Product';
  active: Scalars['Boolean'];
  brandId?: Maybe<Scalars['ID']>;
  brandName?: Maybe<Scalars['String']>;
  category?: Maybe<OptionConfig>;
  description?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  element: Element;
  id: Scalars['ID'];
  meta?: Maybe<Scalars['JSON']>;
  msrp?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  pictures?: Maybe<Array<Maybe<Document>>>;
  profilePictures?: Maybe<Array<Maybe<Document>>>;
  programs?: Maybe<Array<Program>>;
  sku?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export enum ProductState {
  Active = 'active',
  Inactive = 'inactive'
}

export type Program = {
  __typename: 'Program';
  accountingCode?: Maybe<Scalars['String']>;
  adminLabel?: Maybe<Scalars['String']>;
  agency?: Maybe<Organization>;
  archived?: Maybe<Scalars['Boolean']>;
  atCapacity: Scalars['Boolean'];
  brandEducation?: Maybe<Scalars['String']>;
  capacityGigCount: Scalars['Int'];
  capacityLimit?: Maybe<Scalars['Int']>;
  client?: Maybe<Organization>;
  demoKit?: Maybe<ProgramDemoKit>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  executionType: ProgramExecutionType;
  expenses?: Maybe<Scalars['String']>;
  gigPartnerAssignment: GigPartnerAssignment;
  gigStats?: Maybe<GigStats>;
  gigs: Array<Gig>;
  gigsCount: Scalars['Int'];
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  isApprovalRequired: Scalars['Boolean'];
  isContact?: Maybe<Scalars['Boolean']>;
  isOwner: Scalars['Boolean'];
  keyMetric?: Maybe<Option>;
  locationDescription?: Maybe<Scalars['String']>;
  logistics?: Maybe<Scalars['String']>;
  managementState: ProgramManagementState;
  materialsCoordination?: Maybe<Scalars['String']>;
  minimumNotice?: Maybe<Option>;
  myOrgAccessLevel: AccessLevel;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['ID']>;
  ownerOrganization: Organization;
  products: Array<Product>;
  reimbursementNotes?: Maybe<Scalars['String']>;
  requirements?: Maybe<Scalars['String']>;
  roles: Array<ProgramRole>;
  sampleProductsSource?: Maybe<Option>;
  startDate?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  statsHash?: Maybe<Scalars['String']>;
  trainingDocuments: Array<Document>;
  trainingDocumentsCount: Scalars['Int'];
  trainingType?: Maybe<Array<Maybe<Option>>>;
};


export type ProgramGigsArgs = {
  after?: Maybe<Scalars['String']>;
  assignedUsers?: Maybe<Array<Maybe<Scalars['ID']>>>;
  before?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Array<Maybe<Scalars['ID']>>>;
  except?: Maybe<Array<Maybe<Scalars['ID']>>>;
  includeNoDate?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  locationName?: Maybe<Scalars['String']>;
  managementStates?: Maybe<Array<Scalars['String']>>;
  ready?: Maybe<Scalars['Boolean']>;
  reportStates?: Maybe<Array<GigReportState>>;
  searchRadius?: Maybe<SearchRadiusInput>;
  unfilled?: Maybe<Scalars['Boolean']>;
};


export type ProgramGigsCountArgs = {
  after?: Maybe<Scalars['String']>;
  assignedUsers?: Maybe<Array<Maybe<Scalars['ID']>>>;
  before?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Array<Maybe<Scalars['ID']>>>;
  includeNoDate?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  locationName?: Maybe<Scalars['String']>;
  managementStates?: Maybe<Array<Scalars['String']>>;
  ready?: Maybe<Scalars['Boolean']>;
  reportStates?: Maybe<Array<GigReportState>>;
  searchRadius?: Maybe<SearchRadiusInput>;
  unfilled?: Maybe<Scalars['Boolean']>;
};


export type ProgramIsContactArgs = {
  id: Scalars['ID'];
};

export type ProgramBudgetCap = {
  __typename: 'ProgramBudgetCap';
  capType?: Maybe<ProgramBudgetCapType>;
  value?: Maybe<Scalars['Int']>;
};

export type ProgramBudgetCapInput = {
  capType?: Maybe<ProgramBudgetCapType>;
  value?: Maybe<Scalars['Int']>;
};

export enum ProgramBudgetCapType {
  Cost = 'cost',
  Gigs = 'gigs'
}

export type ProgramDemoKit = {
  __typename: 'ProgramDemoKit';
  agency?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  shippingRequirements?: Maybe<Option>;
  venue?: Maybe<Scalars['String']>;
};

export type ProgramDemoKitInput = {
  agency?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  shippingRequirements?: Maybe<OptionInput>;
  venue?: Maybe<Scalars['String']>;
};

export enum ProgramExecutionType {
  Maestro = 'maestro',
  Pro = 'pro',
  Solo = 'solo'
}

export type ProgramGroup = {
  __typename: 'ProgramGroup';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  programIds?: Maybe<Array<Scalars['ID']>>;
  programNames?: Maybe<Array<Scalars['String']>>;
};

export type ProgramGroupInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  programIds?: Maybe<Array<Scalars['ID']>>;
};

export type ProgramHighLevelStats = {
  __typename: 'ProgramHighLevelStats';
  gigs: Scalars['Int'];
  id: Scalars['ID'];
  interactions?: Maybe<Scalars['Int']>;
  samples?: Maybe<Scalars['Int']>;
  unitsSold?: Maybe<Scalars['Int']>;
};

export type ProgramInternalFile = {
  __typename: 'ProgramInternalFile';
  caption: Scalars['String'];
  url: Scalars['String'];
};

export type ProgramInternalFileInput = {
  caption: Scalars['String'];
  programGroupId?: Maybe<Scalars['ID']>;
  url: Scalars['String'];
};

export type ProgramKeyMetric = {
  __typename: 'ProgramKeyMetric';
  description?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  targetValue?: Maybe<Scalars['Int']>;
  unit?: Maybe<ProgramKeyMetricUnit>;
};

export type ProgramKeyMetricInput = {
  description?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  targetValue?: Maybe<Scalars['Int']>;
  unit?: Maybe<ProgramKeyMetricUnit>;
};

export enum ProgramKeyMetricUnit {
  Gig = 'gig',
  Hour = 'hour'
}

export enum ProgramManagementState {
  Accepted = 'accepted',
  Draft = 'draft',
  HandedOff = 'handed_off',
  Sow = 'sow'
}

export type ProgramManager = {
  __typename: 'ProgramManager';
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
};

export type ProgramPartner = {
  __typename: 'ProgramPartner';
  id: Scalars['ID'];
  managers?: Maybe<Array<ProgramManager>>;
  name: Scalars['String'];
  type: ProgramPartnerType;
};

export enum ProgramPartnerType {
  Agency = 'agency',
  Client = 'client'
}

export type ProgramPartnership = {
  __typename: 'ProgramPartnership';
  lastModified: Scalars['Posix'];
  organization: Organization;
  partnerOrganization: Organization;
  partnerPrograms: Array<OrganizationProgram>;
};

export type ProgramRequest = {
  __typename: 'ProgramRequest';
  accountingCode?: Maybe<Scalars['String']>;
  additionalContactEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
  archived: Scalars['Boolean'];
  atCapacity: Scalars['Boolean'];
  autoActivation?: Maybe<Scalars['Boolean']>;
  billingContact?: Maybe<BillingContact>;
  budget?: Maybe<Scalars['Float']>;
  budgetCap?: Maybe<ProgramBudgetCap>;
  capacityGigCount: Scalars['Int'];
  capacityLimit?: Maybe<Scalars['Int']>;
  clientOrganizations: Array<OrganizationProgram>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  demoKit?: Maybe<ProgramDemoKit>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  estimatedGigsCount?: Maybe<Scalars['Int']>;
  executionType: ProgramExecutionType;
  expenses?: Maybe<Scalars['String']>;
  gigBudget?: Maybe<Scalars['Int']>;
  gigPartnerAssignment: GigPartnerAssignment;
  handOffPolicy?: Maybe<Option>;
  highLevelStats: ProgramHighLevelStats;
  id: Scalars['ID'];
  internalFiles?: Maybe<Array<ProgramInternalFile>>;
  isApprovalRequired?: Maybe<Scalars['Boolean']>;
  isOwnedByCurrentOrganization: Scalars['Boolean'];
  keyMetric?: Maybe<ProgramKeyMetric>;
  locationDescription?: Maybe<Scalars['String']>;
  managementState?: Maybe<ProgramManagementState>;
  meta?: Maybe<Scalars['JSON']>;
  minimumNotice?: Maybe<Option>;
  myPartner?: Maybe<ProgramPartner>;
  name?: Maybe<Scalars['String']>;
  nameChangedAt?: Maybe<Scalars['String']>;
  ownerOrganization: Organization;
  partnerOrganizations: Array<OrganizationProgram>;
  payer?: Maybe<Option>;
  preferredAgenciesDescription?: Maybe<Scalars['String']>;
  preliminaryScheduleDocuments?: Maybe<Array<Maybe<Document>>>;
  products?: Maybe<Array<Maybe<Product>>>;
  programGroups: Array<ProgramGroup>;
  roles?: Maybe<Array<Maybe<ProgramRole>>>;
  sampleProductsSource?: Maybe<Option>;
  schedulingPolicy?: Maybe<Option>;
  setupReferenceImages?: Maybe<Array<Maybe<Document>>>;
  singleTalentOrganization: Scalars['Boolean'];
  startDate?: Maybe<Scalars['Date']>;
  stateKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  states?: Maybe<Array<Maybe<State>>>;
  team?: Maybe<Array<Maybe<ProgramTeamMember>>>;
  trainingDocuments?: Maybe<Array<Maybe<Document>>>;
  trainingMessage?: Maybe<Scalars['String']>;
  trainingType?: Maybe<Array<Maybe<Option>>>;
  type?: Maybe<ProgramType>;
  typeKey?: Maybe<Scalars['String']>;
};

export type ProgramRequestInput = {
  accountingCode?: Maybe<Scalars['String']>;
  additionalContactEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
  archived?: Maybe<Scalars['Boolean']>;
  autoActivation?: Maybe<Scalars['Boolean']>;
  billingContactId?: Maybe<Scalars['ID']>;
  budget?: Maybe<Scalars['Float']>;
  budgetCap?: Maybe<ProgramBudgetCapInput>;
  capacityLimit?: Maybe<Scalars['Int']>;
  demoKit?: Maybe<ProgramDemoKitInput>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  estimatedGigsCount?: Maybe<Scalars['Int']>;
  executionType?: Maybe<ProgramExecutionType>;
  expenses?: Maybe<Scalars['String']>;
  gigBudget?: Maybe<Scalars['Int']>;
  handOffPolicy?: Maybe<OptionInput>;
  id?: Maybe<Scalars['ID']>;
  internalFiles?: Maybe<Array<ProgramInternalFileInput>>;
  isApprovalRequired?: Maybe<Scalars['Boolean']>;
  keyMetric?: Maybe<ProgramKeyMetricInput>;
  locationDescription?: Maybe<Scalars['String']>;
  managementState?: Maybe<ProgramManagementState>;
  meta?: Maybe<Scalars['JSON']>;
  minimumNotice?: Maybe<OptionInput>;
  name?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  partnerOrganizations?: Maybe<Array<PartnerOrganizationInput>>;
  payer?: Maybe<OptionInput>;
  preferredAgenciesDescription?: Maybe<Scalars['String']>;
  preliminaryScheduleDocumentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  productIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  programGroupIds?: Maybe<Array<Scalars['ID']>>;
  roles?: Maybe<Array<Maybe<ProgramRoleInput>>>;
  sampleProductsSource?: Maybe<OptionInput>;
  schedulingPolicy?: Maybe<OptionInput>;
  setupReferenceImageIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  startDate?: Maybe<Scalars['Date']>;
  stateKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  team?: Maybe<Array<Maybe<ProgramTeamMemberInput>>>;
  trainingDocumentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  trainingMessage?: Maybe<Scalars['String']>;
  trainingType?: Maybe<Array<Maybe<OptionInput>>>;
  typeKey?: Maybe<Scalars['String']>;
};

export type ProgramRole = {
  __typename: 'ProgramRole';
  adminLabel?: Maybe<Scalars['String']>;
  allowExpenses: Scalars['Boolean'];
  color: Scalars['String'];
  duties?: Maybe<Scalars['String']>;
  gigs: Array<Gig>;
  hoursPerGig?: Maybe<Scalars['Float']>;
  iconName: Scalars['String'];
  id: Scalars['ID'];
  organization?: Maybe<Organization>;
  partnerRates?: Maybe<Array<ProgramRolePartnerRate>>;
  profile?: Maybe<Scalars['String']>;
  program?: Maybe<Program>;
  questions: Array<Question>;
  reportingNotes?: Maybe<Scalars['String']>;
  requestable: Scalars['Boolean'];
  role?: Maybe<Role>;
  roleId: Scalars['ID'];
  selfAssignable: Scalars['Boolean'];
  title: Scalars['String'];
  uniform?: Maybe<Option>;
  volume?: Maybe<Scalars['Int']>;
};

export type ProgramRoleInput = {
  adminLabel?: Maybe<Scalars['String']>;
  duties?: Maybe<Scalars['String']>;
  hoursPerGig?: Maybe<Scalars['Float']>;
  profile?: Maybe<Scalars['String']>;
  reportFormId?: Maybe<Scalars['ID']>;
  reportingNotes?: Maybe<Scalars['String']>;
  requestable?: Maybe<Scalars['Boolean']>;
  roleId?: Maybe<Scalars['ID']>;
  selfAssignable?: Maybe<Scalars['Boolean']>;
  uniform?: Maybe<OptionInput>;
  volume?: Maybe<Scalars['Int']>;
};

export type ProgramRolePartnerRate = {
  __typename: 'ProgramRolePartnerRate';
  clientOrganization: Organization;
  partnerOrganization: Organization;
  pinataMaestroRate?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  role: Role;
};

export type ProgramRolePartnerRateInput = {
  partnerOrganizationId: Scalars['ID'];
  pinataMaestroRate?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  roleId: Scalars['ID'];
};

export type ProgramTeamMember = {
  __typename: 'ProgramTeamMember';
  canRequest: Scalars['Boolean'];
  canSelfAssign: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  isContact?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  userType?: Maybe<Scalars['String']>;
};

export type ProgramTeamMemberInput = {
  firstName?: Maybe<Scalars['String']>;
  isContact?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  userType?: Maybe<Scalars['String']>;
};

export type ProgramType = {
  __typename: 'ProgramType';
  active?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  name: Scalars['String'];
};

export type Query = {
  __typename: 'Query';
  CancellationReasons: Array<CancellationReason>;
  activitiesInitialGroups: Array<InitialGroupIndex>;
  adminUser?: Maybe<User>;
  application?: Maybe<Application>;
  asset?: Maybe<Asset>;
  assignableUsersConnections: PaginatedUser;
  billingContacts?: Maybe<Array<Maybe<BillingContact>>>;
  billingContactsConnections: PaginatedBillingContact;
  brands: PaginatedBrand;
  calendar?: Maybe<Scalars['JSON']>;
  document: Document;
  documents?: Maybe<Array<Maybe<Document>>>;
  expense?: Maybe<Expense>;
  expenses: PaginatedExpense;
  findAGig: PaginatedGig;
  findMyGigs: PaginatedGig;
  getPrimaryBillingContact?: Maybe<BillingContact>;
  gig: Gig;
  gigActions: Array<GigActionCount>;
  gigAssignableUsers: PaginatedGigAssignableUser;
  gigAssignableUsersByProgramAndDate: PaginatedGigAssignableUser;
  gigExpenses: PaginatedGigExpense;
  gigIcs?: Maybe<GigIcsInfo>;
  gigReceiptsZipUrl: Array<Scalars['String']>;
  gigReportStep?: Maybe<GigReportStep>;
  gigReportSteps?: Maybe<Array<Maybe<GigReportStep>>>;
  gigStates: GigStateSections;
  gigs: PaginatedGig;
  impersonator?: Maybe<User>;
  invitationCode: InvitationCode;
  invitationCodeByToken: InvitationCode;
  invitationCodes: PaginatedInvitationCode;
  invoiceExpense?: Maybe<InvoiceExpense>;
  invoiceExpenses: PaginatedInvoiceExpense;
  invoiceGigs: PaginatedInvoiceGig;
  invoiceVersion?: Maybe<InvoiceVersion>;
  invoiceVersionStats: InvoiceVersionStats;
  invoices: PaginatedInvoiceVersion;
  libraryReportForm?: Maybe<ReportFormLibrary>;
  libraryReportForms: PaginatedReportFormLibrary;
  managerConnectionsByPrograms: PaginatedUser;
  me?: Maybe<User>;
  myApplications: PaginatedApplication;
  myGigsStatusCounts?: Maybe<Array<TaskStateGroupCount>>;
  options?: Maybe<Array<Maybe<OptionConfig>>>;
  optionsConnection: PaginatedOption;
  orgProducts: PaginatedProduct;
  organization: Organization;
  organizationConnectionsByPrograms: PaginatedOrganization;
  organizationConnectionsPartners: PaginatedOrganization;
  organizationLocation?: Maybe<OrganizationLocation>;
  organizationLocations: PaginatedOrganizationLocation;
  organizationUser?: Maybe<OrganizationUser>;
  product?: Maybe<Product>;
  productConnectionsByPrograms: PaginatedProduct;
  products?: Maybe<Array<Maybe<Product>>>;
  program: Program;
  programConnections: PaginatedProgram;
  programGroup?: Maybe<ProgramGroup>;
  programGroupConnections: PaginatedProgramGroup;
  programGroups?: Maybe<Array<Maybe<ProgramGroup>>>;
  programPartnerships: PaginatedProgramPartnership;
  programRequest?: Maybe<ProgramRequest>;
  programRole?: Maybe<ProgramRole>;
  programRoleConnections: PaginatedProgramRole;
  programTypes?: Maybe<Array<Maybe<State>>>;
  programs: Array<Maybe<Program>>;
  questionGroups?: Maybe<Array<Maybe<ReportGroup>>>;
  reportDashboard?: Maybe<ReportDashboard>;
  reportDashboards: Array<ReportDashboard>;
  reportForm?: Maybe<ReportForm>;
  reportForms: PaginatedReportForm;
  reportStep?: Maybe<ReportStep>;
  reportSteps?: Maybe<Array<ReportStep>>;
  role?: Maybe<Role>;
  roleConnections: PaginatedRole;
  roleConnectionsByPrograms: PaginatedRole;
  roleConnectionsByRequestUser: PaginatedRole;
  states: Array<State>;
  statesConnection: PaginatedState;
  tag?: Maybe<Tag>;
  tags: PaginatedTag;
  talentConnectionsByPrograms: PaginatedUser;
  talentRosterConnections: PaginatedUser;
  todaysLatestIncompleteCheckin?: Maybe<Gig>;
  uiCustomization?: Maybe<UiCustomization>;
  user?: Maybe<User>;
  userCalendarDatesByMonth: Array<UserCalendarDate>;
  userConnectionsByOrganization: PaginatedUser;
  userConnectionsByProgramPartners: PaginatedUser;
  userOrganizationByInvoice?: Maybe<Organization>;
  userOrganizationByPrograms?: Maybe<Organization>;
  users?: Maybe<Array<User>>;
};


export type QueryCancellationReasonsArgs = {
  constraint: CancellationReasonConstraint;
};


export type QueryActivitiesInitialGroupsArgs = {
  filters?: Maybe<Scalars['JSON']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryAdminUserArgs = {
  id: Scalars['ID'];
};


export type QueryApplicationArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryAssetArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryAssignableUsersConnectionsArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  gigId?: Maybe<Scalars['ID']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingUser>>>;
  search?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryBillingContactsArgs = {
  organizationId: Scalars['String'];
};


export type QueryBillingContactsConnectionsArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingProduct>>>;
  organizationId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryBrandsArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingBrand>>>;
  search?: Maybe<Scalars['String']>;
};


export type QueryCalendarArgs = {
  endDate: Scalars['String'];
  filters?: Maybe<Scalars['JSON']>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  startDate: Scalars['String'];
};


export type QueryDocumentArgs = {
  id: Scalars['ID'];
};


export type QueryDocumentsArgs = {
  documentObjectType?: Maybe<DocumentObjectType>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  organizationId: Scalars['String'];
};


export type QueryExpenseArgs = {
  id: Scalars['ID'];
};


export type QueryExpensesArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingExpense>>>;
  search?: Maybe<Scalars['String']>;
};


export type QueryFindAGigArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingGig>>>;
  search?: Maybe<Scalars['String']>;
  searchRadius?: Maybe<SearchRadiusInput>;
};


export type QueryFindMyGigsArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingGig>>>;
  search?: Maybe<Scalars['String']>;
  searchRadius?: Maybe<SearchRadiusInput>;
};


export type QueryGetPrimaryBillingContactArgs = {
  organizationId: Scalars['String'];
};


export type QueryGigArgs = {
  applyHash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};


export type QueryGigActionsArgs = {
  filters?: Maybe<Scalars['JSON']>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryGigAssignableUsersArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  gigIds: Array<Scalars['ID']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingGigAssignableUser>>>;
  search?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGigAssignableUsersByProgramAndDateArgs = {
  after?: Maybe<Scalars['ID']>;
  date: Scalars['Date'];
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingGigAssignableUser>>>;
  programIds: Array<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  status?: Maybe<GigAssignableUserStatus>;
  tagIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGigExpensesArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingGigExpense>>>;
  search?: Maybe<Scalars['String']>;
};


export type QueryGigIcsArgs = {
  gigId: Scalars['ID'];
};


export type QueryGigReceiptsZipUrlArgs = {
  filters?: Maybe<Scalars['JSON']>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryGigReportStepArgs = {
  gigId: Scalars['ID'];
  productId?: Maybe<Scalars['ID']>;
  stepKey: Scalars['ID'];
};


export type QueryGigReportStepsArgs = {
  gigId: Scalars['ID'];
};


export type QueryGigStatesArgs = {
  filters?: Maybe<Scalars['JSON']>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryGigsArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingGig>>>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  search?: Maybe<Scalars['String']>;
  searchRadius?: Maybe<SearchRadiusInput>;
};


export type QueryInvitationCodeArgs = {
  id: Scalars['ID'];
};


export type QueryInvitationCodeByTokenArgs = {
  token: Scalars['String'];
};


export type QueryInvitationCodesArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingInvitationCode>>>;
  search?: Maybe<Scalars['String']>;
};


export type QueryInvoiceExpenseArgs = {
  expenseId: Scalars['ID'];
};


export type QueryInvoiceExpensesArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  invoiceVersionId?: Maybe<Scalars['ID']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingInvoiceExpense>>>;
  search?: Maybe<Scalars['String']>;
};


export type QueryInvoiceGigsArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  invoiceVersionId?: Maybe<Scalars['ID']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingInvoiceGig>>>;
};


export type QueryInvoiceVersionArgs = {
  id: Scalars['ID'];
};


export type QueryInvoiceVersionStatsArgs = {
  id: Scalars['ID'];
};


export type QueryInvoicesArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingInvoiceVersion>>>;
  search?: Maybe<Scalars['String']>;
};


export type QueryLibraryReportFormArgs = {
  id: Scalars['ID'];
};


export type QueryLibraryReportFormsArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingReportFormLibrary>>>;
};


export type QueryManagerConnectionsByProgramsArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingUser>>>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  search?: Maybe<Scalars['String']>;
};


export type QueryMyApplicationsArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryOptionsArgs = {
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  includeInactive?: Maybe<Scalars['Boolean']>;
};


export type QueryOptionsConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  group: Scalars['String'];
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingOption>>>;
  search?: Maybe<Scalars['String']>;
};


export type QueryOrgProductsArgs = {
  after?: Maybe<Scalars['ID']>;
  brandIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingProduct>>>;
  programId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationConnectionsByProgramsArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingOrganization>>>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  search?: Maybe<Scalars['String']>;
  type?: Maybe<GigOrganizationType>;
};


export type QueryOrganizationConnectionsPartnersArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  onlyVisible?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<Array<Maybe<OrderingOrganization>>>;
  programId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryOrganizationLocationArgs = {
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
};


export type QueryOrganizationLocationsArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingOrganizationLocation>>>;
  search?: Maybe<Scalars['String']>;
  searchRadius?: Maybe<SearchRadiusInput>;
  tagIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryOrganizationUserArgs = {
  id?: Maybe<Scalars['ID']>;
  orgId?: Maybe<Scalars['ID']>;
};


export type QueryProductArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryProductConnectionsByProgramsArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingProduct>>>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  search?: Maybe<Scalars['String']>;
};


export type QueryProductsArgs = {
  gigId?: Maybe<Scalars['ID']>;
  limit?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['ID']>;
  programId?: Maybe<Scalars['ID']>;
  state?: Maybe<ProductState>;
};


export type QueryProgramArgs = {
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};


export type QueryProgramConnectionsArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingProgram>>>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  search?: Maybe<Scalars['String']>;
};


export type QueryProgramGroupArgs = {
  id: Scalars['ID'];
};


export type QueryProgramGroupConnectionsArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingProgramGroup>>>;
  search?: Maybe<Scalars['String']>;
};


export type QueryProgramGroupsArgs = {
  includeArchivedPrograms?: Maybe<Scalars['Boolean']>;
  programGroupIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryProgramPartnershipsArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingProgramPartnership>>>;
  search?: Maybe<Scalars['String']>;
};


export type QueryProgramRequestArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryProgramRoleArgs = {
  programId: Scalars['ID'];
  roleId: Scalars['ID'];
};


export type QueryProgramRoleConnectionsArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingProgramRole>>>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  search?: Maybe<Scalars['String']>;
};


export type QueryProgramTypesArgs = {
  includeInactive?: Maybe<Scalars['Boolean']>;
  keys?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryProgramsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  includeArchived?: Maybe<Scalars['Boolean']>;
  includeUnaccepted?: Maybe<Scalars['Boolean']>;
  onlyOwnedByMyOrg?: Maybe<Scalars['Boolean']>;
  programGroupIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryQuestionGroupsArgs = {
  gigId?: Maybe<Scalars['String']>;
  withAnswers?: Maybe<Scalars['Boolean']>;
};


export type QueryReportDashboardArgs = {
  app: ReportDashboardApp;
  dashboardKey?: Maybe<Scalars['ID']>;
  exclude?: Maybe<Scalars['Boolean']>;
  programIds?: Maybe<Array<Scalars['ID']>>;
  viewport?: Maybe<Scalars['JSON']>;
};


export type QueryReportDashboardsArgs = {
  app: ReportDashboardApp;
  collection?: Maybe<ReportDashboardCollection>;
  exclude?: Maybe<Scalars['Boolean']>;
  programIds?: Maybe<Array<Scalars['ID']>>;
  viewport?: Maybe<Scalars['JSON']>;
};


export type QueryReportFormArgs = {
  id: Scalars['ID'];
};


export type QueryReportFormsArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingReportForm>>>;
};


export type QueryReportStepArgs = {
  stepKey: Scalars['String'];
};


export type QueryRoleArgs = {
  id: Scalars['ID'];
};


export type QueryRoleConnectionsArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingRole>>>;
  search?: Maybe<Scalars['String']>;
};


export type QueryRoleConnectionsByProgramsArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingRole>>>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  search?: Maybe<Scalars['String']>;
};


export type QueryRoleConnectionsByRequestUserArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  onlyRequestable?: Maybe<Scalars['Boolean']>;
  onlySelfAssignable?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<Array<Maybe<OrderingRole>>>;
  search?: Maybe<Scalars['String']>;
};


export type QueryStatesArgs = {
  includeInactive?: Maybe<Scalars['Boolean']>;
  keys?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryStatesConnectionArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryTagArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryTagsArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  group: TagGroup;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingTag>>>;
  search?: Maybe<Scalars['String']>;
};


export type QueryTalentConnectionsByProgramsArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingUser>>>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  search?: Maybe<Scalars['String']>;
};


export type QueryTalentRosterConnectionsArgs = {
  after?: Maybe<Scalars['ID']>;
  area?: Maybe<AreaInput>;
  areaGigs?: Maybe<AreaGigsInput>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingUser>>>;
  search?: Maybe<Scalars['String']>;
  talentSelection?: Maybe<SelectionInput>;
  talentTagSelection?: Maybe<SelectionInput>;
};


export type QueryUiCustomizationArgs = {
  id: Scalars['ID'];
};


export type QueryUserArgs = {
  gigId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
};


export type QueryUserCalendarDatesByMonthArgs = {
  includeReccuring?: Maybe<Scalars['Boolean']>;
  month: Scalars['Int'];
  userId: Scalars['ID'];
  year: Scalars['Int'];
};


export type QueryUserConnectionsByOrganizationArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingUser>>>;
  organizationId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryUserConnectionsByProgramPartnersArgs = {
  after?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<OrderingUser>>>;
  programId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryUserOrganizationByInvoiceArgs = {
  invoiceVersionId: Scalars['ID'];
  managerOrganizationId: Scalars['ID'];
};


export type QueryUserOrganizationByProgramsArgs = {
  managerOrganizationId: Scalars['ID'];
  programIds?: Maybe<Array<Scalars['ID']>>;
};


export type QueryUsersArgs = {
  includeGhosts?: Maybe<Scalars['Boolean']>;
  includeTalent?: Maybe<Scalars['Boolean']>;
  managementStates?: Maybe<Array<Maybe<UserManagementState>>>;
  organizationId: Scalars['ID'];
};

export type Question = {
  answerType: AnswerType;
  eachProduct: Scalars['Boolean'];
  footer?: Maybe<Scalars['String']>;
  gogetterTitle?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  notApplicableDesc?: Maybe<Scalars['String']>;
  optional: Scalars['Boolean'];
  shortTitle?: Maybe<Scalars['String']>;
};

export type Receipt = {
  __typename: 'Receipt';
  caption?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type RegisterWithInvitationCodeInput = {
  firstName: Scalars['String'];
  invitationCodeToken: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  phoneVerificationCode: Scalars['String'];
};

export type RegistrationInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  continueUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type RegistrationPayload = {
  __typename: 'RegistrationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  jwt?: Maybe<Scalars['String']>;
  me: User;
};

export type RemoveUserInput = {
  organizationId: Scalars['String'];
  userIds: Array<Maybe<Scalars['ID']>>;
};

export type ReportDashboard = {
  __typename: 'ReportDashboard';
  app: Scalars['String'];
  collection: ReportDashboardCollection;
  description?: Maybe<Scalars['String']>;
  downloadPdf: Scalars['Boolean'];
  imageQuestions: Array<Maybe<ReportDashboardImageQuestion>>;
  key: Scalars['String'];
  pdfUrl: Scalars['String'];
  photoTemplate?: Maybe<PhotoTemplate>;
  title: Scalars['String'];
  url: Scalars['String'];
  viewport: Scalars['JSON'];
};

export enum ReportDashboardApp {
  Dashboard = 'dashboard',
  Go = 'go'
}

export enum ReportDashboardCollection {
  Financials = 'financials',
  Reports = 'reports'
}

export type ReportDashboardImage = {
  __typename: 'ReportDashboardImage';
  gigEndTime?: Maybe<Scalars['String']>;
  gigId: Scalars['ID'];
  gigStartTime?: Maybe<Scalars['String']>;
  gigTimeZone?: Maybe<Scalars['String']>;
  gogetterId?: Maybe<Scalars['ID']>;
  gogetterName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  locationAddress?: Maybe<Scalars['String']>;
  locationExternalId?: Maybe<Scalars['String']>;
  locationInternalIdentifier?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  urls: Array<Maybe<Scalars['String']>>;
};

export type ReportDashboardImageQuestion = {
  __typename: 'ReportDashboardImageQuestion';
  id: Scalars['ID'];
  images?: Maybe<Array<ReportDashboardImage>>;
  title: Scalars['String'];
};

export type ReportForm = {
  __typename: 'ReportForm';
  id: Scalars['ID'];
  name: Scalars['String'];
  report: Array<ReportGroup>;
  searchOrder?: Maybe<Scalars['Int']>;
};

export type ReportFormLibrary = {
  __typename: 'ReportFormLibrary';
  id: Scalars['ID'];
  name: Scalars['String'];
  report: Array<ReportGroup>;
  searchOrder?: Maybe<Scalars['Int']>;
};

export type ReportGroup = {
  __typename: 'ReportGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  productId?: Maybe<Scalars['ID']>;
  productName?: Maybe<Scalars['String']>;
  subgroups: Array<ReportSubgroup>;
};

export type ReportGroupQuestion = Question & {
  __typename: 'ReportGroupQuestion';
  answerJson?: Maybe<Scalars['String']>;
  answerState?: Maybe<Scalars['String']>;
  answerType: AnswerType;
  eachProduct: Scalars['Boolean'];
  footer?: Maybe<Scalars['String']>;
  gogetterTitle?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inputPlaceholder?: Maybe<Scalars['String']>;
  notApplicableDesc?: Maybe<Scalars['String']>;
  optional: Scalars['Boolean'];
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortTitle?: Maybe<Scalars['String']>;
};

export type ReportQuestionRolesInput = {
  id: Scalars['ID'];
  roleIds?: Maybe<Array<Scalars['ID']>>;
};

export type ReportStep = {
  __typename: 'ReportStep';
  key: Scalars['String'];
  managementDesc: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
  questions: Array<FullQuestion>;
  shortDesc: Scalars['String'];
};

export type ReportSubgroup = {
  __typename: 'ReportSubgroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  questions: Array<ReportGroupQuestion>;
  type: ReportSubgroupType;
};

export enum ReportSubgroupType {
  Sibling = 'sibling',
  Sidebar = 'sidebar',
  Simple = 'simple',
  Stats = 'stats'
}

export type ResendEmailConfirmationInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  continueUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ResendInviteInput = {
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  programIds?: Maybe<Array<Scalars['ID']>>;
  reportingProgramId?: Maybe<Scalars['ID']>;
};

export type ResendInvitePayloadItem = {
  __typename: 'ResendInvitePayloadItem';
  id?: Maybe<Scalars['ID']>;
  inviteResponseStatus: InviteResponseStatus;
};

export type ResetPasswordEmailInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  continueUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

export type RevokeEmailChangeInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export type RevokeEmailChangePayload = {
  __typename: 'RevokeEmailChangePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  passwordResetToken: Scalars['String'];
};

export type Role = {
  __typename: 'Role';
  allowExpenses: Scalars['Boolean'];
  dressCode?: Maybe<Option>;
  duties?: Maybe<Scalars['String']>;
  gigs: Array<Gig>;
  hasCheckin: Scalars['Boolean'];
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  partnerRates?: Maybe<Array<RolePartnerRate>>;
  profile?: Maybe<Scalars['String']>;
  questions: Array<Question>;
  reportForm?: Maybe<ReportForm>;
  requestable: Scalars['Boolean'];
  selfAssignable: Scalars['Boolean'];
  skipReportingSteps: SkipReportingSteps;
  title: Scalars['String'];
  uniform?: Maybe<Scalars['String']>;
  uniformKey?: Maybe<Scalars['String']>;
};

export type RoleInput = {
  allowExpenses?: Maybe<Scalars['Boolean']>;
  duties?: Maybe<Scalars['String']>;
  hasCheckin?: Maybe<Scalars['Boolean']>;
  identifier: Scalars['String'];
  profile?: Maybe<Scalars['String']>;
  reportForm: RoleReportFormInput;
  requestable?: Maybe<Scalars['Boolean']>;
  selfAssignable?: Maybe<Scalars['Boolean']>;
  skipReportingSteps?: Maybe<SkipReportingSteps>;
  title: Scalars['String'];
  uniform?: Maybe<Scalars['String']>;
  uniformKey?: Maybe<Scalars['String']>;
};

export type RolePartnerRate = {
  __typename: 'RolePartnerRate';
  clientOrganization: Organization;
  partnerOrganization: Organization;
  pinataMaestroRate?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

export enum RoleReportFormIdTypes {
  Library = 'library',
  Organization = 'organization'
}

export type RoleReportFormInput = {
  id: Scalars['ID'];
  organizationCustomNameForLibraryForm?: Maybe<Scalars['String']>;
  type: RoleReportFormIdTypes;
};

export type SaveGigReportStepInput = {
  gigId: Scalars['ID'];
  productId?: Maybe<Scalars['ID']>;
  state: Scalars['JSON'];
  stateChecksum: Scalars['String'];
  stepKey: Scalars['ID'];
};

export type Schedule = {
  __typename: 'Schedule';
  calendarWeekdays: Array<CalendarWeekday>;
  firstNotificationOffset: Scalars['Int'];
  timezone: Scalars['String'];
};

export type SearchRadiusInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  radius: Scalars['Float'];
};

export type Selection = {
  __typename: 'Selection';
  type: SelectionType;
  values: Array<Scalars['String']>;
};

export type SelectionInput = {
  type: SelectionType;
  values: Array<Scalars['String']>;
};

export enum SelectionType {
  Exclude = 'exclude',
  Include = 'include'
}

export type SendMagicLinkInput = {
  acceptedTOS?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  continueUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

export type SetProductActiveInput = {
  active: Scalars['Boolean'];
  ids: Array<Maybe<Scalars['ID']>>;
};

export type SignInWithInvitationCodeInput = {
  invitationCodeToken: Scalars['String'];
  phone: Scalars['String'];
  phoneVerificationCode: Scalars['String'];
};

export enum SkipReportingSteps {
  Location = 'location',
  LocationAndConfirmation = 'locationAndConfirmation',
  None = 'none'
}

export type State = {
  __typename: 'State';
  active?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  name: Scalars['String'];
};

export type Tag = {
  __typename: 'Tag';
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  groups: Array<TagGroup>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum TagGroup {
  Documents = 'documents',
  Locations = 'locations',
  Products = 'products',
  Programs = 'programs',
  Roles = 'roles',
  Users = 'users'
}

export type TagSelection = {
  __typename: 'TagSelection';
  type: SelectionType;
  values: Array<Tag>;
};

export enum TaskGroup {
  Finished = 'finished',
  Imported = 'imported',
  Inprogress = 'inprogress',
  Pending = 'pending',
  Ready = 'ready',
  Review = 'review',
  Trash = 'trash',
  Upcoming = 'upcoming'
}

export enum TaskSection {
  Active = 'active',
  Finished = 'finished',
  Other = 'other'
}

export enum TaskState {
  Cancelled = 'cancelled',
  ConfirmationRejected = 'confirmationRejected',
  ConfirmationRequested = 'confirmationRequested',
  Confirmed = 'confirmed',
  Eligible = 'eligible',
  Finished = 'finished',
  Imported = 'imported',
  Incomplete = 'incomplete',
  Late = 'late',
  Live = 'live',
  Obsolete = 'obsolete',
  Returned = 'returned',
  Submitted = 'submitted',
  Today = 'today',
  Trash = 'trash',
  Unapproved = 'unapproved',
  Unspecified = 'unspecified',
  Upcoming = 'upcoming'
}

export type TaskStateGroupCount = {
  __typename: 'TaskStateGroupCount';
  cnt: Scalars['Int'];
  taskStateGroup: TaskGroup;
};

export type TemperatureAnswer = Answer & {
  __typename: 'TemperatureAnswer';
  id: Scalars['ID'];
  kelvin?: Maybe<Scalars['Float']>;
  payload?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  questionId: Scalars['ID'];
  raw?: Maybe<Scalars['Float']>;
  reportId: Scalars['ID'];
  state: AnswerState;
  unit?: Maybe<TemperatureUnit>;
};

export enum TemperatureUnit {
  Celsius = 'celsius',
  Fahrenheit = 'fahrenheit',
  Kelvin = 'kelvin'
}

export type TextAnswer = Answer & {
  __typename: 'TextAnswer';
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  questionId: Scalars['ID'];
  reportId: Scalars['ID'];
  state: AnswerState;
  text?: Maybe<Scalars['String']>;
};

export type TextQuestion = Question & {
  __typename: 'TextQuestion';
  answerType: AnswerType;
  eachProduct: Scalars['Boolean'];
  footer?: Maybe<Scalars['String']>;
  gogetterTitle?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inputPlaceholder?: Maybe<Scalars['String']>;
  notApplicableDesc?: Maybe<Scalars['String']>;
  optional: Scalars['Boolean'];
  shortTitle?: Maybe<Scalars['String']>;
};

export type ToggleAnswer = Answer & {
  __typename: 'ToggleAnswer';
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  questionId: Scalars['ID'];
  reportId: Scalars['ID'];
  state: AnswerState;
  toggle?: Maybe<Scalars['Boolean']>;
};

export type UiCustomization = {
  __typename: 'UICustomization';
  backgroundImageUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  longDarkBgLogoUrl?: Maybe<Scalars['String']>;
  longLightBgLogoUrl?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  squareDarkBgLogoUrl?: Maybe<Scalars['String']>;
  squareLightBgLogoUrl?: Maybe<Scalars['String']>;
};

export type UiCustomizationInput = {
  backgroundImageUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  longDarkBgLogoUrl?: Maybe<Scalars['String']>;
  longLightBgLogoUrl?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  squareDarkBgLogoUrl?: Maybe<Scalars['String']>;
  squareLightBgLogoUrl?: Maybe<Scalars['String']>;
};

export type UpdateGigRosterInput = {
  message?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  sendNotification: Scalars['Boolean'];
  sendSms?: Maybe<Scalars['Boolean']>;
  talentLocation?: Maybe<CreateLocationInput>;
  talentLocationDistance?: Maybe<Scalars['Float']>;
  talentSelection?: Maybe<SelectionInput>;
  talentTagSelection?: Maybe<SelectionInput>;
  updateAccessFilter: Scalars['Boolean'];
  visibility: GigVisibility;
};

export type UpdateGigsInput = {
  agencyGuidance?: Maybe<Scalars['String']>;
  clientOrganizationId?: Maybe<Scalars['ID']>;
  documents?: Maybe<Array<UpsertDocumentInput>>;
  duration?: Maybe<Scalars['Int']>;
  expenseMemo?: Maybe<Scalars['String']>;
  expenseNotes?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
  locationName?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  myRate?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  overriddenDuration?: Maybe<Scalars['Float']>;
  partnerOrganizationId?: Maybe<Scalars['ID']>;
  productSelection?: Maybe<IdSelectionInput>;
  programId?: Maybe<Scalars['ID']>;
  roleId?: Maybe<Scalars['ID']>;
  startTime?: Maybe<Scalars['String']>;
  talentExpenses?: Maybe<Scalars['Boolean']>;
  talentId?: Maybe<Scalars['ID']>;
  talentRate?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  windowAssign?: Maybe<Scalars['Boolean']>;
  windowEndTime?: Maybe<Scalars['String']>;
  windowStartTime?: Maybe<Scalars['String']>;
};

export type UpdateInvoiceVersionInput = {
  dueDate?: Maybe<Scalars['String']>;
  dueDays?: Maybe<Scalars['Int']>;
  issuerContactId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  recipientContactId?: Maybe<Scalars['ID']>;
  recipientOrganizationId: Scalars['ID'];
  startDate?: Maybe<Scalars['String']>;
};

export type UpdateMyDetailsInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Maybe<UpsertDocumentInput>>>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type UpdateMyDetailsPayload = {
  __typename: 'UpdateMyDetailsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  me: User;
};

export type UpsertDocumentInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type UpsertOrganizationLocationError = {
  __typename: 'UpsertOrganizationLocationError';
  error: Scalars['String'];
  index: Scalars['Int'];
};

export type UpsertOrganizationLocationInput = {
  contacts?: Maybe<Array<Maybe<OrganizationLocationContactInput>>>;
  geocodingQuery?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  internalIdentifier?: Maybe<Scalars['String']>;
  location?: Maybe<CreateLocationInput>;
  name: Scalars['String'];
  otherNotes?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['ID']>;
  productIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  schedulingNotes?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type UpsertOrganizationLocationResult = {
  __typename: 'UpsertOrganizationLocationResult';
  errors: Array<UpsertOrganizationLocationError>;
};

export type UpsertOrganizationProductError = {
  __typename: 'UpsertOrganizationProductError';
  error: Scalars['String'];
  index: Scalars['Int'];
};

export type UpsertOrganizationProductInput = {
  active?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  msrp?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  sku?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type UpsertOrganizationProductResult = {
  __typename: 'UpsertOrganizationProductResult';
  errors: Array<UpsertOrganizationProductError>;
};

export type UpsertProductInput = {
  active?: Maybe<Scalars['Boolean']>;
  brandId?: Maybe<Scalars['ID']>;
  categoryKey?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  documentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  elementHashId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  meta?: Maybe<Scalars['JSON']>;
  msrp?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['ID']>;
  profilePictureIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sku?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type UpsertTagInput = {
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<TagGroup>>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['ID']>;
};

export type User = {
  __typename: 'User';
  active: Scalars['Boolean'];
  applications: Array<Application>;
  assets: Array<Asset>;
  checkInData?: Maybe<CheckInData>;
  confirmedAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayEmail?: Maybe<Scalars['String']>;
  distanceFromGig?: Maybe<Scalars['Float']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstOrganizationId?: Maybe<Scalars['ID']>;
  gigs?: Maybe<Array<Maybe<Gig>>>;
  id: Scalars['ID'];
  intercomHash: Scalars['String'];
  isAdmin: Scalars['Boolean'];
  isCorporate: Scalars['Boolean'];
  kind?: Maybe<UserKind>;
  language?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  managementState?: Maybe<UserManagementState>;
  organizationId?: Maybe<Scalars['ID']>;
  organizationName?: Maybe<Scalars['String']>;
  organizationUser?: Maybe<OrganizationUser>;
  organizations: Array<Maybe<Organization>>;
  phone?: Maybe<Scalars['String']>;
  profile?: Maybe<UserProfile>;
  programs: Array<Maybe<Program>>;
  reportingProgramId?: Maybe<Scalars['ID']>;
  selfAssignableOrganizations: Array<Organization>;
  tosAcceptedAt?: Maybe<Scalars['DateTime']>;
  unconfirmedEmail?: Maybe<Scalars['String']>;
  unconfirmedPhone?: Maybe<Scalars['String']>;
  userType?: Maybe<OrganizationUserType>;
};


export type UserDistanceFromGigArgs = {
  gigId?: Maybe<Scalars['ID']>;
};


export type UserFirstOrganizationIdArgs = {
  includeInactive?: Maybe<Scalars['Boolean']>;
};


export type UserOrganizationUserArgs = {
  organizationId?: Maybe<Scalars['ID']>;
};


export type UserProfileArgs = {
  gigId?: Maybe<Scalars['ID']>;
};


export type UserProgramsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  includeArchived?: Maybe<Scalars['Boolean']>;
  includeUnaccepted?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  programGroupIds?: Maybe<Array<Scalars['ID']>>;
};

export type UserAsset = {
  isPrimary?: Maybe<Scalars['Boolean']>;
  url: Scalars['String'];
};

export type UserCalendarDate = {
  __typename: 'UserCalendarDate';
  date: Scalars['String'];
  day: Scalars['Int'];
  month: Scalars['Int'];
  status: CalendarDateStatus;
  statusWithoutRecurring: CalendarDateStatus;
  user: User;
  year: Scalars['Int'];
};

export enum UserKind {
  Bot = 'bot',
  Ghost = 'ghost',
  Human = 'human'
}

export enum UserManagementState {
  Active = 'active',
  Disabled = 'disabled',
  Invited = 'invited'
}

export type UserProfile = {
  __typename: 'UserProfile';
  address?: Maybe<Scalars['String']>;
  apartment?: Maybe<Scalars['String']>;
  areas?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  calendarWeekdays: Array<CalendarWeekday>;
  canDrive?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Coordinates>;
  country?: Maybe<Scalars['String']>;
  flexibleTravel?: Maybe<Scalars['Boolean']>;
  hasCar?: Maybe<Scalars['Boolean']>;
  languages?: Maybe<Array<Maybe<Option>>>;
  selfSummary?: Maybe<Scalars['String']>;
  shipHome?: Maybe<Scalars['Boolean']>;
  shippingAddress?: Maybe<Scalars['String']>;
  shirtSize?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type UserProfileInput = {
  address?: Maybe<Scalars['String']>;
  addressJson?: Maybe<Scalars['JSON']>;
  apartment?: Maybe<Scalars['String']>;
  areas?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  canDrive?: Maybe<Scalars['Boolean']>;
  flexibleTravel?: Maybe<Scalars['Boolean']>;
  hasCar?: Maybe<Scalars['Boolean']>;
  languageKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  selfSummary?: Maybe<Scalars['String']>;
  shipHome?: Maybe<Scalars['Boolean']>;
  shippingAddress?: Maybe<Scalars['String']>;
  shippingAddressComponents?: Maybe<Scalars['JSON']>;
  shirtSize?: Maybe<Scalars['String']>;
};

export type UserSelection = {
  __typename: 'UserSelection';
  type: SelectionType;
  values: Array<User>;
};

export enum VerifyExpensePolicy {
  AlreadyCovered = 'alreadyCovered',
  GogetterReimbursement = 'gogetterReimbursement'
}

export type BrandFilterItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type BrandFilterItemsQuery = (
  { __typename: 'Query' }
  & { brands: (
    { __typename: 'PaginatedBrand' }
    & { edges: Array<(
      { __typename: 'PaginatedBrandEdge' }
      & { node: (
        { __typename: 'Brand' }
        & Pick<Brand, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type DeleteTalentGigExpenseMutationVariables = Exact<{
  gigId: Scalars['ID'];
  expenseId: Scalars['ID'];
}>;


export type DeleteTalentGigExpenseMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'deleteGigExpense'>
);

export type UpsertTalentGigExpenseMutationVariables = Exact<{
  gigId: Scalars['ID'];
  expenseId?: Maybe<Scalars['ID']>;
  reimburseAmount?: Maybe<Scalars['Float']>;
  chargeAmount?: Maybe<Scalars['Float']>;
  input?: Maybe<ExpenseInput>;
}>;


export type UpsertTalentGigExpenseMutation = (
  { __typename: 'Mutation' }
  & { upsertGigExpense?: Maybe<(
    { __typename: 'GigExpense' }
    & Pick<GigExpense, 'gigId' | 'reimburseAmount' | 'chargeAmount'>
    & { expense: (
      { __typename: 'Expense' }
      & Pick<Expense, 'id' | 'amount' | 'images' | 'memo'>
    ) }
  )> }
);

export type LocationFinderCardQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type LocationFinderCardQuery = (
  { __typename: 'Query' }
  & { organizationLocation?: Maybe<(
    { __typename: 'OrganizationLocation' }
    & Pick<OrganizationLocation, 'id' | 'name' | 'internalIdentifier'>
  )> }
);

export type LocationFinderQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type LocationFinderQuery = (
  { __typename: 'Query' }
  & { organizationLocations: (
    { __typename: 'PaginatedOrganizationLocation' }
    & { edges: Array<(
      { __typename: 'PaginatedOrganizationLocationEdge' }
      & { node: (
        { __typename: 'OrganizationLocation' }
        & Pick<OrganizationLocation, 'id' | 'name' | 'internalIdentifier' | 'schedulingNotes' | 'otherNotes' | 'displayAddress'>
        & { location?: Maybe<(
          { __typename: 'Location' }
          & Pick<Location, 'id' | 'name' | 'address' | 'addressJson' | 'externalId' | 'latitude' | 'longitude'>
        )>, owner?: Maybe<(
          { __typename: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName'>
        )>, tags?: Maybe<Array<Maybe<(
          { __typename: 'Tag' }
          & Pick<Tag, 'id' | 'name'>
        )>>>, contacts?: Maybe<Array<Maybe<(
          { __typename: 'OrganizationLocationContact' }
          & Pick<OrganizationLocationContact, 'name' | 'title' | 'email' | 'phone' | 'url'>
        )>>>, products: Array<(
          { __typename: 'Product' }
          & Pick<Product, 'id' | 'name' | 'sku'>
        )> }
      ) }
    )>, pageInfo: (
      { __typename: 'ConnectionPageInfo' }
      & Pick<ConnectionPageInfo, 'totalCount'>
    ) }
  ) }
);

export type OptionsDropdownItemsQueryVariables = Exact<{
  group?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type OptionsDropdownItemsQuery = (
  { __typename: 'Query' }
  & { options?: Maybe<Array<Maybe<(
    { __typename: 'OptionConfig' }
    & Pick<OptionConfig, 'key' | 'group' | 'label'>
  )>>> }
);

export type TagFilterItemsQueryVariables = Exact<{
  group: TagGroup;
}>;


export type TagFilterItemsQuery = (
  { __typename: 'Query' }
  & { tags: (
    { __typename: 'PaginatedTag' }
    & { edges: Array<(
      { __typename: 'PaginatedTagEdge' }
      & { node: (
        { __typename: 'Tag' }
        & Pick<Tag, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type TagsInputItemsQueryVariables = Exact<{
  group: TagGroup;
}>;


export type TagsInputItemsQuery = (
  { __typename: 'Query' }
  & { tags: (
    { __typename: 'PaginatedTag' }
    & { edges: Array<(
      { __typename: 'PaginatedTagEdge' }
      & { node: (
        { __typename: 'Tag' }
        & Pick<Tag, 'id' | 'name' | 'description' | 'color'>
      ) }
    )> }
  ) }
);

export type UpsertTagMutationVariables = Exact<{
  input: UpsertTagInput;
}>;


export type UpsertTagMutation = (
  { __typename: 'Mutation' }
  & { upsertTag?: Maybe<(
    { __typename: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'description' | 'color'>
  )> }
);

export type InviteDetailsQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type InviteDetailsQuery = (
  { __typename: 'Query' }
  & { me?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { organizationUser?: Maybe<(
      { __typename: 'OrganizationUser' }
      & Pick<OrganizationUser, 'id' | 'type' | 'organizationId' | 'organizationType'>
    )>, programs: Array<Maybe<(
      { __typename: 'Program' }
      & Pick<Program, 'id' | 'name' | 'executionType'>
    )>> }
  )>, organization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'gigRequestsActive' | 'gigSelfAssignmentActive'>
  ) }
);

export type InviteUsersProgramOptionsFragment = (
  { __typename: 'Program' }
  & Pick<Program, 'id' | 'name'>
);

export type ProgramGroupsInviteUsersQueryQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type ProgramGroupsInviteUsersQueryQuery = (
  { __typename: 'Query' }
  & { organization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'programGroupsVisible'>
  ), programGroups?: Maybe<Array<Maybe<(
    { __typename: 'ProgramGroup' }
    & Pick<ProgramGroup, 'id' | 'name' | 'programIds'>
  )>>> }
);

export type OrgBillingContactOptionsFragment = (
  { __typename: 'BillingContact' }
  & Pick<BillingContact, 'id' | 'nickname' | 'firstName' | 'lastName' | 'displayEmail'>
);

export type DeleteInvoiceExpenseMutationVariables = Exact<{
  invoiceVersionId: Scalars['ID'];
  expenseId: Scalars['ID'];
}>;


export type DeleteInvoiceExpenseMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'deleteInvoiceExpense'>
);

export type InvoiceExpenseQueryVariables = Exact<{
  expenseId: Scalars['ID'];
}>;


export type InvoiceExpenseQuery = (
  { __typename: 'Query' }
  & { invoiceExpense?: Maybe<(
    { __typename: 'InvoiceExpense' }
    & { expense: (
      { __typename: 'Expense' }
      & Pick<Expense, 'id' | 'amount' | 'memo' | 'images'>
    ) }
  )> }
);

export type UpsertInvoiceExpenseMutationVariables = Exact<{
  invoiceVersionId: Scalars['ID'];
  expenseId?: Maybe<Scalars['ID']>;
  input: ExpenseInput;
}>;


export type UpsertInvoiceExpenseMutation = (
  { __typename: 'Mutation' }
  & { upsertInvoiceExpense?: Maybe<(
    { __typename: 'InvoiceExpense' }
    & Pick<InvoiceExpense, 'expenseId' | 'invoiceVersionId'>
    & { expense: (
      { __typename: 'Expense' }
      & Pick<Expense, 'id' | 'amount' | 'memo' | 'images'>
    ) }
  )> }
);

export type InvoiceStateFragment = (
  { __typename: 'InvoiceVersion' }
  & Pick<InvoiceVersion, 'id' | 'stateKey' | 'invoiceId' | 'treasurerInvoiceId' | 'treasurerCompliant' | 'downloadToken' | 'name' | 'number' | 'actions'>
  & { recipientOrganization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
  ), issuerOrganization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'acceptedAgentOfThePayee'>
  ), history: Array<(
    { __typename: 'InvoiceHistory' }
    & Pick<InvoiceHistory, 'action' | 'userFirstName' | 'userLastName' | 'createdAt' | 'notes'>
  )> }
);

export type ApproveInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
  notes?: Maybe<Scalars['String']>;
}>;


export type ApproveInvoiceMutation = (
  { __typename: 'Mutation' }
  & { approveInvoice?: Maybe<(
    { __typename: 'InvoiceActionUpdateVersionResult' }
    & Pick<InvoiceActionUpdateVersionResult, 'updated'>
  )> }
);

export type DeleteInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
  notes?: Maybe<Scalars['String']>;
}>;


export type DeleteInvoiceMutation = (
  { __typename: 'Mutation' }
  & { deleteInvoice?: Maybe<(
    { __typename: 'InvoiceActionUpdateVersionResult' }
    & Pick<InvoiceActionUpdateVersionResult, 'updated'>
  )> }
);

export type DuplicateInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
}>;


export type DuplicateInvoiceMutation = (
  { __typename: 'Mutation' }
  & { duplicateInvoice?: Maybe<(
    { __typename: 'InvoiceActionCreateVersionResult' }
    & { updated: Array<(
      { __typename: 'InvoiceActionCreateVersion' }
      & Pick<InvoiceActionCreateVersion, 'id' | 'newInvoiceVersionId'>
    )> }
  )> }
);

export type FinalizeInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
  notes?: Maybe<Scalars['String']>;
}>;


export type FinalizeInvoiceMutation = (
  { __typename: 'Mutation' }
  & { finalizeInvoice?: Maybe<(
    { __typename: 'InvoiceActionUpdateVersionResult' }
    & Pick<InvoiceActionUpdateVersionResult, 'updated'>
  )> }
);

export type IssueInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
  notes?: Maybe<Scalars['String']>;
}>;


export type IssueInvoiceMutation = (
  { __typename: 'Mutation' }
  & { issueInvoice?: Maybe<(
    { __typename: 'InvoiceActionUpdateVersionResult' }
    & Pick<InvoiceActionUpdateVersionResult, 'updated'>
  )> }
);

export type MarkPaymentProcessedInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
  notes?: Maybe<Scalars['String']>;
}>;


export type MarkPaymentProcessedInvoiceMutation = (
  { __typename: 'Mutation' }
  & { markPaymentProcessedInvoice?: Maybe<(
    { __typename: 'InvoiceActionUpdateVersionResult' }
    & Pick<InvoiceActionUpdateVersionResult, 'updated'>
  )> }
);

export type MarkPaymentReceivedInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
  notes?: Maybe<Scalars['String']>;
}>;


export type MarkPaymentReceivedInvoiceMutation = (
  { __typename: 'Mutation' }
  & { markPaymentReceivedInvoice?: Maybe<(
    { __typename: 'InvoiceActionUpdateVersionResult' }
    & Pick<InvoiceActionUpdateVersionResult, 'updated'>
  )> }
);

export type RejectInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
  notes?: Maybe<Scalars['String']>;
}>;


export type RejectInvoiceMutation = (
  { __typename: 'Mutation' }
  & { rejectInvoice?: Maybe<(
    { __typename: 'InvoiceActionCreateVersionResult' }
    & { updated: Array<(
      { __typename: 'InvoiceActionCreateVersion' }
      & Pick<InvoiceActionCreateVersion, 'id' | 'newInvoiceVersionId'>
    )> }
  )> }
);

export type RetractInvoiceVersionMutationVariables = Exact<{
  filters: Scalars['JSON'];
  notes?: Maybe<Scalars['String']>;
}>;


export type RetractInvoiceVersionMutation = (
  { __typename: 'Mutation' }
  & { retractInvoice?: Maybe<(
    { __typename: 'InvoiceActionCreateVersionResult' }
    & { updated: Array<(
      { __typename: 'InvoiceActionCreateVersion' }
      & Pick<InvoiceActionCreateVersion, 'id' | 'newInvoiceVersionId'>
    )> }
  )> }
);

export type UndoMarkPaymentProcessedInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
  notes?: Maybe<Scalars['String']>;
}>;


export type UndoMarkPaymentProcessedInvoiceMutation = (
  { __typename: 'Mutation' }
  & { undoMarkPaymentProcessedInvoice?: Maybe<(
    { __typename: 'InvoiceActionUpdateVersionResult' }
    & Pick<InvoiceActionUpdateVersionResult, 'updated'>
  )> }
);

export type UndoMarkPaymentReceivedInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
  notes?: Maybe<Scalars['String']>;
}>;


export type UndoMarkPaymentReceivedInvoiceMutation = (
  { __typename: 'Mutation' }
  & { undoMarkPaymentReceivedInvoice?: Maybe<(
    { __typename: 'InvoiceActionUpdateVersionResult' }
    & Pick<InvoiceActionUpdateVersionResult, 'updated'>
  )> }
);

export type VoidInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
  notes?: Maybe<Scalars['String']>;
}>;


export type VoidInvoiceMutation = (
  { __typename: 'Mutation' }
  & { voidInvoice?: Maybe<(
    { __typename: 'InvoiceActionUpdateVersionResult' }
    & Pick<InvoiceActionUpdateVersionResult, 'updated'>
  )> }
);

export type InvoiceVersionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type InvoiceVersionQuery = (
  { __typename: 'Query' }
  & { invoiceVersion?: Maybe<(
    { __typename: 'InvoiceVersion' }
    & Pick<InvoiceVersion, 'id' | 'name' | 'number' | 'notes' | 'dueDate' | 'startDate' | 'gigCount' | 'invoiceExpenseAmount' | 'treasurerInvoiceId' | 'treasurerCompliant'>
    & { recipientContact?: Maybe<(
      { __typename: 'BillingContact' }
      & Pick<BillingContact, 'id' | 'nickname' | 'firstName' | 'lastName' | 'email' | 'address1' | 'address2' | 'city' | 'state' | 'zip'>
    )>, issuerContact?: Maybe<(
      { __typename: 'BillingContact' }
      & Pick<BillingContact, 'id' | 'nickname' | 'firstName' | 'lastName' | 'email' | 'address1' | 'address2' | 'city' | 'state' | 'zip'>
    )>, recipientOrganization: (
      { __typename: 'Organization' }
      & Pick<Organization, 'name' | 'id'>
    ), issuerOrganization: (
      { __typename: 'Organization' }
      & Pick<Organization, 'name' | 'id' | 'acceptedAgentOfThePayee'>
    ) }
    & InvoiceStateFragment
  )>, invoiceVersionStats: (
    { __typename: 'InvoiceVersionStats' }
    & Pick<InvoiceVersionStats, 'totalAmount'>
    & { programs: Array<(
      { __typename: 'InvoiceVersionProgramTotal' }
      & Pick<InvoiceVersionProgramTotal, 'id' | 'name' | 'total' | 'accountingCode'>
    )> }
  ), invoiceExpenses: (
    { __typename: 'PaginatedInvoiceExpense' }
    & { edges: Array<(
      { __typename: 'PaginatedInvoiceExpenseEdge' }
      & { node: (
        { __typename: 'InvoiceExpense' }
        & { expense: (
          { __typename: 'Expense' }
          & Pick<Expense, 'amount'>
        ) }
      ) }
    )> }
  ) }
);

export type UpdateInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
  input: UpdateInvoiceVersionInput;
}>;


export type UpdateInvoiceMutation = (
  { __typename: 'Mutation' }
  & { updateInvoice?: Maybe<(
    { __typename: 'InvoiceActionUpdateVersionResult' }
    & Pick<InvoiceActionUpdateVersionResult, 'updated'>
  )> }
);

export type UserOrganizationByInvoiceQueryVariables = Exact<{
  invoiceVersionId: Scalars['ID'];
  orgId: Scalars['ID'];
}>;


export type UserOrganizationByInvoiceQuery = (
  { __typename: 'Query' }
  & { userOrganizationByInvoice?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
  )> }
);

export type CreateInvoiceMutationVariables = Exact<{
  input: CreateInvoiceVersionInput;
}>;


export type CreateInvoiceMutation = (
  { __typename: 'Mutation' }
  & { createInvoice?: Maybe<(
    { __typename: 'InvoiceVersion' }
    & Pick<InvoiceVersion, 'id'>
  )> }
);

export type InvoiceIndexQueryVariables = Exact<{
  filters?: Maybe<Scalars['JSON']>;
  after?: Maybe<Scalars['ID']>;
  orderBy?: Maybe<Array<Maybe<OrderingInvoiceVersion>> | Maybe<OrderingInvoiceVersion>>;
}>;


export type InvoiceIndexQuery = (
  { __typename: 'Query' }
  & { connection: (
    { __typename: 'PaginatedInvoiceVersion' }
    & { pageInfo: (
      { __typename: 'ConnectionPageInfo' }
      & Pick<ConnectionPageInfo, 'offset'>
    ) }
  ) }
);

export type AddBillingContactMutationVariables = Exact<{
  contact: BillingContactInput;
  organizationId?: Maybe<Scalars['String']>;
}>;


export type AddBillingContactMutation = (
  { __typename: 'Mutation' }
  & { addBillingContact?: Maybe<(
    { __typename: 'BillingContact' }
    & BillingContactDetailsFragment
  )> }
);

export type BillingContactsQueryVariables = Exact<{
  orgId: Scalars['String'];
}>;


export type BillingContactsQuery = (
  { __typename: 'Query' }
  & { billingContacts?: Maybe<Array<Maybe<(
    { __typename: 'BillingContact' }
    & BillingContactDetailsFragment
  )>>> }
);

export type BillingContactDetailsFragment = (
  { __typename: 'BillingContact' }
  & Pick<BillingContact, 'id' | 'nickname' | 'firstName' | 'lastName' | 'email' | 'displayEmail' | 'company' | 'address1' | 'address2' | 'city' | 'state' | 'zip' | 'createdAt' | 'updatedAt'>
);

export type DocumentFieldQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  orgId: Scalars['String'];
}>;


export type DocumentFieldQuery = (
  { __typename: 'Query' }
  & { documents?: Maybe<Array<Maybe<(
    { __typename: 'FileDocument' }
    & Pick<FileDocument, 'id' | 'name'>
  ) | (
    { __typename: 'LinkDocument' }
    & Pick<LinkDocument, 'id' | 'name'>
  )>>> }
);

export type UpsertDocumentMutationVariables = Exact<{
  input: UpsertDocumentInput;
}>;


export type UpsertDocumentMutation = (
  { __typename: 'Mutation' }
  & { upsertDocument?: Maybe<(
    { __typename: 'FileDocument' }
    & Pick<FileDocument, 'id' | 'name' | 'url' | 'description' | 'createdAt'>
  ) | (
    { __typename: 'LinkDocument' }
    & Pick<LinkDocument, 'id' | 'name' | 'url' | 'description' | 'createdAt'>
  )> }
);

export type DocumentsQueryVariables = Exact<{
  orgId: Scalars['String'];
  documentObjectType?: Maybe<DocumentObjectType>;
}>;


export type DocumentsQuery = (
  { __typename: 'Query' }
  & { documents?: Maybe<Array<Maybe<(
    { __typename: 'FileDocument' }
    & DocumentsTableInfo_FileDocument_Fragment
  ) | (
    { __typename: 'LinkDocument' }
    & DocumentsTableInfo_LinkDocument_Fragment
  )>>> }
);

type DocumentsTableInfo_FileDocument_Fragment = (
  { __typename: 'FileDocument' }
  & Pick<FileDocument, 'id' | 'name' | 'url' | 'description' | 'createdAt'>
);

type DocumentsTableInfo_LinkDocument_Fragment = (
  { __typename: 'LinkDocument' }
  & Pick<LinkDocument, 'id' | 'name' | 'url' | 'description' | 'createdAt'>
);

export type DocumentsTableInfoFragment = DocumentsTableInfo_FileDocument_Fragment | DocumentsTableInfo_LinkDocument_Fragment;

export type EditUsersMutationVariables = Exact<{
  input: EditUserPermissionsInput;
  userId: Scalars['ID'];
}>;


export type EditUsersMutation = (
  { __typename: 'Mutation' }
  & { editUserPermissions?: Maybe<Array<Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'userType'>
    & { programs: Array<Maybe<(
      { __typename: 'Program' }
      & Pick<Program, 'id' | 'name' | 'isContact'>
    )>> }
  )>>> }
);

export type EditPermissionsDetailsQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type EditPermissionsDetailsQuery = (
  { __typename: 'Query' }
  & { me?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { organizationUser?: Maybe<(
      { __typename: 'OrganizationUser' }
      & Pick<OrganizationUser, 'id' | 'type' | 'organizationId' | 'organizationType'>
    )>, programs: Array<Maybe<(
      { __typename: 'Program' }
      & Pick<Program, 'id' | 'name' | 'executionType' | 'managementState'>
    )>> }
  )>, organization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'gigRequestsActive' | 'gigSelfAssignmentActive'>
  ) }
);

export type EditUserPermissionsModalProgramOptionsFragment = (
  { __typename: 'Program' }
  & Pick<Program, 'id' | 'name'>
);

export type ProgramGroupsEditUserQueryQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type ProgramGroupsEditUserQueryQuery = (
  { __typename: 'Query' }
  & { organization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'programGroupsVisible'>
  ), programGroups?: Maybe<Array<Maybe<(
    { __typename: 'ProgramGroup' }
    & Pick<ProgramGroup, 'id' | 'name' | 'programIds'>
  )>>> }
);

export type EditPermissionsUserDetailsQueryVariables = Exact<{
  userId: Scalars['ID'];
  orgId: Scalars['ID'];
}>;


export type EditPermissionsUserDetailsQuery = (
  { __typename: 'Query' }
  & { user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'unconfirmedEmail' | 'userType'>
    & { programs: Array<Maybe<(
      { __typename: 'Program' }
      & Pick<Program, 'id' | 'isContact'>
    )>> }
  )>, organizationUser?: Maybe<(
    { __typename: 'OrganizationUser' }
    & Pick<OrganizationUser, 'id' | 'canRequest' | 'canSelfAssign'>
  )> }
);

export type OrganizationUserQueryQueryVariables = Exact<{
  userId: Scalars['ID'];
  orgId?: Maybe<Scalars['ID']>;
}>;


export type OrganizationUserQueryQuery = (
  { __typename: 'Query' }
  & { organizationUser?: Maybe<(
    { __typename: 'OrganizationUser' }
    & Pick<OrganizationUser, 'id' | 'organizationId' | 'defaultRate' | 'salaried' | 'organizationNotes' | 'legalName' | 'employeeId'>
    & { tags?: Maybe<Array<Maybe<(
      { __typename: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )>>>, documents?: Maybe<Array<Maybe<(
      { __typename: 'FileDocument' }
      & Pick<FileDocument, 'id' | 'url' | 'name'>
    ) | (
      { __typename: 'LinkDocument' }
      & Pick<LinkDocument, 'id' | 'url' | 'name'>
    )>>> }
  )> }
);

export type UpsertOrganizationUserMutationVariables = Exact<{
  input: OrganizationUserInput;
}>;


export type UpsertOrganizationUserMutation = (
  { __typename: 'Mutation' }
  & { upsertOrganizationUser?: Maybe<(
    { __typename: 'OrganizationUser' }
    & Pick<OrganizationUser, 'id' | 'organizationNotes' | 'organizationId' | 'defaultRate' | 'salaried' | 'legalName' | 'employeeId'>
    & { tags?: Maybe<Array<Maybe<(
      { __typename: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )>>>, documents?: Maybe<Array<Maybe<(
      { __typename: 'FileDocument' }
      & Pick<FileDocument, 'id' | 'url' | 'name'>
    ) | (
      { __typename: 'LinkDocument' }
      & Pick<LinkDocument, 'id' | 'url' | 'name'>
    )>>> }
  )> }
);

export type UserProfileModalQueryVariables = Exact<{
  userId: Scalars['ID'];
  gigId?: Maybe<Scalars['ID']>;
}>;


export type UserProfileModalQuery = (
  { __typename: 'Query' }
  & { user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'unconfirmedEmail' | 'phone' | 'unconfirmedPhone' | 'kind' | 'isCorporate'>
    & { profile?: Maybe<(
      { __typename: 'UserProfile' }
      & Pick<UserProfile, 'selfSummary' | 'shirtSize' | 'birthday' | 'canDrive' | 'hasCar' | 'areas' | 'address' | 'city' | 'state' | 'zip' | 'country' | 'apartment'>
      & { languages?: Maybe<Array<Maybe<(
        { __typename: 'Option' }
        & Pick<Option, 'label' | 'key'>
      )>>> }
    )>, assets: Array<(
      { __typename: 'Asset' }
      & Pick<Asset, 'id' | 'url' | 'assetType' | 'isPrimary'>
    )>, documents?: Maybe<Array<Maybe<(
      { __typename: 'FileDocument' }
      & Pick<FileDocument, 'id' | 'name' | 'url'>
    ) | (
      { __typename: 'LinkDocument' }
      & Pick<LinkDocument, 'id' | 'name' | 'url'>
    )>>> }
  )> }
);

export type LocationFormDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LocationFormDetailsQuery = (
  { __typename: 'Query' }
  & { organizationLocation?: Maybe<(
    { __typename: 'OrganizationLocation' }
    & Pick<OrganizationLocation, 'id' | 'name' | 'internalIdentifier' | 'schedulingNotes' | 'otherNotes'>
    & { location?: Maybe<(
      { __typename: 'Location' }
      & Pick<Location, 'id' | 'address' | 'addressJson' | 'externalId' | 'latitude' | 'longitude' | 'name'>
    )>, owner?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )>, contacts?: Maybe<Array<Maybe<(
      { __typename: 'OrganizationLocationContact' }
      & Pick<OrganizationLocationContact, 'name' | 'email' | 'phone' | 'title' | 'url'>
    )>>>, tags?: Maybe<Array<Maybe<(
      { __typename: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )>>>, products: Array<(
      { __typename: 'Product' }
      & Pick<Product, 'id' | 'name'>
    )> }
  )> }
);

export type LocationFormModalOrganizationQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type LocationFormModalOrganizationQuery = (
  { __typename: 'Query' }
  & { organization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  ) }
);

export type UpsertOrganizationLocationsMutationVariables = Exact<{
  input: Array<UpsertOrganizationLocationInput> | UpsertOrganizationLocationInput;
}>;


export type UpsertOrganizationLocationsMutation = (
  { __typename: 'Mutation' }
  & { upsertOrganizationLocations: (
    { __typename: 'UpsertOrganizationLocationResult' }
    & { errors: Array<(
      { __typename: 'UpsertOrganizationLocationError' }
      & Pick<UpsertOrganizationLocationError, 'index' | 'error'>
    )> }
  ) }
);

export type ArchiveOrganizationLocationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveOrganizationLocationMutation = (
  { __typename: 'Mutation' }
  & { archiveOrganizationLocation?: Maybe<(
    { __typename: 'OrganizationLocation' }
    & Pick<OrganizationLocation, 'id'>
  )> }
);

export type LocationsTableItemsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type LocationsTableItemsQuery = (
  { __typename: 'Query' }
  & { organizationLocations: (
    { __typename: 'PaginatedOrganizationLocation' }
    & { edges: Array<(
      { __typename: 'PaginatedOrganizationLocationEdge' }
      & { node: (
        { __typename: 'OrganizationLocation' }
        & Pick<OrganizationLocation, 'id' | 'name' | 'internalIdentifier' | 'displayAddress' | 'schedulingNotes' | 'otherNotes'>
        & { location?: Maybe<(
          { __typename: 'Location' }
          & Pick<Location, 'id' | 'name' | 'address' | 'city' | 'latitude' | 'longitude'>
        )>, owner?: Maybe<(
          { __typename: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName'>
        )>, tags?: Maybe<Array<Maybe<(
          { __typename: 'Tag' }
          & Pick<Tag, 'id' | 'name'>
        )>>>, contacts?: Maybe<Array<Maybe<(
          { __typename: 'OrganizationLocationContact' }
          & Pick<OrganizationLocationContact, 'name' | 'title' | 'email' | 'phone' | 'url'>
        )>>>, products: Array<(
          { __typename: 'Product' }
          & Pick<Product, 'id' | 'name' | 'sku'>
        )> }
      ) }
    )>, pageInfo: (
      { __typename: 'ConnectionPageInfo' }
      & Pick<ConnectionPageInfo, 'totalCount'>
    ) }
  ) }
);

export type OrgPartnerOptionsFragment = (
  { __typename: 'Organization' }
  & Pick<Organization, 'id' | 'name'>
);

export type ProductFormModalBrandOptionsFragment = (
  { __typename: 'Brand' }
  & Pick<Brand, 'id' | 'name'>
);

export type OrganizationProgramsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrganizationProgramsQuery = (
  { __typename: 'Query' }
  & { organization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'programGroupsVisible'>
    & { programs: Array<(
      { __typename: 'Program' }
      & Pick<Program, 'id' | 'name'>
    )> }
  ) }
);

export type ProductCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductCategoriesQuery = (
  { __typename: 'Query' }
  & { productCategories?: Maybe<Array<Maybe<(
    { __typename: 'OptionConfig' }
    & { id: OptionConfig['key'], name: OptionConfig['label'] }
  )>>> }
);

export type ProductDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProductDetailsQuery = (
  { __typename: 'Query' }
  & { product?: Maybe<(
    { __typename: 'Product' }
    & Pick<Product, 'id' | 'name' | 'sku' | 'msrp' | 'brandId' | 'description'>
    & { category?: Maybe<(
      { __typename: 'OptionConfig' }
      & Pick<OptionConfig, 'key'>
    )>, tags?: Maybe<Array<Maybe<(
      { __typename: 'Tag' }
      & Pick<Tag, 'id'>
    )>>>, documents?: Maybe<Array<Maybe<(
      { __typename: 'FileDocument' }
      & Pick<FileDocument, 'id'>
    ) | (
      { __typename: 'LinkDocument' }
      & Pick<LinkDocument, 'id'>
    )>>>, profilePictures?: Maybe<Array<Maybe<(
      { __typename: 'FileDocument' }
      & Pick<FileDocument, 'id' | 'url'>
    ) | (
      { __typename: 'LinkDocument' }
      & Pick<LinkDocument, 'id' | 'url'>
    )>>>, programs?: Maybe<Array<(
      { __typename: 'Program' }
      & Pick<Program, 'id' | 'name'>
    )>> }
  )> }
);

export type ProductFormModalProgramOptionsFragment = (
  { __typename: 'Program' }
  & Pick<Program, 'id' | 'name'>
);

export type ProductsProgramGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsProgramGroupsQuery = (
  { __typename: 'Query' }
  & { programGroups?: Maybe<Array<Maybe<(
    { __typename: 'ProgramGroup' }
    & Pick<ProgramGroup, 'id' | 'programIds' | 'name'>
  )>>> }
);

export type UpsertProductMutationVariables = Exact<{
  input: UpsertProductInput;
}>;


export type UpsertProductMutation = (
  { __typename: 'Mutation' }
  & { upsertProduct?: Maybe<(
    { __typename: 'Product' }
    & Pick<Product, 'id' | 'name' | 'sku' | 'msrp' | 'brandId' | 'description'>
    & { category?: Maybe<(
      { __typename: 'OptionConfig' }
      & Pick<OptionConfig, 'key' | 'label'>
    )>, tags?: Maybe<Array<Maybe<(
      { __typename: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )>>>, documents?: Maybe<Array<Maybe<(
      { __typename: 'FileDocument' }
      & Pick<FileDocument, 'id' | 'name' | 'url' | 'createdAt'>
    ) | (
      { __typename: 'LinkDocument' }
      & Pick<LinkDocument, 'id' | 'name' | 'url' | 'createdAt'>
    )>>>, programs?: Maybe<Array<(
      { __typename: 'Program' }
      & Pick<Program, 'id' | 'name'>
    )>> }
  )> }
);

export type UpsertProductProfilePicturesMutationVariables = Exact<{
  input?: Maybe<Array<UpsertDocumentInput> | UpsertDocumentInput>;
  organizationId: Scalars['ID'];
}>;


export type UpsertProductProfilePicturesMutation = (
  { __typename: 'Mutation' }
  & { upsertDocuments?: Maybe<Array<Maybe<(
    { __typename: 'FileDocument' }
    & Pick<FileDocument, 'id' | 'url'>
  ) | (
    { __typename: 'LinkDocument' }
    & Pick<LinkDocument, 'id' | 'url'>
  )>>> }
);

export type ProductsTableInfoFragment = (
  { __typename: 'Product' }
  & Pick<Product, 'id' | 'name' | 'sku' | 'msrp' | 'description' | 'brandId' | 'brandName'>
  & { category?: Maybe<(
    { __typename: 'OptionConfig' }
    & Pick<OptionConfig, 'key' | 'label'>
  )>, tags?: Maybe<Array<Maybe<(
    { __typename: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )>>>, documents?: Maybe<Array<Maybe<(
    { __typename: 'FileDocument' }
    & Pick<FileDocument, 'id' | 'name' | 'url' | 'createdAt'>
  ) | (
    { __typename: 'LinkDocument' }
    & Pick<LinkDocument, 'id' | 'name' | 'url' | 'createdAt'>
  )>>> }
);

export type ProductsTableItemsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  brandIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  status?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
}>;


export type ProductsTableItemsQuery = (
  { __typename: 'Query' }
  & { orgProducts: (
    { __typename: 'PaginatedProduct' }
    & { edges: Array<(
      { __typename: 'PaginatedProductEdge' }
      & { node: (
        { __typename: 'Product' }
        & Pick<Product, 'id' | 'name' | 'sku' | 'msrp' | 'description' | 'brandId' | 'brandName'>
        & { category?: Maybe<(
          { __typename: 'OptionConfig' }
          & Pick<OptionConfig, 'key' | 'label'>
        )>, tags?: Maybe<Array<Maybe<(
          { __typename: 'Tag' }
          & Pick<Tag, 'id' | 'name'>
        )>>>, documents?: Maybe<Array<Maybe<(
          { __typename: 'FileDocument' }
          & Pick<FileDocument, 'id' | 'name' | 'url' | 'createdAt'>
        ) | (
          { __typename: 'LinkDocument' }
          & Pick<LinkDocument, 'id' | 'name' | 'url' | 'createdAt'>
        )>>>, programs?: Maybe<Array<(
          { __typename: 'Program' }
          & Pick<Program, 'id'>
        )>> }
      ) }
    )>, pageInfo: (
      { __typename: 'ConnectionPageInfo' }
      & Pick<ConnectionPageInfo, 'totalCount'>
    ) }
  ) }
);

export type SetProductActiveComponentMutationVariables = Exact<{
  input: SetProductActiveInput;
}>;


export type SetProductActiveComponentMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'setProductActive'>
);

export type TeamMembersTableQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type TeamMembersTableQuery = (
  { __typename: 'Query' }
  & { users?: Maybe<Array<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'phone' | 'userType' | 'createdAt'>
    & { organizationUser?: Maybe<(
      { __typename: 'OrganizationUser' }
      & Pick<OrganizationUser, 'id' | 'organizationId' | 'canRequest' | 'canSelfAssign'>
      & { tags?: Maybe<Array<Maybe<(
        { __typename: 'Tag' }
        & Pick<Tag, 'id'>
      )>>> }
    )> }
  )>> }
);

export type UsersTableQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type UsersTableQuery = (
  { __typename: 'Query' }
  & { users?: Maybe<Array<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'unconfirmedEmail' | 'phone' | 'unconfirmedPhone' | 'userType' | 'createdAt'>
    & { organizationUser?: Maybe<(
      { __typename: 'OrganizationUser' }
      & Pick<OrganizationUser, 'id' | 'isAdmin' | 'type' | 'organizationId' | 'organizationUserId' | 'organizationType' | 'canRequest' | 'canSelfAssign'>
      & { tags?: Maybe<Array<Maybe<(
        { __typename: 'Tag' }
        & Pick<Tag, 'id' | 'name'>
      )>>> }
    )> }
  )>> }
);

export type SettingsOrganizationQueryQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type SettingsOrganizationQueryQuery = (
  { __typename: 'Query' }
  & { organization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'partnershipsVisible' | 'programGroupsVisible' | 'workspacesActive' | 'brandingActive'>
  ) }
);

export type NavHeaderOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type NavHeaderOrganizationsQuery = (
  { __typename: 'Query' }
  & { me?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { organizations: Array<Maybe<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    )>> }
  )> }
);

export type NavHeaderOrganizationQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type NavHeaderOrganizationQuery = (
  { __typename: 'Query' }
  & { organization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'invoicingActive' | 'programGroupsVisible' | 'isWorkspace' | 'logoUrl' | 'newNav' | 'primaryColor'>
  ) }
);

export type NavHeaderProgramGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type NavHeaderProgramGroupsQuery = (
  { __typename: 'Query' }
  & { programGroups?: Maybe<Array<Maybe<(
    { __typename: 'ProgramGroup' }
    & Pick<ProgramGroup, 'name' | 'id' | 'programIds'>
  )>>> }
);

export type SetProductActiveMutationVariables = Exact<{
  input: SetProductActiveInput;
}>;


export type SetProductActiveMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'setProductActive'>
);

export type UpsertProgramRequestMutationVariables = Exact<{
  input: ProgramRequestInput;
}>;


export type UpsertProgramRequestMutation = (
  { __typename: 'Mutation' }
  & { upsertProgramRequest?: Maybe<(
    { __typename: 'ProgramRequest' }
    & Pick<ProgramRequest, 'id'>
  )> }
);

export type DuplicateProgramMutationVariables = Exact<{
  programId: Scalars['ID'];
}>;


export type DuplicateProgramMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'duplicateProgram'>
);

export type ProgramsRequestQueryVariables = Exact<{
  orgId: Scalars['ID'];
  ownedByMyOrg?: Maybe<Scalars['Boolean']>;
}>;


export type ProgramsRequestQuery = (
  { __typename: 'Query' }
  & { programs: Array<Maybe<(
    { __typename: 'Program' }
    & Pick<Program, 'id' | 'name' | 'executionType'>
  )>>, organization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'type'>
  ) }
);

export type UpdatedProgramManagementStateFragment = (
  { __typename: 'Program' }
  & Pick<Program, 'managementState'>
);

export type SetProgramManagementStateMutationVariables = Exact<{
  id: Scalars['ID'];
  state: ProgramManagementState;
}>;


export type SetProgramManagementStateMutation = (
  { __typename: 'Mutation' }
  & { updatedProgram?: Maybe<(
    { __typename: 'ProgramRequest' }
    & Pick<ProgramRequest, 'id' | 'managementState'>
  )> }
);

export type PrfOrganizationQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type PrfOrganizationQuery = (
  { __typename: 'Query' }
  & { organization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'financialPackageActive'>
  ) }
);

export type ProgramRequestQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProgramRequestQuery = (
  { __typename: 'Query' }
  & { programRequest?: Maybe<(
    { __typename: 'ProgramRequest' }
    & Pick<ProgramRequest, 'id' | 'meta' | 'managementState' | 'executionType'>
    & FullProgramFormFragment
  )> }
);

export type CreationUpsertProgramMutationVariables = Exact<{
  input: ProgramRequestInput;
}>;


export type CreationUpsertProgramMutation = (
  { __typename: 'Mutation' }
  & { upsertProgramRequest?: Maybe<(
    { __typename: 'ProgramRequest' }
    & UpsertedProgramRequestFragment
  )> }
);

export type ReportDashboardQueryVariables = Exact<{
  dashboardKey: Scalars['ID'];
  programIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  viewport?: Maybe<Scalars['JSON']>;
}>;


export type ReportDashboardQuery = (
  { __typename: 'Query' }
  & { reportDashboard?: Maybe<(
    { __typename: 'ReportDashboard' }
    & Pick<ReportDashboard, 'app' | 'key' | 'title' | 'url' | 'downloadPdf' | 'photoTemplate'>
    & { imageQuestions: Array<Maybe<(
      { __typename: 'ReportDashboardImageQuestion' }
      & Pick<ReportDashboardImageQuestion, 'id' | 'title'>
      & { images?: Maybe<Array<(
        { __typename: 'ReportDashboardImage' }
        & Pick<ReportDashboardImage, 'id' | 'gigStartTime' | 'gigEndTime' | 'gigTimeZone' | 'locationName' | 'locationAddress' | 'locationExternalId' | 'locationInternalIdentifier' | 'gogetterId' | 'gogetterName' | 'urls'>
      )>> }
    )>> }
  )> }
);

export type DashboardPdfUrlMutationVariables = Exact<{
  dashboardKey: Scalars['ID'];
  programIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  viewport?: Maybe<Scalars['JSON']>;
}>;


export type DashboardPdfUrlMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'createReportDashboardPdf'>
);

export type ReportsQueryVariables = Exact<{
  programIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  collection?: Maybe<ReportDashboardCollection>;
}>;


export type ReportsQuery = (
  { __typename: 'Query' }
  & { reportDashboards: Array<(
    { __typename: 'ReportDashboard' }
    & Pick<ReportDashboard, 'app' | 'key' | 'title' | 'viewport'>
  )> }
);

export type ActivitiesFormFragment = (
  { __typename: 'ProgramRequest' }
  & { roles?: Maybe<Array<Maybe<(
    { __typename: 'ProgramRole' }
    & Pick<ProgramRole, 'id' | 'roleId' | 'profile' | 'duties' | 'hoursPerGig' | 'volume'>
    & { uniform?: Maybe<(
      { __typename: 'Option' }
      & Pick<Option, 'key' | 'description'>
    )> }
  )>>> }
);

export type AdminInfoFormFragment = (
  { __typename: 'ProgramRequest' }
  & Pick<ProgramRequest, 'accountingCode' | 'additionalContactEmails'>
  & { payer?: Maybe<(
    { __typename: 'Option' }
    & Pick<Option, 'key' | 'description'>
  )>, billingContact?: Maybe<(
    { __typename: 'BillingContact' }
    & Pick<BillingContact, 'id'>
  )> }
);

export type PrfBillingContactFragment = (
  { __typename: 'BillingContact' }
  & Pick<BillingContact, 'id' | 'firstName' | 'lastName'>
);

export type ProgramBillingUserDetailsQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type ProgramBillingUserDetailsQuery = (
  { __typename: 'Query' }
  & { organization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  ), me?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
  )> }
);

export type TrainingFormFragment = (
  { __typename: 'ProgramRequest' }
  & Pick<ProgramRequest, 'trainingMessage'>
  & { trainingDocuments?: Maybe<Array<Maybe<(
    { __typename: 'FileDocument' }
    & Pick<FileDocument, 'id'>
  ) | (
    { __typename: 'LinkDocument' }
    & Pick<LinkDocument, 'id'>
  )>>> }
);

export type TrainingTypesOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type TrainingTypesOptionsQuery = (
  { __typename: 'Query' }
  & { options?: Maybe<Array<Maybe<(
    { __typename: 'OptionConfig' }
    & Pick<OptionConfig, 'key' | 'group' | 'label'>
  )>>> }
);

export type InvitedByFormFragment = (
  { __typename: 'ProgramRequest' }
  & { clientOrganizations: Array<(
    { __typename: 'OrganizationProgram' }
    & Pick<OrganizationProgram, 'accessLevel'>
    & { organization: (
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    ), roleRates: Array<(
      { __typename: 'ProgramRolePartnerRate' }
      & Pick<ProgramRolePartnerRate, 'rate' | 'pinataMaestroRate'>
      & { role: (
        { __typename: 'Role' }
        & Pick<Role, 'id' | 'title'>
      ) }
    )> }
  )>, ownerOrganization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
  ) }
);

export type PrfNewPorgramGroupMutationVariables = Exact<{
  name: Scalars['String'];
  programFilters: Scalars['JSON'];
}>;


export type PrfNewPorgramGroupMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'upsertProgramGroup'>
);

export type PrfOverviewOrganizationFeaturesQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type PrfOverviewOrganizationFeaturesQuery = (
  { __typename: 'Query' }
  & { organization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'programGroupsVisible' | 'programCapacityActive' | 'type'>
  ) }
);

export type OverviewFormFragment = (
  { __typename: 'ProgramRequest' }
  & Pick<ProgramRequest, 'archived' | 'name' | 'description' | 'typeKey' | 'startDate' | 'endDate' | 'locationDescription' | 'stateKeys' | 'managementState' | 'preferredAgenciesDescription' | 'estimatedGigsCount' | 'budget' | 'nameChangedAt' | 'createdAt' | 'autoActivation'>
  & { keyMetric?: Maybe<(
    { __typename: 'ProgramKeyMetric' }
    & Pick<ProgramKeyMetric, 'key' | 'description' | 'targetValue' | 'unit'>
  )>, programGroups: Array<(
    { __typename: 'ProgramGroup' }
    & Pick<ProgramGroup, 'id'>
  )> }
);

export type PrfProgramGroupFragment = (
  { __typename: 'ProgramGroup' }
  & Pick<ProgramGroup, 'id' | 'name'>
);

export type UsStatePickerFragment = (
  { __typename: 'State' }
  & Pick<State, 'name'>
  & { id: State['key'] }
);

export type NumberAvailablePartnerOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type NumberAvailablePartnerOrganizationsQuery = (
  { __typename: 'Query' }
  & { organizationConnectionsPartners: (
    { __typename: 'PaginatedOrganization' }
    & { pageInfo: (
      { __typename: 'ConnectionPageInfo' }
      & Pick<ConnectionPageInfo, 'totalCount'>
    ) }
  ) }
);

export type PartnerOrganizationsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PartnerOrganizationsQuery = (
  { __typename: 'Query' }
  & { programRequest?: Maybe<(
    { __typename: 'ProgramRequest' }
    & Pick<ProgramRequest, 'id' | 'isOwnedByCurrentOrganization'>
    & { ownerOrganization: (
      { __typename: 'Organization' }
      & Pick<Organization, 'id'>
    ), partnerOrganizations: Array<(
      { __typename: 'OrganizationProgram' }
      & Pick<OrganizationProgram, 'accessLevel'>
      & { organization: (
        { __typename: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'type'>
      ), roleRates: Array<(
        { __typename: 'ProgramRolePartnerRate' }
        & Pick<ProgramRolePartnerRate, 'rate' | 'pinataMaestroRate'>
        & { role: (
          { __typename: 'Role' }
          & Pick<Role, 'id' | 'title'>
        ) }
      )> }
    )> }
  )> }
);

export type PartnershipFormFragment = (
  { __typename: 'ProgramRequest' }
  & Pick<ProgramRequest, 'isOwnedByCurrentOrganization'>
  & { partnerOrganizations: Array<(
    { __typename: 'OrganizationProgram' }
    & Pick<OrganizationProgram, 'accessLevel'>
    & { organization: (
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    ), roleRates: Array<(
      { __typename: 'ProgramRolePartnerRate' }
      & Pick<ProgramRolePartnerRate, 'rate' | 'pinataMaestroRate'>
      & { role: (
        { __typename: 'Role' }
        & Pick<Role, 'id' | 'title'>
      ) }
    )> }
  )> }
);

export type ProductsFormFragment = (
  { __typename: 'ProgramRequest' }
  & { sampleProductsSource?: Maybe<(
    { __typename: 'Option' }
    & Pick<Option, 'key' | 'description'>
  )>, products?: Maybe<Array<Maybe<(
    { __typename: 'Product' }
    & Pick<Product, 'id' | 'name'>
  )>>> }
);

export type ProgramLogisticsFormFragment = (
  { __typename: 'ProgramRequest' }
  & Pick<ProgramRequest, 'gigBudget' | 'expenses'>
  & { demoKit?: Maybe<(
    { __typename: 'ProgramDemoKit' }
    & Pick<ProgramDemoKit, 'client' | 'agency' | 'venue'>
    & { shippingRequirements?: Maybe<(
      { __typename: 'Option' }
      & Pick<Option, 'key' | 'description'>
    )> }
  )>, internalFiles?: Maybe<Array<(
    { __typename: 'ProgramInternalFile' }
    & Pick<ProgramInternalFile, 'caption' | 'url'>
  )>> }
);

export type ProgramScheduleFormFragment = (
  { __typename: 'ProgramRequest' }
  & Pick<ProgramRequest, 'isApprovalRequired'>
  & { schedulingPolicy?: Maybe<(
    { __typename: 'Option' }
    & Pick<Option, 'key' | 'group' | 'description'>
  )>, handOffPolicy?: Maybe<(
    { __typename: 'Option' }
    & Pick<Option, 'key' | 'description'>
  )>, budgetCap?: Maybe<(
    { __typename: 'ProgramBudgetCap' }
    & Pick<ProgramBudgetCap, 'value' | 'capType'>
  )>, preliminaryScheduleDocuments?: Maybe<Array<Maybe<(
    { __typename: 'FileDocument' }
    & Pick<FileDocument, 'id'>
  ) | (
    { __typename: 'LinkDocument' }
    & Pick<LinkDocument, 'id'>
  )>>>, minimumNotice?: Maybe<(
    { __typename: 'Option' }
    & Pick<Option, 'key' | 'group'>
  )> }
);

export type TalentRequirementsFormFragment = (
  { __typename: 'ProgramRequest' }
  & { roles?: Maybe<Array<Maybe<(
    { __typename: 'ProgramRole' }
    & Pick<ProgramRole, 'id' | 'roleId' | 'profile' | 'duties' | 'hoursPerGig' | 'volume' | 'requestable' | 'selfAssignable'>
    & { uniform?: Maybe<(
      { __typename: 'Option' }
      & Pick<Option, 'key' | 'description'>
    )> }
  )>>> }
);

export type TeamFormFragment = (
  { __typename: 'ProgramRequest' }
  & { team?: Maybe<Array<Maybe<(
    { __typename: 'ProgramTeamMember' }
    & Pick<ProgramTeamMember, 'userId' | 'isContact' | 'userType' | 'firstName' | 'lastName' | 'canRequest' | 'canSelfAssign'>
  )>>> }
);

export type ProgramTeamUserDetailsQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type ProgramTeamUserDetailsQuery = (
  { __typename: 'Query' }
  & { organization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'gigRequestsActive' | 'gigSelfAssignmentActive'>
  ), me?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
  )> }
);

export type ProgramTeamQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type ProgramTeamQuery = (
  { __typename: 'Query' }
  & { users?: Maybe<Array<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'phone' | 'userType' | 'createdAt'>
    & { organizationUser?: Maybe<(
      { __typename: 'OrganizationUser' }
      & Pick<OrganizationUser, 'id' | 'organizationId' | 'canRequest' | 'canSelfAssign'>
      & { tags?: Maybe<Array<Maybe<(
        { __typename: 'Tag' }
        & Pick<Tag, 'id'>
      )>>> }
    )> }
  )>> }
);

export type FullProgramFormFragment = (
  { __typename: 'ProgramRequest' }
  & OverviewFormFragment
  & ActivitiesFormFragment
  & TalentRequirementsFormFragment
  & ProgramScheduleFormFragment
  & ProgramLogisticsFormFragment
  & ProductsFormFragment
  & TrainingFormFragment
  & AdminInfoFormFragment
  & TeamFormFragment
  & PartnershipFormFragment
  & InvitedByFormFragment
);

export type RolesQueryVariables = Exact<{
  orgId: Scalars['ID'];
  active?: Maybe<Scalars['Boolean']>;
}>;


export type RolesQuery = (
  { __typename: 'Query' }
  & { organization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'gigRequestsActive' | 'gigSelfAssignmentActive'>
    & { roles: Array<(
      { __typename: 'Role' }
      & Pick<Role, 'id' | 'title' | 'duties' | 'profile' | 'uniform' | 'uniformKey' | 'requestable' | 'selfAssignable'>
    )> }
  ) }
);

export type UpdatedProgramFragment = (
  { __typename: 'Program' }
  & Pick<Program, 'id' | 'name' | 'managementState'>
  & { roles: Array<(
    { __typename: 'ProgramRole' }
    & Pick<ProgramRole, 'id' | 'roleId' | 'title' | 'duties' | 'profile'>
    & { uniform?: Maybe<(
      { __typename: 'Option' }
      & Pick<Option, 'key' | 'description'>
    )> }
  )>, products: Array<(
    { __typename: 'Product' }
    & Pick<Product, 'id' | 'name' | 'sku'>
  )> }
);

export type UpsertedProgramRequestFragment = (
  { __typename: 'ProgramRequest' }
  & Pick<ProgramRequest, 'id' | 'name' | 'managementState' | 'executionType'>
  & { roles?: Maybe<Array<Maybe<(
    { __typename: 'ProgramRole' }
    & Pick<ProgramRole, 'id' | 'roleId' | 'title' | 'duties' | 'profile'>
    & { uniform?: Maybe<(
      { __typename: 'Option' }
      & Pick<Option, 'key' | 'description'>
    )> }
  )>>>, products?: Maybe<Array<Maybe<(
    { __typename: 'Product' }
    & Pick<Product, 'id' | 'name' | 'sku'>
  )>>> }
  & TalentRequirementsFormFragment
  & TeamFormFragment
);

export type CalendarAvailabilityOptionsQueryVariables = Exact<{
  programIds: Array<Scalars['ID']> | Scalars['ID'];
  date: Scalars['Date'];
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  orderBy?: Maybe<Array<Maybe<OrderingGigAssignableUser>> | Maybe<OrderingGigAssignableUser>>;
  status?: Maybe<GigAssignableUserStatus>;
}>;


export type CalendarAvailabilityOptionsQuery = (
  { __typename: 'Query' }
  & { gigAssignableUsersByProgramAndDate: (
    { __typename: 'PaginatedGigAssignableUser' }
    & { edges: Array<(
      { __typename: 'PaginatedGigAssignableUserEdge' }
      & { node: (
        { __typename: 'GigAssignableUser' }
        & { user: (
          { __typename: 'User' }
          & Pick<User, 'id' | 'email' | 'createdAt' | 'phone' | 'firstName' | 'lastName'>
          & { profile?: Maybe<(
            { __typename: 'UserProfile' }
            & Pick<UserProfile, 'city' | 'zip' | 'flexibleTravel'>
          )>, organizationUser?: Maybe<(
            { __typename: 'OrganizationUser' }
            & Pick<OrganizationUser, 'id' | 'isAdmin' | 'type' | 'organizationId' | 'organizationType'>
            & { tags?: Maybe<Array<Maybe<(
              { __typename: 'Tag' }
              & Pick<Tag, 'id' | 'name'>
            )>>> }
          )> }
        ) }
      ) }
    )>, pageInfo: (
      { __typename: 'ConnectionPageInfo' }
      & Pick<ConnectionPageInfo, 'totalCount'>
    ) }
  ) }
);

export type CalendarQueryVariables = Exact<{
  programIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  filters?: Maybe<Scalars['JSON']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type CalendarQuery = (
  { __typename: 'Query' }
  & Pick<Query, 'calendar'>
  & { me?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { organizations: Array<Maybe<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'availabilityCalendarActive'>
    )>> }
  )> }
);

export type OrganizationQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type OrganizationQuery = (
  { __typename: 'Query' }
  & { organization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'availabilityCalendarActive'>
  ) }
);

export type GigIndexQueryVariables = Exact<{
  filters?: Maybe<Scalars['JSON']>;
  after?: Maybe<Scalars['ID']>;
}>;


export type GigIndexQuery = (
  { __typename: 'Query' }
  & { connection: (
    { __typename: 'PaginatedGig' }
    & { pageInfo: (
      { __typename: 'ConnectionPageInfo' }
      & Pick<ConnectionPageInfo, 'offset'>
    ) }
  ) }
);

export type ArchiveProgramMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveProgramMutation = (
  { __typename: 'Mutation' }
  & { setProgramManagementState?: Maybe<(
    { __typename: 'ProgramRequest' }
    & Pick<ProgramRequest, 'id'>
  )> }
);

export type OverviewQueryVariables = Exact<{
  id: Scalars['ID'];
  orgId: Scalars['ID'];
}>;


export type OverviewQuery = (
  { __typename: 'Query' }
  & { program?: Maybe<(
    { __typename: 'ProgramRequest' }
    & Pick<ProgramRequest, 'id' | 'isOwnedByCurrentOrganization'>
    & FullProgramFormFragment
    & ProgramOverviewInfoFragment
  )>, organization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'financialPackageActive' | 'gigRequestsActive' | 'gigSelfAssignmentActive' | 'partnershipsVisible' | 'workspacesActive'>
  ) }
);

export type ProgramOverviewInfoFragment = (
  { __typename: 'ProgramRequest' }
  & Pick<ProgramRequest, 'id' | 'executionType' | 'isApprovalRequired' | 'estimatedGigsCount' | 'atCapacity' | 'capacityLimit' | 'capacityGigCount' | 'startDate' | 'endDate' | 'description' | 'locationDescription' | 'expenses' | 'trainingMessage' | 'accountingCode' | 'additionalContactEmails' | 'singleTalentOrganization'>
  & { highLevelStats: (
    { __typename: 'ProgramHighLevelStats' }
    & Pick<ProgramHighLevelStats, 'id' | 'gigs' | 'interactions' | 'samples' | 'unitsSold'>
  ), myPartner?: Maybe<(
    { __typename: 'ProgramPartner' }
    & Pick<ProgramPartner, 'id' | 'name' | 'type'>
    & { managers?: Maybe<Array<(
      { __typename: 'ProgramManager' }
      & Pick<ProgramManager, 'id' | 'firstName' | 'lastName' | 'email'>
    )>> }
  )>, type?: Maybe<(
    { __typename: 'ProgramType' }
    & Pick<ProgramType, 'key'>
    & { label: ProgramType['name'] }
  )>, states?: Maybe<Array<Maybe<(
    { __typename: 'State' }
    & Pick<State, 'key'>
    & { label: State['name'] }
  )>>>, keyMetric?: Maybe<(
    { __typename: 'ProgramKeyMetric' }
    & Pick<ProgramKeyMetric, 'key' | 'description'>
  )>, roles?: Maybe<Array<Maybe<(
    { __typename: 'ProgramRole' }
    & Pick<ProgramRole, 'id' | 'title' | 'profile' | 'duties' | 'hoursPerGig' | 'volume'>
    & { uniform?: Maybe<(
      { __typename: 'Option' }
      & Pick<Option, 'key' | 'group' | 'label' | 'description'>
    )> }
  )>>>, sampleProductsSource?: Maybe<(
    { __typename: 'Option' }
    & Pick<Option, 'key' | 'group' | 'label' | 'description'>
  )>, schedulingPolicy?: Maybe<(
    { __typename: 'Option' }
    & Pick<Option, 'key' | 'group' | 'label' | 'description'>
  )>, minimumNotice?: Maybe<(
    { __typename: 'Option' }
    & Pick<Option, 'key' | 'group' | 'label'>
  )>, preliminaryScheduleDocuments?: Maybe<Array<Maybe<(
    { __typename: 'FileDocument' }
    & Pick<FileDocument, 'id' | 'url' | 'name'>
  ) | (
    { __typename: 'LinkDocument' }
    & Pick<LinkDocument, 'id' | 'url' | 'name'>
  )>>>, demoKit?: Maybe<(
    { __typename: 'ProgramDemoKit' }
    & Pick<ProgramDemoKit, 'client' | 'agency' | 'venue'>
    & { shippingRequirements?: Maybe<(
      { __typename: 'Option' }
      & Pick<Option, 'key' | 'group' | 'label' | 'description'>
    )> }
  )>, trainingDocuments?: Maybe<Array<Maybe<(
    { __typename: 'FileDocument' }
    & DocumentsTableInfo_FileDocument_Fragment
  ) | (
    { __typename: 'LinkDocument' }
    & DocumentsTableInfo_LinkDocument_Fragment
  )>>>, payer?: Maybe<(
    { __typename: 'Option' }
    & Pick<Option, 'key' | 'group' | 'label' | 'description'>
  )>, billingContact?: Maybe<(
    { __typename: 'BillingContact' }
    & Pick<BillingContact, 'id' | 'nickname' | 'firstName' | 'lastName' | 'email' | 'company' | 'address1' | 'address2' | 'city' | 'state' | 'zip'>
  )>, internalFiles?: Maybe<Array<(
    { __typename: 'ProgramInternalFile' }
    & Pick<ProgramInternalFile, 'caption' | 'url'>
  )>> }
  & PartnershipFormFragment
);

export type OverviewUpsertProgramMutationVariables = Exact<{
  input: ProgramRequestInput;
}>;


export type OverviewUpsertProgramMutation = (
  { __typename: 'Mutation' }
  & { upsertProgramRequest?: Maybe<(
    { __typename: 'ProgramRequest' }
    & UpsertedProgramRequestFragment
    & ProgramOverviewInfoFragment
  )> }
);

export type SelectProgramsQueryVariables = Exact<{
  orgId: Scalars['ID'];
  programGroupIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type SelectProgramsQuery = (
  { __typename: 'Query' }
  & { me?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'userType' | 'isAdmin'>
    & { programs: Array<Maybe<(
      { __typename: 'Program' }
      & Pick<Program, 'id' | 'name' | 'managementState' | 'executionType' | 'archived' | 'atCapacity'>
    )>> }
    & OrganizationUserFragment
  )>, organization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'programCreationActive'>
  ), impersonator?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'isAdmin'>
  )> }
);

export type ProgramsViewQueryVariables = Exact<{
  ids?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  includeUnaccepted: Scalars['Boolean'];
}>;


export type ProgramsViewQuery = (
  { __typename: 'Query' }
  & { me?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'isAdmin'>
    & { programs: Array<Maybe<(
      { __typename: 'Program' }
      & Pick<Program, 'id' | 'name' | 'executionType' | 'archived' | 'managementState'>
    )>> }
  )> }
);

export type UserOrganizationByProgramsQueryVariables = Exact<{
  programIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  orgId: Scalars['ID'];
}>;


export type UserOrganizationByProgramsQuery = (
  { __typename: 'Query' }
  & { userOrganizationByPrograms?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'bulkUploaderActive'>
  )> }
);

export type ImpersonateModalUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ImpersonateModalUserQuery = (
  { __typename: 'Query' }
  & { adminUser?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'unconfirmedEmail'>
  )> }
);

export type StatesQueryVariables = Exact<{ [key: string]: never; }>;


export type StatesQuery = (
  { __typename: 'Query' }
  & { states: Array<(
    { __typename: 'State' }
    & Pick<State, 'key' | 'name'>
  )> }
);

export type DocumentLinkUrlQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DocumentLinkUrlQuery = (
  { __typename: 'Query' }
  & { document: (
    { __typename: 'FileDocument' }
    & Pick<FileDocument, 'id' | 'url'>
  ) | (
    { __typename: 'LinkDocument' }
    & Pick<LinkDocument, 'id' | 'url'>
  ) }
);

export type InvoiceOptionsFragment = (
  { __typename: 'InvoiceVersion' }
  & Pick<InvoiceVersion, 'id' | 'name' | 'number'>
  & { recipientOrganization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  ) }
);

export type AddGigsToInvoiceMutationVariables = Exact<{
  programIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  filters?: Maybe<Scalars['JSON']>;
  invoiceVersionId: Scalars['ID'];
}>;


export type AddGigsToInvoiceMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'addGigsToInvoice'>
);

export type ApprovalApproveGigMutationVariables = Exact<{
  programIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  message?: Maybe<Scalars['String']>;
}>;


export type ApprovalApproveGigMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'approveGig'>
);

export type ApprovalCancelGigMutationVariables = Exact<{
  programIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  message?: Maybe<Scalars['String']>;
}>;


export type ApprovalCancelGigMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'approvalCancelGig'>
);

export type ApprovalReturnGigMutationVariables = Exact<{
  programIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  message?: Maybe<Scalars['String']>;
}>;


export type ApprovalReturnGigMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'returnGig'>
);

export type OrgFeatureFlagsQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type OrgFeatureFlagsQuery = (
  { __typename: 'Query' }
  & { organization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'gigApplyActive'>
  ) }
);

export type AuthorizeGigsPaymentMutationVariables = Exact<{
  programIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  willPay?: Maybe<Scalars['Boolean']>;
}>;


export type AuthorizeGigsPaymentMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'authorizePayment'>
);

export type CancelGigsMutationVariables = Exact<{
  programIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  reasonKey: Scalars['String'];
  reasonDescription?: Maybe<Scalars['String']>;
  cancellationType: Scalars['String'];
}>;


export type CancelGigsMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'cancelGigs'>
);

export type CancellationReasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type CancellationReasonsQuery = (
  { __typename: 'Query' }
  & { CancellationReasons: Array<(
    { __typename: 'CancellationReason' }
    & Pick<CancellationReason, 'key' | 'label'>
  )> }
);

export type DeleteGigsMutationVariables = Exact<{
  programIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  filters: Scalars['JSON'];
}>;


export type DeleteGigsMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'deleteDraftGigs'>
);

export type DownloadReceiptsQueryVariables = Exact<{
  programIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
}>;


export type DownloadReceiptsQuery = (
  { __typename: 'Query' }
  & Pick<Query, 'gigReceiptsZipUrl'>
);

export type ProgramDetailsFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id'>
  & { program: (
    { __typename: 'Program' }
    & Pick<Program, 'id' | 'archived'>
  ) }
);

export type DetailsForGigVerificationFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id' | 'startTime' | 'endTime' | 'talentRate' | 'myRate' | 'totalExpenses'>
  & { program: (
    { __typename: 'Program' }
    & Pick<Program, 'id' | 'executionType'>
  ) }
);

export type RejectGigReportMutationVariables = Exact<{
  programIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  filters: Scalars['JSON'];
  message?: Maybe<Scalars['String']>;
}>;


export type RejectGigReportMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'rejectGigReport'>
);

export type ReturnGigReportMutationVariables = Exact<{
  programIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  filters: Scalars['JSON'];
  message?: Maybe<Scalars['String']>;
}>;


export type ReturnGigReportMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'returnGigReport'>
);

export type VerifyGigReportMutationVariables = Exact<{
  programIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  filters: Scalars['JSON'];
  message?: Maybe<Scalars['String']>;
  overriddenDuration?: Maybe<Scalars['Float']>;
  overriddenTotalExpenses?: Maybe<Scalars['Float']>;
}>;


export type VerifyGigReportMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'verifyGigReport'>
);

export type RequestGigApprovalMutationVariables = Exact<{
  programIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  message?: Maybe<Scalars['String']>;
}>;


export type RequestGigApprovalMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'requestGigApproval'>
);

export type PartnerFieldProgramPartnersQueryVariables = Exact<{
  programId: Scalars['ID'];
}>;


export type PartnerFieldProgramPartnersQuery = (
  { __typename: 'Query' }
  & { organizationConnectionsPartners: (
    { __typename: 'PaginatedOrganization' }
    & { edges: Array<(
      { __typename: 'PaginatedOrganizationEdge' }
      & { node: (
        { __typename: 'Organization' }
        & Pick<Organization, 'id' | 'isWorkspace'>
      ) }
    )> }
  ) }
);

export type MarkGigsClosedMutationVariables = Exact<{
  programIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  filters: Scalars['JSON'];
}>;


export type MarkGigsClosedMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'markGigsClosed'>
);

export type UndoMarkGigsClosedMutationVariables = Exact<{
  programIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  filters: Scalars['JSON'];
}>;


export type UndoMarkGigsClosedMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'undoMarkGigsClosed'>
);

export type UndoReviewGigsMutationVariables = Exact<{
  programIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  filters: Scalars['JSON'];
}>;


export type UndoReviewGigsMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'undoReviewGigs'>
);

export type UntrashGigsMutationVariables = Exact<{
  programIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  filters: Scalars['JSON'];
}>;


export type UntrashGigsMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'untrashGigs'>
);

export type GigQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GigQuery = (
  { __typename: 'Query' }
  & { gig: (
    { __typename: 'Gig' }
    & Pick<Gig, 'id'>
    & { ownerOrganization: (
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'finalApprovalActive'>
    ) }
  ) }
);

export type GigActionsQueryVariables = Exact<{
  filters?: Maybe<Scalars['JSON']>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type GigActionsQuery = (
  { __typename: 'Query' }
  & { gigActions: Array<(
    { __typename: 'GigActionCount' }
    & Pick<GigActionCount, 'actionKey' | 'actionKeyCount' | 'actionPrimary' | 'actionOrder'>
  )> }
);

export type GigsAttachmentsFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id'>
  & { finalVerification?: Maybe<(
    { __typename: 'GigFinalVerification' }
    & Pick<GigFinalVerification, 'isVerified'>
  )>, documents?: Maybe<Array<(
    { __typename: 'FileDocument' }
    & Pick<FileDocument, 'id' | 'name' | 'url'>
  ) | (
    { __typename: 'LinkDocument' }
    & Pick<LinkDocument, 'id' | 'name' | 'url'>
  )>> }
);

export type ClientTalentRateUpdateMutationVariables = Exact<{
  input: UpdateGigsInput;
  filters?: Maybe<Scalars['JSON']>;
}>;


export type ClientTalentRateUpdateMutation = (
  { __typename: 'Mutation' }
  & { updateGigs?: Maybe<Array<Maybe<(
    { __typename: 'Gig' }
    & Pick<Gig, 'id'>
  )>>> }
);

export type ClientTotalHoursUpdateMutationVariables = Exact<{
  input: UpdateGigsInput;
  filters?: Maybe<Scalars['JSON']>;
}>;


export type ClientTotalHoursUpdateMutation = (
  { __typename: 'Mutation' }
  & { updateGigs?: Maybe<Array<Maybe<(
    { __typename: 'Gig' }
    & Pick<Gig, 'id'>
  )>>> }
);

export type ClientUpdateGigExpensesMutationVariables = Exact<{
  gigId: Scalars['ID'];
  input?: Maybe<Array<Maybe<GigExpenseInput>> | Maybe<GigExpenseInput>>;
}>;


export type ClientUpdateGigExpensesMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'updateGigExpenses'>
);

export type UpdateGigExpenseMemoMutationVariables = Exact<{
  input: UpdateGigsInput;
  filters?: Maybe<Scalars['JSON']>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type UpdateGigExpenseMemoMutation = (
  { __typename: 'Mutation' }
  & { updateGigs?: Maybe<Array<Maybe<(
    { __typename: 'Gig' }
    & Pick<Gig, 'id'>
  )>>> }
);

export type GigReportExpensesQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type GigReportExpensesQuery = (
  { __typename: 'Query' }
  & { gig: (
    { __typename: 'Gig' }
    & Pick<Gig, 'id' | 'talentRate' | 'myRate' | 'talentOrganizationRate' | 'expenseNotes' | 'totalHours' | 'expenseMemo' | 'managerLaborAmount' | 'managerExpenseAmount' | 'approvalReviewedAt' | 'invoiceManagementState' | 'workflowStateOrder' | 'workflowStateGroup'>
    & { managerOrganization?: Maybe<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    )>, expenses: Array<(
      { __typename: 'GigExpense' }
      & Pick<GigExpense, 'gigId' | 'reimburseAmount' | 'chargeAmount' | 'byAgency'>
      & { expense: (
        { __typename: 'Expense' }
        & Pick<Expense, 'id' | 'amount' | 'images' | 'memo' | 'personal' | 'createdAt' | 'classKey' | 'modifiedByManager'>
      ) }
    )>, talent?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
      & { assets: Array<(
        { __typename: 'Asset' }
        & Pick<Asset, 'id' | 'url' | 'thumbnail' | 'isPrimary' | 'assetType'>
      )> }
    )>, talentOrganization?: Maybe<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    )>, location?: Maybe<(
      { __typename: 'Location' }
      & Pick<Location, 'name'>
    )>, approvalReviewedBy?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )>, role: (
      { __typename: 'Role' }
      & Pick<Role, 'id' | 'title'>
    ), program: (
      { __typename: 'Program' }
      & Pick<Program, 'id' | 'executionType'>
    ), organization?: Maybe<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'invoicingActive'>
    )>, invoice?: Maybe<(
      { __typename: 'InvoiceVersion' }
      & Pick<InvoiceVersion, 'id'>
    )>, finalVerification?: Maybe<(
      { __typename: 'GigFinalVerification' }
      & Pick<GigFinalVerification, 'isVerified'>
    )> }
  ) }
);

export type GigsCompanyFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id'>
  & { program: (
    { __typename: 'Program' }
    & Pick<Program, 'id' | 'executionType'>
    & { gigPartnerAssignment: (
      { __typename: 'GigPartnerAssignment' }
      & Pick<GigPartnerAssignment, 'canAssign' | 'isProgramOwnerOrganization' | 'hasPartners'>
    ) }
  ), partnerOrganization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )>, talentOrganization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )>, talentContacts: Array<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
  )> }
);

export type GigsDateFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id' | 'startTime' | 'endTime' | 'timezone' | 'windowEndTime' | 'windowStartTime' | 'windowLocalStartTime' | 'windowLocalEndTime' | 'duration' | 'windowAssign'>
);

export type GigsDetailsFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id' | 'title' | 'notes'>
  & { program: (
    { __typename: 'Program' }
    & Pick<Program, 'id' | 'name'>
  ), products: Array<(
    { __typename: 'Product' }
    & Pick<Product, 'id' | 'name'>
  )> }
);

export type GigsDetailsExpandedFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id' | 'title' | 'notes' | 'agencyGuidance' | 'expenseNotes'>
);

export type GigsLocationFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id' | 'customLocationName' | 'organizationLocationId'>
  & { location?: Maybe<(
    { __typename: 'Location' }
    & Pick<Location, 'id' | 'externalId' | 'name' | 'address' | 'city' | 'state' | 'latitude' | 'longitude'>
  )> }
);

export type GigsOwnerFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id' | 'createdAt'>
  & { manager?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
  )>, ownerOrganization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  ), managerOrganization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )>, talentOrganization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
  )>, createdBy?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
  )> }
);

export type GigReportOverviewFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id' | 'interactions' | 'samples' | 'unitsSold' | 'windowStartTime' | 'windowEndTime' | 'startTime' | 'customLocationName' | 'timezone' | 'workflowStateOrder'>
  & { checkinStatus?: Maybe<(
    { __typename: 'CheckInOutStatus' }
    & Pick<CheckInOutStatus, 'time'>
  )>, program: (
    { __typename: 'Program' }
    & Pick<Program, 'id' | 'executionType'>
  ), report: Array<(
    { __typename: 'ReportGroup' }
    & Pick<ReportGroup, 'id' | 'name' | 'productId' | 'productName'>
    & { subgroups: Array<(
      { __typename: 'ReportSubgroup' }
      & Pick<ReportSubgroup, 'id' | 'name' | 'type'>
      & { questions: Array<(
        { __typename: 'ReportGroupQuestion' }
        & Pick<ReportGroupQuestion, 'id' | 'shortTitle' | 'answerState' | 'answerType' | 'answerJson' | 'notApplicableDesc'>
      )> }
    )> }
  )>, products: Array<(
    { __typename: 'Product' }
    & Pick<Product, 'id'>
    & { profilePictures?: Maybe<Array<Maybe<(
      { __typename: 'FileDocument' }
      & Pick<FileDocument, 'id' | 'url'>
    ) | (
      { __typename: 'LinkDocument' }
      & Pick<LinkDocument, 'id' | 'url'>
    )>>> }
  )>, organizationLocation?: Maybe<(
    { __typename: 'OrganizationLocation' }
    & Pick<OrganizationLocation, 'id' | 'internalIdentifier'>
  )>, assignedGogetter?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
  )>, finalVerification?: Maybe<(
    { __typename: 'GigFinalVerification' }
    & Pick<GigFinalVerification, 'isVerified'>
  )>, talentOrganization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'verifiedReportEditing'>
  )>, managerOrganization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'verifiedReportEditing'>
  )>, ownerOrganization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'verifiedReportEditing'>
  ), partnerOrganization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'verifiedReportEditing'>
  )>, talent?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type GigsRoleFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id'>
  & { role: (
    { __typename: 'Role' }
    & Pick<Role, 'id' | 'title'>
  ) }
);

export type GigsRoleOverviewFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id' | 'myRate'>
  & { programRole?: Maybe<(
    { __typename: 'ProgramRole' }
    & Pick<ProgramRole, 'id' | 'title' | 'profile' | 'duties'>
    & { uniform?: Maybe<(
      { __typename: 'Option' }
      & Pick<Option, 'key' | 'group' | 'label' | 'description'>
    )> }
  )> }
);

export type CancellationDetailsFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'cancellationReasonDescription' | 'cancelledAt'>
  & { cancellationReason?: Maybe<(
    { __typename: 'CancellationReason' }
    & Pick<CancellationReason, 'key' | 'label'>
  )>, cancelledBy?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
  )> }
);

export type GigsStatusFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id' | 'workflowStateKey' | 'workflowStateNameKey' | 'cancelledAt' | 'workflowStateGroup' | 'startTime' | 'approvalRequestedAt' | 'approvalReviewedAt'>
  & { cancellationReason?: Maybe<(
    { __typename: 'CancellationReason' }
    & Pick<CancellationReason, 'key' | 'label'>
  )>, cancelledBy?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
  )>, finalVerification?: Maybe<(
    { __typename: 'GigFinalVerification' }
    & Pick<GigFinalVerification, 'isVerified'>
  )> }
);

export type GigsTalentFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id' | 'visibility' | 'talentRate' | 'applicationCount' | 'workflowStateNameKey'>
  & { programRole?: Maybe<(
    { __typename: 'ProgramRole' }
    & Pick<ProgramRole, 'id' | 'title'>
  )>, talent?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
  )>, talentOrganization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )> }
);

export type GigsTalentPersonFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id' | 'talentRate'>
  & { talentOrganization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
  )>, assignedGogetter?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
    & { assets: Array<(
      { __typename: 'Asset' }
      & Pick<Asset, 'id' | 'assetType' | 'isPrimary' | 'url'>
    )>, organizationUser?: Maybe<(
      { __typename: 'OrganizationUser' }
      & Pick<OrganizationUser, 'id' | 'organizationId' | 'salaried'>
    )> }
  )> }
);

export type TrackingVisibleFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id'>
  & { talentOrganization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
  )> }
);

export type TrackingDetailsFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id' | 'startTime' | 'endTime' | 'timezone' | 'totalExpenses'>
  & { location?: Maybe<(
    { __typename: 'Location' }
    & Pick<Location, 'id' | 'externalId' | 'latitude' | 'longitude'>
  )>, checkinStatus?: Maybe<(
    { __typename: 'CheckInOutStatus' }
    & Pick<CheckInOutStatus, 'time' | 'latitude' | 'longitude'>
  )>, checkoutStatus?: Maybe<(
    { __typename: 'CheckInOutStatus' }
    & Pick<CheckInOutStatus, 'time' | 'latitude' | 'longitude'>
  )> }
);

export type GigFormCreateGigsMutationVariables = Exact<{
  gigs: Array<CreateGigsInput> | CreateGigsInput;
}>;


export type GigFormCreateGigsMutation = (
  { __typename: 'Mutation' }
  & { createGigs: (
    { __typename: 'GigCreationResult' }
    & Pick<GigCreationResult, 'uploadId' | 'gigIds'>
  ) }
);

export type CreateGigsOrganizationQueryQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type CreateGigsOrganizationQueryQuery = (
  { __typename: 'Query' }
  & { organization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'bulkUploaderActive'>
  ) }
);

export type GigSubmitProgramDetailsFragment = (
  { __typename: 'Program' }
  & Pick<Program, 'id' | 'isApprovalRequired' | 'executionType' | 'archived' | 'atCapacity' | 'myOrgAccessLevel'>
  & { ownerOrganization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  ) }
);

export type GigIcsQueryVariables = Exact<{
  gigId: Scalars['ID'];
}>;


export type GigIcsQuery = (
  { __typename: 'Query' }
  & { gigIcs?: Maybe<(
    { __typename: 'GigIcsInfo' }
    & Pick<GigIcsInfo, 'id' | 'ics' | 'startTime' | 'windowStartTime'>
  )> }
);

export type GigAgencyGuidanceFieldFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id' | 'agencyGuidance'>
);

export type GigAssignmentOptionsQueryVariables = Exact<{
  gigIds: Array<Scalars['ID']> | Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  orderBy?: Maybe<Array<Maybe<OrderingGigAssignableUser>> | Maybe<OrderingGigAssignableUser>>;
}>;


export type GigAssignmentOptionsQuery = (
  { __typename: 'Query' }
  & { gigAssignableUsers: (
    { __typename: 'PaginatedGigAssignableUser' }
    & { edges: Array<(
      { __typename: 'PaginatedGigAssignableUserEdge' }
      & { node: (
        { __typename: 'GigAssignableUser' }
        & Pick<GigAssignableUser, 'status' | 'distance' | 'note'>
        & { user: (
          { __typename: 'User' }
          & Pick<User, 'id' | 'email' | 'createdAt' | 'phone' | 'firstName' | 'lastName' | 'kind'>
          & { organizationUser?: Maybe<(
            { __typename: 'OrganizationUser' }
            & Pick<OrganizationUser, 'id' | 'isAdmin' | 'type' | 'organizationId' | 'organizationType'>
            & { tags?: Maybe<Array<Maybe<(
              { __typename: 'Tag' }
              & Pick<Tag, 'id' | 'name'>
            )>>> }
          )> }
        ) }
      ) }
    )>, pageInfo: (
      { __typename: 'ConnectionPageInfo' }
      & Pick<ConnectionPageInfo, 'totalCount'>
    ) }
  ) }
);

export type GigAssignmentFieldFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id' | 'talentRate' | 'startTime' | 'endTime' | 'timezone'>
  & { talent?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
  )>, program: (
    { __typename: 'Program' }
    & Pick<Program, 'id' | 'executionType'>
    & { gigPartnerAssignment: (
      { __typename: 'GigPartnerAssignment' }
      & Pick<GigPartnerAssignment, 'isProgramOwnerOrganization' | 'canAssign' | 'hasPartners'>
    ) }
  ), partnerOrganization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
  )>, location?: Maybe<(
    { __typename: 'Location' }
    & Pick<Location, 'externalId' | 'latitude' | 'longitude'>
  )> }
);

export type GigAssignmentProviderFieldFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id'>
);

export type AssignmentOrganizationQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type AssignmentOrganizationQuery = (
  { __typename: 'Query' }
  & { organization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'gigApplyActive' | 'isWorkspace'>
  ) }
);

export type AssignmentTalentQueryVariables = Exact<{
  gigId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type AssignmentTalentQuery = (
  { __typename: 'Query' }
  & { user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
    & { organizationUser?: Maybe<(
      { __typename: 'OrganizationUser' }
      & Pick<OrganizationUser, 'id' | 'organizationId' | 'defaultRate' | 'salaried'>
      & { tags?: Maybe<Array<Maybe<(
        { __typename: 'Tag' }
        & Pick<Tag, 'id' | 'name'>
      )>>> }
    )> }
  )> }
);

export type GigDateFieldFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'startTime' | 'endTime' | 'timezone' | 'windowEndTime' | 'windowStartTime' | 'duration' | 'windowAssign'>
);

export type ProgramDatesFragment = (
  { __typename: 'Program' }
  & Pick<Program, 'id' | 'startDate' | 'endDate'>
);

export type GigDescriptionFieldFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id' | 'notes'>
);

export type GigExpenseNotesFieldFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id' | 'expenseNotes'>
);

export type GigProgramExpenseFragment = (
  { __typename: 'Program' }
  & Pick<Program, 'id' | 'expenses' | 'executionType'>
);

export type GigDocumentsFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id'>
  & { finalVerification?: Maybe<(
    { __typename: 'GigFinalVerification' }
    & Pick<GigFinalVerification, 'isVerified'>
  )>, documents?: Maybe<Array<(
    { __typename: 'FileDocument' }
    & Pick<FileDocument, 'id' | 'url' | 'name'>
  ) | (
    { __typename: 'LinkDocument' }
    & Pick<LinkDocument, 'id' | 'url' | 'name'>
  )>> }
);

export type GigLocationFieldFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id' | 'organizationLocationId' | 'customLocationName'>
  & { location?: Maybe<(
    { __typename: 'Location' }
    & Pick<Location, 'id' | 'externalId' | 'name' | 'latitude' | 'longitude' | 'address' | 'addressJson'>
  )> }
);

export type GigOwnerOptionsFragment = (
  { __typename: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'organizationName' | 'organizationId'>
);

export type GigOwnerFieldFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id'>
  & { manager?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
  )> }
);

export type PartnerFieldMyOrganizationQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type PartnerFieldMyOrganizationQuery = (
  { __typename: 'Query' }
  & { organization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'isWorkspace'>
    & { parentOrganization?: Maybe<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    )> }
  ) }
);

export type GigPartnerFieldFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id'>
  & { partnerOrganization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
  )>, clientOrganization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
  )>, program: (
    { __typename: 'Program' }
    & Pick<Program, 'id'>
    & { gigPartnerAssignment: (
      { __typename: 'GigPartnerAssignment' }
      & Pick<GigPartnerAssignment, 'isProgramOwnerOrganization' | 'canAssign' | 'hasPartners'>
    ) }
  ) }
);

export type GigPartnerFormProviderFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id'>
  & { partnerOrganization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )> }
);

export type PartnerFieldProgramRequestQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PartnerFieldProgramRequestQuery = (
  { __typename: 'Query' }
  & { programRequest?: Maybe<(
    { __typename: 'ProgramRequest' }
    & Pick<ProgramRequest, 'id'>
    & { clientOrganizations: Array<(
      { __typename: 'OrganizationProgram' }
      & Pick<OrganizationProgram, 'isOwner'>
      & { organization: (
        { __typename: 'Organization' }
        & Pick<Organization, 'id' | 'name'>
      ) }
    )>, gigPartnerAssignment: (
      { __typename: 'GigPartnerAssignment' }
      & Pick<GigPartnerAssignment, 'isProgramOwnerOrganization' | 'canAssign' | 'hasPartners'>
    ), ownerOrganization: (
      { __typename: 'Organization' }
      & Pick<Organization, 'id'>
    ) }
  )> }
);

export type GigMyRateFieldFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id' | 'myRate'>
);

export type GigProductOptionsFragment = (
  { __typename: 'Product' }
  & Pick<Product, 'id' | 'name'>
);

export type GigProductFieldFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id'>
  & { products: Array<(
    { __typename: 'Product' }
    & Pick<Product, 'id'>
  )> }
);

export type GigProgramOptionsFragment = (
  { __typename: 'Program' }
  & Pick<Program, 'id' | 'name'>
);

export type GigProgramFieldFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id'>
  & { program: (
    { __typename: 'Program' }
    & Pick<Program, 'id' | 'name'>
  ) }
);

export type RoleFieldFragment = (
  { __typename: 'Role' }
  & Pick<Role, 'id' | 'title' | 'skipReportingSteps'>
);

export type GigRoleFieldFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id' | 'myRate' | 'workflowStateSection'>
  & { checkinStatus?: Maybe<(
    { __typename: 'CheckInOutStatus' }
    & Pick<CheckInOutStatus, 'time'>
  )>, programRole?: Maybe<(
    { __typename: 'ProgramRole' }
    & Pick<ProgramRole, 'id'>
    & { role?: Maybe<(
      { __typename: 'Role' }
      & Pick<Role, 'id' | 'title'>
    )> }
  )>, program: (
    { __typename: 'Program' }
    & Pick<Program, 'id' | 'executionType'>
  ) }
);

export type GigTalentRateFieldFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id' | 'talentRate'>
);

export type TaskManagerPickerFieldFragment = (
  { __typename: 'Organization' }
  & Pick<Organization, 'id' | 'name'>
);

export type GigTaskManagerFieldFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id'>
  & { partnerOrganization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
  )> }
);

export type GigTaskManagerFormProviderFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id'>
  & { partnerOrganization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )> }
);

export type GigTitleFieldFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id' | 'title'>
);

export type GigVisibilityFieldFragment = (
  { __typename: 'Gig' }
  & Pick<Gig, 'id' | 'visibility'>
);

export type ProgramExecutionTypeFragment = (
  { __typename: 'Program' }
  & Pick<Program, 'id' | 'startDate' | 'endDate' | 'executionType'>
);

export type OwnerFilterFragment = (
  { __typename: 'Organization' }
  & Pick<Organization, 'id' | 'name'>
);

export type PartnerFilterFragment = (
  { __typename: 'Organization' }
  & Pick<Organization, 'id' | 'name'>
);

export type GigsContactTagsFilterFragment = (
  { __typename: 'Tag' }
  & Pick<Tag, 'id' | 'name'>
);

export type ContactFilterFragment = (
  { __typename: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName'>
);

export type FirstAndLastQueryVariables = Exact<{
  programIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  filters?: Maybe<Scalars['JSON']>;
}>;


export type FirstAndLastQuery = (
  { __typename: 'Query' }
  & { firstGig: (
    { __typename: 'PaginatedGig' }
    & { edges: Array<(
      { __typename: 'PaginatedGigEdge' }
      & { node: (
        { __typename: 'Gig' }
        & Pick<Gig, 'id' | 'localStartTime'>
      ) }
    )> }
  ), lastGig: (
    { __typename: 'PaginatedGig' }
    & { edges: Array<(
      { __typename: 'PaginatedGigEdge' }
      & { node: (
        { __typename: 'Gig' }
        & Pick<Gig, 'id' | 'localStartTime'>
      ) }
    )> }
  ) }
);

export type GigsProductFilterFragment = (
  { __typename: 'Product' }
  & Pick<Product, 'id' | 'name'>
);

export type GigsProductTagFilterFragment = (
  { __typename: 'Tag' }
  & Pick<Tag, 'id' | 'name'>
);

export type GigsProgramFilterFragment = (
  { __typename: 'Program' }
  & Pick<Program, 'id' | 'name'>
);

export type GigsLocationTagFilterFragment = (
  { __typename: 'Tag' }
  & Pick<Tag, 'id' | 'name'>
);

export type GigsSavedLocationsFilterFragment = (
  { __typename: 'OrganizationLocation' }
  & Pick<OrganizationLocation, 'id' | 'name' | 'displayAddress' | 'internalIdentifier'>
  & { location?: Maybe<(
    { __typename: 'Location' }
    & Pick<Location, 'id' | 'latitude' | 'longitude'>
  )> }
);

export type GigsLocationStatesFilterFragment = (
  { __typename: 'State' }
  & Pick<State, 'id' | 'name'>
);

export type GigStatesQueryVariables = Exact<{
  programIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  filters?: Maybe<Scalars['JSON']>;
}>;


export type GigStatesQuery = (
  { __typename: 'Query' }
  & { gigStates: (
    { __typename: 'GigStateSections' }
    & { active: (
      { __typename: 'GigSection' }
      & StateDataFragment
    ), finished: (
      { __typename: 'GigSection' }
      & StateDataFragment
    ), other: (
      { __typename: 'GigSection' }
      & StateDataFragment
    ) }
  ) }
);

export type StateDataFragment = (
  { __typename: 'GigSection' }
  & Pick<GigSection, 'count'>
  & { groups: Array<(
    { __typename: 'GigGroup' }
    & Pick<GigGroup, 'count' | 'key'>
    & { states: Array<(
      { __typename: 'GigState' }
      & Pick<GigState, 'key' | 'count' | 'isActionable'>
    )> }
  )> }
);

export type GigsAgencyFilterFragment = (
  { __typename: 'Organization' }
  & Pick<Organization, 'id' | 'name'>
);

export type GigsGoGetterFilterFragment = (
  { __typename: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName'>
);

export type GigsRoleFilterFragment = (
  { __typename: 'Role' }
  & Pick<Role, 'id' | 'title'>
);

export type GigsTalentTagsFilterFragment = (
  { __typename: 'Tag' }
  & Pick<Tag, 'id' | 'name'>
);

export type GigsCountQueryVariables = Exact<{
  programIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  filters?: Maybe<Scalars['JSON']>;
}>;


export type GigsCountQuery = (
  { __typename: 'Query' }
  & { gigs: (
    { __typename: 'PaginatedGig' }
    & { pageInfo: (
      { __typename: 'ConnectionPageInfo' }
      & Pick<ConnectionPageInfo, 'totalCount'>
    ) }
  ) }
);

export type BatchUpdateGigsMutationVariables = Exact<{
  input: UpdateGigsInput;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  filters?: Maybe<Scalars['JSON']>;
}>;


export type BatchUpdateGigsMutation = (
  { __typename: 'Mutation' }
  & { batchUpdateGigs?: Maybe<(
    { __typename: 'BatchUpdateErrors' }
    & Pick<BatchUpdateErrors, 'invalidCount' | 'errorDetailsCsvLink'>
  )> }
);

export type UploaderCreateGigsMutationVariables = Exact<{
  gigs: Array<CreateGigsInput> | CreateGigsInput;
  uploadId?: Maybe<Scalars['ID']>;
}>;


export type UploaderCreateGigsMutation = (
  { __typename: 'Mutation' }
  & { createGigs: (
    { __typename: 'GigCreationResult' }
    & Pick<GigCreationResult, 'uploadId'>
  ) }
);

export type GigsUploaderTalentFragment = (
  { __typename: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName'>
);

export type WorkflowSaveGigsMutationVariables = Exact<{
  input: UpdateGigsInput;
  filters?: Maybe<Scalars['JSON']>;
  programIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type WorkflowSaveGigsMutation = (
  { __typename: 'Mutation' }
  & { updateGigs?: Maybe<Array<Maybe<(
    { __typename: 'Gig' }
    & Pick<Gig, 'id'>
  )>>> }
);

export type InvoiceListApproveInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
}>;


export type InvoiceListApproveInvoiceMutation = (
  { __typename: 'Mutation' }
  & { approveInvoice?: Maybe<(
    { __typename: 'InvoiceActionUpdateVersionResult' }
    & Pick<InvoiceActionUpdateVersionResult, 'updated'>
  )> }
);

export type InvoiceListDeleteInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
}>;


export type InvoiceListDeleteInvoiceMutation = (
  { __typename: 'Mutation' }
  & { deleteInvoice?: Maybe<(
    { __typename: 'InvoiceActionUpdateVersionResult' }
    & Pick<InvoiceActionUpdateVersionResult, 'updated'>
  )> }
);

export type InvoiceListFinalizeInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
}>;


export type InvoiceListFinalizeInvoiceMutation = (
  { __typename: 'Mutation' }
  & { finalizeInvoice?: Maybe<(
    { __typename: 'InvoiceActionUpdateVersionResult' }
    & Pick<InvoiceActionUpdateVersionResult, 'updated'>
  )> }
);

export type InvoiceListIssueInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
}>;


export type InvoiceListIssueInvoiceMutation = (
  { __typename: 'Mutation' }
  & { issueInvoice?: Maybe<(
    { __typename: 'InvoiceActionUpdateVersionResult' }
    & Pick<InvoiceActionUpdateVersionResult, 'updated'>
  )> }
);

export type InvoiceListMarkPaymenReceivedInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
}>;


export type InvoiceListMarkPaymenReceivedInvoiceMutation = (
  { __typename: 'Mutation' }
  & { markPaymentReceivedInvoice?: Maybe<(
    { __typename: 'InvoiceActionUpdateVersionResult' }
    & Pick<InvoiceActionUpdateVersionResult, 'updated'>
  )> }
);

export type InvoiceListMarkPaymentProcessedInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
}>;


export type InvoiceListMarkPaymentProcessedInvoiceMutation = (
  { __typename: 'Mutation' }
  & { markPaymentProcessedInvoice?: Maybe<(
    { __typename: 'InvoiceActionUpdateVersionResult' }
    & Pick<InvoiceActionUpdateVersionResult, 'updated'>
  )> }
);

export type InvoiceListRejectInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
}>;


export type InvoiceListRejectInvoiceMutation = (
  { __typename: 'Mutation' }
  & { rejectInvoice?: Maybe<(
    { __typename: 'InvoiceActionCreateVersionResult' }
    & { updated: Array<(
      { __typename: 'InvoiceActionCreateVersion' }
      & Pick<InvoiceActionCreateVersion, 'id'>
    )> }
  )> }
);

export type InvoiceListRetractInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
}>;


export type InvoiceListRetractInvoiceMutation = (
  { __typename: 'Mutation' }
  & { retractInvoice?: Maybe<(
    { __typename: 'InvoiceActionCreateVersionResult' }
    & { updated: Array<(
      { __typename: 'InvoiceActionCreateVersion' }
      & Pick<InvoiceActionCreateVersion, 'id'>
    )> }
  )> }
);

export type InvoiceListUndoMarkPaymentReceivedInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
}>;


export type InvoiceListUndoMarkPaymentReceivedInvoiceMutation = (
  { __typename: 'Mutation' }
  & { undoMarkPaymentReceivedInvoice?: Maybe<(
    { __typename: 'InvoiceActionUpdateVersionResult' }
    & Pick<InvoiceActionUpdateVersionResult, 'updated'>
  )> }
);

export type InvoiceListUndoMarkPaymentProcessedInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
}>;


export type InvoiceListUndoMarkPaymentProcessedInvoiceMutation = (
  { __typename: 'Mutation' }
  & { undoMarkPaymentProcessedInvoice?: Maybe<(
    { __typename: 'InvoiceActionUpdateVersionResult' }
    & Pick<InvoiceActionUpdateVersionResult, 'updated'>
  )> }
);

export type InvoiceListVoidInvoiceMutationVariables = Exact<{
  filters: Scalars['JSON'];
}>;


export type InvoiceListVoidInvoiceMutation = (
  { __typename: 'Mutation' }
  & { voidInvoice?: Maybe<(
    { __typename: 'InvoiceActionUpdateVersionResult' }
    & Pick<InvoiceActionUpdateVersionResult, 'updated'>
  )> }
);

export type InvoiceActionsQueryVariables = Exact<{
  filters?: Maybe<Scalars['JSON']>;
  after?: Maybe<Scalars['ID']>;
}>;


export type InvoiceActionsQuery = (
  { __typename: 'Query' }
  & { invoices: (
    { __typename: 'PaginatedInvoiceVersion' }
    & { edges: Array<(
      { __typename: 'PaginatedInvoiceVersionEdge' }
      & { node: (
        { __typename: 'InvoiceVersion' }
        & Pick<InvoiceVersion, 'id' | 'stateKey' | 'actions'>
      ) }
    )> }
  ) }
);

export type DetailsInvoiceVersionFragment = (
  { __typename: 'InvoiceVersion' }
  & Pick<InvoiceVersion, 'id' | 'firstGigDate' | 'lastGigDate' | 'gigCount' | 'programNames' | 'totalAmount'>
);

export type PayeeInvoiceVersionFragment = (
  { __typename: 'InvoiceVersion' }
  & Pick<InvoiceVersion, 'id' | 'number' | 'name'>
  & { issuerContact?: Maybe<(
    { __typename: 'BillingContact' }
    & Pick<BillingContact, 'id' | 'nickname' | 'firstName' | 'lastName'>
  )>, issuerOrganization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  ) }
);

export type PayerInvoiceVersionFragment = (
  { __typename: 'InvoiceVersion' }
  & Pick<InvoiceVersion, 'id'>
  & { recipientOrganization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  ), recipientContact?: Maybe<(
    { __typename: 'BillingContact' }
    & Pick<BillingContact, 'id' | 'nickname' | 'firstName' | 'lastName'>
  )> }
);

export type InvoiceVersionStatusFragment = (
  { __typename: 'InvoiceVersion' }
  & Pick<InvoiceVersion, 'id' | 'stateKey' | 'updatedAt' | 'invoiceId' | 'treasurerInvoiceId'>
);

export type TrackingInvoiceVersionFragment = (
  { __typename: 'InvoiceVersion' }
  & Pick<InvoiceVersion, 'id' | 'downloadToken' | 'name' | 'number' | 'dueDate'>
  & { history: Array<(
    { __typename: 'InvoiceHistory' }
    & Pick<InvoiceHistory, 'action' | 'createdAt'>
  )> }
);

export type LocationUploaderTagFragment = (
  { __typename: 'Tag' }
  & Pick<Tag, 'id' | 'name'>
);

export type GigsImporterTalentFragment = (
  { __typename: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName'>
);

export type UpsertOrganizationLocationUploaderMutationVariables = Exact<{
  input: Array<UpsertOrganizationLocationInput> | UpsertOrganizationLocationInput;
}>;


export type UpsertOrganizationLocationUploaderMutation = (
  { __typename: 'Mutation' }
  & { upsertOrganizationLocations: (
    { __typename: 'UpsertOrganizationLocationResult' }
    & { errors: Array<(
      { __typename: 'UpsertOrganizationLocationError' }
      & Pick<UpsertOrganizationLocationError, 'index' | 'error'>
    )> }
  ) }
);

export type UpsertTagOrganizationLocationUploaderMutationVariables = Exact<{
  input: UpsertTagInput;
}>;


export type UpsertTagOrganizationLocationUploaderMutation = (
  { __typename: 'Mutation' }
  & { upsertTag?: Maybe<(
    { __typename: 'Tag' }
    & Pick<Tag, 'id'>
  )> }
);

export type ProductUploaderTagFragment = (
  { __typename: 'Tag' }
  & Pick<Tag, 'id' | 'name'>
);

export type UpsertOrganizationProductUploaderMutationVariables = Exact<{
  input: Array<UpsertOrganizationProductInput> | UpsertOrganizationProductInput;
}>;


export type UpsertOrganizationProductUploaderMutation = (
  { __typename: 'Mutation' }
  & { upsertOrganizationProducts: (
    { __typename: 'UpsertOrganizationProductResult' }
    & { errors: Array<(
      { __typename: 'UpsertOrganizationProductError' }
      & Pick<UpsertOrganizationProductError, 'index' | 'error'>
    )> }
  ) }
);

export type UpsertTagOrganizationProductUploaderMutationVariables = Exact<{
  input: UpsertTagInput;
}>;


export type UpsertTagOrganizationProductUploaderMutation = (
  { __typename: 'Mutation' }
  & { upsertTag?: Maybe<(
    { __typename: 'Tag' }
    & Pick<Tag, 'id'>
  )> }
);

export type OrganizationUserFragment = (
  { __typename: 'User' }
  & { organizationUser?: Maybe<(
    { __typename: 'OrganizationUser' }
    & Pick<OrganizationUser, 'id' | 'type' | 'organizationId' | 'organizationType'>
  )> }
);

export type DashboardUserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardUserInfoQuery = (
  { __typename: 'Query' }
  & { me?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'unconfirmedEmail' | 'confirmedAt' | 'isAdmin' | 'language' | 'firstOrganizationId' | 'intercomHash'>
    & { assets: Array<(
      { __typename: 'Asset' }
      & Pick<Asset, 'id' | 'assetType' | 'isPrimary' | 'url'>
    )>, organizations: Array<Maybe<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'website' | 'active' | 'customerType' | 'isDemo' | 'isWorkspace' | 'subscriptionStatus' | 'type' | 'enabledFeatures' | 'availabilityCalendarActive' | 'brandingActive' | 'bulkUploaderActive' | 'enableNewGigRequestSelectPartnerUi' | 'finalApprovalActive' | 'financialPackageActive' | 'gigApplyActive' | 'gigConfirmationActive' | 'gigRequestsActive' | 'gigSelfAssignmentActive' | 'invoicingActive' | 'oneOffLocations' | 'partnershipsVisible' | 'programCapacityActive' | 'programCreationActive' | 'programGroupsVisible' | 'treasurerActive' | 'workspacesActive' | 'slidesActive' | 'dataExportEnabled'>
      & { industry?: Maybe<(
        { __typename: 'Industry' }
        & Pick<Industry, 'id' | 'name'>
      )>, primaryBillingContact?: Maybe<(
        { __typename: 'BillingContact' }
        & Pick<BillingContact, 'id' | 'firstName' | 'lastName' | 'email' | 'displayEmail' | 'company' | 'address1' | 'address2' | 'city' | 'state' | 'zip'>
      )> }
    )>>, organizationUser?: Maybe<(
      { __typename: 'OrganizationUser' }
      & Pick<OrganizationUser, 'id' | 'isAdmin' | 'type' | 'organizationId' | 'organizationType' | 'treasurerActive' | 'bulkUploaderActive' | 'organizationInvoicingActive' | 'slidesActive' | 'canRequest' | 'canSelfAssign' | 'redirectToTheApp'>
    )> }
  )>, impersonator?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
  )> }
);

export type MyOrgsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyOrgsQuery = (
  { __typename: 'Query' }
  & { me?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { organizations: Array<Maybe<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    )>> }
  )> }
);

export type FindGigsQueryVariables = Exact<{
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  skipPageInfo: Scalars['Boolean'];
  orderBy?: Maybe<Array<Maybe<OrderingGig>> | Maybe<OrderingGig>>;
}>;


export type FindGigsQuery = (
  { __typename: 'Query' }
  & { findAGig: (
    { __typename: 'PaginatedGig' }
    & { edges: Array<(
      { __typename: 'PaginatedGigEdge' }
      & { node: (
        { __typename: 'Gig' }
        & Pick<Gig, 'id' | 'title' | 'customLocationName' | 'talentRate' | 'startTime' | 'endTime' | 'timezone' | 'windowEndTime' | 'windowStartTime' | 'windowLocalStartTime' | 'windowLocalEndTime' | 'duration'>
        & { location?: Maybe<(
          { __typename: 'Location' }
          & Pick<Location, 'address' | 'longitude' | 'latitude'>
        )>, organizationLocation?: Maybe<(
          { __typename: 'OrganizationLocation' }
          & Pick<OrganizationLocation, 'id' | 'internalIdentifier'>
        )>, program: (
          { __typename: 'Program' }
          & Pick<Program, 'id' | 'name'>
        ) }
      ) }
    )>, pageInfo?: Maybe<(
      { __typename: 'ConnectionPageInfo' }
      & Pick<ConnectionPageInfo, 'totalCount'>
    )> }
  ) }
);

export type ApplyToGigMutationVariables = Exact<{
  gigId: Scalars['ID'];
  applyHash?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
}>;


export type ApplyToGigMutation = (
  { __typename: 'Mutation' }
  & { applyToGig?: Maybe<(
    { __typename: 'Application' }
    & Pick<Application, 'id' | 'withdrawnAt'>
    & { talent: (
      { __typename: 'User' }
      & Pick<User, 'id'>
    ), gig: (
      { __typename: 'Gig' }
      & Pick<Gig, 'id'>
    ) }
  )> }
);

export type GgGigInfoQueryVariables = Exact<{
  gigId?: Maybe<Scalars['ID']>;
  applyHash?: Maybe<Scalars['String']>;
  compareToKey: Scalars['String'];
}>;


export type GgGigInfoQuery = (
  { __typename: 'Query' }
  & { gig: (
    { __typename: 'Gig' }
    & Pick<Gig, 'id' | 'selfAssignUserCanEditWhenGigHasSelfAssignableActivity' | 'timezone' | 'startTime' | 'windowStartTime' | 'windowEndTime' | 'endTime' | 'talentRate' | 'updatedAt' | 'workflowStateKey' | 'workflowStateOrder' | 'compareWorkflowStateOrder' | 'reportReviewMessage' | 'title' | 'notes' | 'expenseNotes' | 'customLocationName' | 'talentExpenses'>
    & { documents?: Maybe<Array<(
      { __typename: 'FileDocument' }
      & Pick<FileDocument, 'id' | 'name' | 'url'>
    ) | (
      { __typename: 'LinkDocument' }
      & Pick<LinkDocument, 'id' | 'name' | 'url'>
    )>>, talent?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id'>
    )>, managerOrganization?: Maybe<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'verifiedReportEditing'>
      & { uiCustomization?: Maybe<(
        { __typename: 'UICustomization' }
        & Pick<UiCustomization, 'id' | 'squareLightBgLogoUrl'>
      )> }
    )>, manager?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'phone' | 'unconfirmedPhone'>
    )>, talentOrganization?: Maybe<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'verifiedReportEditing' | 'name'>
    )>, ownerOrganization: (
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'verifiedReportEditing'>
    ), partnerOrganization?: Maybe<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'verifiedReportEditing'>
    )>, reportReviewedBy?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )>, checkinStatus?: Maybe<(
      { __typename: 'CheckInOutStatus' }
      & Pick<CheckInOutStatus, 'time' | 'latitude' | 'longitude'>
    )>, checkoutStatus?: Maybe<(
      { __typename: 'CheckInOutStatus' }
      & Pick<CheckInOutStatus, 'time' | 'latitude' | 'longitude'>
    )>, myApplication?: Maybe<(
      { __typename: 'Application' }
      & Pick<Application, 'id' | 'withdrawnAt'>
    )>, programRole?: Maybe<(
      { __typename: 'ProgramRole' }
      & Pick<ProgramRole, 'id' | 'duties' | 'title' | 'allowExpenses'>
      & { uniform?: Maybe<(
        { __typename: 'Option' }
        & Pick<Option, 'label' | 'description'>
      )>, role?: Maybe<(
        { __typename: 'Role' }
        & Pick<Role, 'id' | 'hasCheckin' | 'skipReportingSteps'>
      )> }
    )>, location?: Maybe<(
      { __typename: 'Location' }
      & Pick<Location, 'id' | 'name' | 'latitude' | 'longitude' | 'address' | 'addressJson' | 'externalId'>
    )>, products: Array<(
      { __typename: 'Product' }
      & Pick<Product, 'id' | 'description' | 'name'>
      & { category?: Maybe<(
        { __typename: 'OptionConfig' }
        & Pick<OptionConfig, 'label'>
      )>, documents?: Maybe<Array<Maybe<(
        { __typename: 'FileDocument' }
        & Pick<FileDocument, 'id' | 'name' | 'url'>
      ) | (
        { __typename: 'LinkDocument' }
        & Pick<LinkDocument, 'id' | 'name' | 'url'>
      )>>> }
    )>, organizationLocation?: Maybe<(
      { __typename: 'OrganizationLocation' }
      & Pick<OrganizationLocation, 'id' | 'name' | 'internalIdentifier'>
      & { contacts?: Maybe<Array<Maybe<(
        { __typename: 'OrganizationLocationContact' }
        & Pick<OrganizationLocationContact, 'name' | 'email' | 'phone' | 'title' | 'url'>
      )>>> }
    )>, program: (
      { __typename: 'Program' }
      & Pick<Program, 'id' | 'name' | 'description' | 'executionType' | 'requirements' | 'expenses' | 'locationDescription'>
      & { keyMetric?: Maybe<(
        { __typename: 'Option' }
        & Pick<Option, 'key' | 'description' | 'label'>
      )>, trainingDocuments: Array<(
        { __typename: 'FileDocument' }
        & Pick<FileDocument, 'id' | 'name' | 'url'>
      ) | (
        { __typename: 'LinkDocument' }
        & Pick<LinkDocument, 'id' | 'name' | 'url'>
      )>, sampleProductsSource?: Maybe<(
        { __typename: 'Option' }
        & Pick<Option, 'label' | 'description'>
      )> }
    ), assignedGogetter?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id'>
    )>, expenses: Array<(
      { __typename: 'GigExpense' }
      & Pick<GigExpense, 'gigId' | 'reimburseAmount' | 'chargeAmount'>
      & { expense: (
        { __typename: 'Expense' }
        & Pick<Expense, 'id' | 'amount' | 'memo' | 'personal' | 'classKey' | 'images'>
        & { createdBy: (
          { __typename: 'User' }
          & Pick<User, 'id' | 'firstName'>
        ) }
      ) }
    )> }
  ) }
);

export type WithdrawApplicationMutationVariables = Exact<{
  applicationId: Scalars['ID'];
}>;


export type WithdrawApplicationMutation = (
  { __typename: 'Mutation' }
  & { withdrawApplication?: Maybe<(
    { __typename: 'Application' }
    & Pick<Application, 'id' | 'withdrawnAt'>
  )> }
);

export type CancelGigsGgMutationVariables = Exact<{
  programIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  filters?: Maybe<Scalars['JSON']>;
  reasonKey?: Maybe<Scalars['String']>;
  reasonDescription?: Maybe<Scalars['String']>;
}>;


export type CancelGigsGgMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'cancelGigs'>
);

export type TalentLocationMutationMutationVariables = Exact<{
  input: UserProfileInput;
}>;


export type TalentLocationMutationMutation = (
  { __typename: 'Mutation' }
  & { upsertUserProfile?: Maybe<(
    { __typename: 'UserProfile' }
    & Pick<UserProfile, 'canDrive' | 'hasCar' | 'areas' | 'address' | 'apartment'>
  )> }
);

export type TalentLocationQueryVariables = Exact<{ [key: string]: never; }>;


export type TalentLocationQuery = (
  { __typename: 'Query' }
  & { me?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { profile?: Maybe<(
      { __typename: 'UserProfile' }
      & Pick<UserProfile, 'canDrive' | 'hasCar' | 'areas' | 'address' | 'apartment' | 'flexibleTravel' | 'shipHome' | 'shippingAddress'>
    )> }
  )> }
);

export type MissingProfileImgQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type MissingProfileImgQueryQuery = (
  { __typename: 'Query' }
  & { me?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { assets: Array<(
      { __typename: 'Asset' }
      & Pick<Asset, 'id' | 'assetType' | 'isPrimary'>
    )> }
  )> }
);

export type FindMyGigsQueryVariables = Exact<{
  filters?: Maybe<Scalars['JSON']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  skipPageInfo: Scalars['Boolean'];
  orderBy?: Maybe<Array<Maybe<OrderingGig>> | Maybe<OrderingGig>>;
}>;


export type FindMyGigsQuery = (
  { __typename: 'Query' }
  & { findMyGigs: (
    { __typename: 'PaginatedGig' }
    & { edges: Array<(
      { __typename: 'PaginatedGigEdge' }
      & { node: (
        { __typename: 'Gig' }
        & Pick<Gig, 'id' | 'timezone' | 'startTime' | 'endTime' | 'windowEndTime' | 'windowStartTime' | 'windowLocalStartTime' | 'windowLocalEndTime' | 'duration' | 'title' | 'customLocationName' | 'workflowStateGroup' | 'workflowStateSection'>
        & { talent?: Maybe<(
          { __typename: 'User' }
          & Pick<User, 'id'>
        )>, program: (
          { __typename: 'Program' }
          & Pick<Program, 'id' | 'name'>
        ), location?: Maybe<(
          { __typename: 'Location' }
          & Pick<Location, 'id' | 'name' | 'address'>
        )>, checkinStatus?: Maybe<(
          { __typename: 'CheckInOutStatus' }
          & Pick<CheckInOutStatus, 'time'>
        )>, checkoutStatus?: Maybe<(
          { __typename: 'CheckInOutStatus' }
          & Pick<CheckInOutStatus, 'time'>
        )>, organizationLocation?: Maybe<(
          { __typename: 'OrganizationLocation' }
          & Pick<OrganizationLocation, 'id' | 'internalIdentifier'>
        )> }
      ) }
    )>, pageInfo?: Maybe<(
      { __typename: 'ConnectionPageInfo' }
      & Pick<ConnectionPageInfo, 'totalCount'>
    )> }
  ) }
);

export type LanguageOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type LanguageOptionsQuery = (
  { __typename: 'Query' }
  & { options?: Maybe<Array<Maybe<(
    { __typename: 'OptionConfig' }
    & Pick<OptionConfig, 'key' | 'label'>
  )>>> }
);

export type ProfileInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileInfoQuery = (
  { __typename: 'Query' }
  & { me?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'phone'>
    & { assets: Array<(
      { __typename: 'Asset' }
      & Pick<Asset, 'id' | 'url' | 'isPrimary'>
    )>, documents?: Maybe<Array<Maybe<(
      { __typename: 'FileDocument' }
      & Pick<FileDocument, 'id' | 'pkey' | 'url' | 'name' | 'status' | 'createdAt' | 'createdBy' | 'updatedAt'>
    ) | (
      { __typename: 'LinkDocument' }
      & Pick<LinkDocument, 'id' | 'pkey' | 'url' | 'name' | 'status' | 'createdAt' | 'createdBy' | 'updatedAt'>
    )>>>, profile?: Maybe<(
      { __typename: 'UserProfile' }
      & Pick<UserProfile, 'selfSummary' | 'shirtSize' | 'birthday'>
      & { languages?: Maybe<Array<Maybe<(
        { __typename: 'Option' }
        & Pick<Option, 'label' | 'key'>
      )>>> }
    )> }
  )> }
);

export type UpdateMyAssetsMutationVariables = Exact<{
  assets: Array<UserAsset> | UserAsset;
}>;


export type UpdateMyAssetsMutation = (
  { __typename: 'Mutation' }
  & { updateMyAssets?: Maybe<Array<(
    { __typename: 'Asset' }
    & Pick<Asset, 'id' | 'url' | 'isPrimary' | 'assetType'>
  )>> }
);

export type MyAccountUpdateMyDetailsMutationVariables = Exact<{
  input: UpdateMyDetailsInput;
}>;


export type MyAccountUpdateMyDetailsMutation = (
  { __typename: 'Mutation' }
  & { updateMyDetails?: Maybe<(
    { __typename: 'UpdateMyDetailsPayload' }
    & { me: (
      { __typename: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'phone'>
      & { documents?: Maybe<Array<Maybe<(
        { __typename: 'FileDocument' }
        & Pick<FileDocument, 'id' | 'name' | 'url' | 'pkey' | 'status' | 'createdBy' | 'createdAt' | 'updatedAt'>
      ) | (
        { __typename: 'LinkDocument' }
        & Pick<LinkDocument, 'id' | 'name' | 'url' | 'pkey' | 'status' | 'createdBy' | 'createdAt' | 'updatedAt'>
      )>>> }
    ) }
  )> }
);

export type UpdateMyProfileMutationVariables = Exact<{
  input: UserProfileInput;
}>;


export type UpdateMyProfileMutation = (
  { __typename: 'Mutation' }
  & { upsertUserProfile?: Maybe<(
    { __typename: 'UserProfile' }
    & Pick<UserProfile, 'selfSummary' | 'shirtSize' | 'birthday'>
    & { languages?: Maybe<Array<Maybe<(
      { __typename: 'Option' }
      & Pick<Option, 'label' | 'key'>
    )>>> }
  )> }
);

export type SettingsHeaderOrganizationQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type SettingsHeaderOrganizationQuery = (
  { __typename: 'Query' }
  & { organization: (
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'logoUrl' | 'newNav' | 'primaryColor'>
  ) }
);

export type UserQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQueryQuery = (
  { __typename: 'Query' }
  & { me?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'phone' | 'language'>
    & { assets: Array<(
      { __typename: 'Asset' }
      & Pick<Asset, 'id' | 'assetType' | 'url' | 'thumbnail' | 'isPrimary'>
    )>, profile?: Maybe<(
      { __typename: 'UserProfile' }
      & Pick<UserProfile, 'address' | 'shipHome' | 'shippingAddress'>
    )>, organizationUser?: Maybe<(
      { __typename: 'OrganizationUser' }
      & Pick<OrganizationUser, 'id' | 'type'>
    )> }
  )> }
);

export type ReporterMeQueryVariables = Exact<{ [key: string]: never; }>;


export type ReporterMeQuery = (
  { __typename: 'Query' }
  & { me?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'isCorporate' | 'intercomHash' | 'language'>
    & { selfAssignableOrganizations: Array<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id'>
    )>, organizations: Array<Maybe<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'type' | 'website'>
    )>>, assets: Array<(
      { __typename: 'Asset' }
      & Pick<Asset, 'id' | 'assetType' | 'isPrimary' | 'url'>
    )>, profile?: Maybe<(
      { __typename: 'UserProfile' }
      & Pick<UserProfile, 'address'>
    )> }
  )> }
);

export type CheckInMutationVariables = Exact<{
  gigId?: Maybe<Scalars['ID']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
}>;


export type CheckInMutation = (
  { __typename: 'Mutation' }
  & { checkIn?: Maybe<(
    { __typename: 'Gig' }
    & Pick<Gig, 'id'>
    & { checkinStatus?: Maybe<(
      { __typename: 'CheckInOutStatus' }
      & Pick<CheckInOutStatus, 'time' | 'latitude' | 'longitude'>
    )> }
  )> }
);

export type CheckOutMutationVariables = Exact<{
  gigId?: Maybe<Scalars['ID']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
}>;


export type CheckOutMutation = (
  { __typename: 'Mutation' }
  & { checkOut?: Maybe<(
    { __typename: 'Gig' }
    & Pick<Gig, 'id'>
    & { checkoutStatus?: Maybe<(
      { __typename: 'CheckInOutStatus' }
      & Pick<CheckInOutStatus, 'time' | 'latitude' | 'longitude'>
    )> }
  )> }
);

export type ProductInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProductInfoQuery = (
  { __typename: 'Query' }
  & { product?: Maybe<(
    { __typename: 'Product' }
    & Pick<Product, 'id' | 'name' | 'msrp' | 'description'>
    & { documents?: Maybe<Array<Maybe<(
      { __typename: 'FileDocument' }
      & Pick<FileDocument, 'id' | 'name' | 'url'>
    ) | (
      { __typename: 'LinkDocument' }
      & Pick<LinkDocument, 'id' | 'name' | 'url'>
    )>>>, profilePictures?: Maybe<Array<Maybe<(
      { __typename: 'FileDocument' }
      & Pick<FileDocument, 'id' | 'url'>
    ) | (
      { __typename: 'LinkDocument' }
      & Pick<LinkDocument, 'id' | 'url'>
    )>>> }
  )> }
);

export type GigReportStepsQueryVariables = Exact<{
  gigId: Scalars['ID'];
}>;


export type GigReportStepsQuery = (
  { __typename: 'Query' }
  & { gigReportSteps?: Maybe<Array<Maybe<(
    { __typename: 'GigReportStep' }
    & Pick<GigReportStep, 'id' | 'key' | 'name' | 'shortDesc' | 'productId' | 'productTagIds' | 'productBrandId' | 'progress' | 'state' | 'stateChecksum'>
    & { questions: Array<(
      { __typename: 'FullQuestion' }
      & Pick<FullQuestion, 'id' | 'key' | 'groupKey' | 'gogetterTitle' | 'helper' | 'footer' | 'optional' | 'answerType' | 'inputPlaceholder' | 'options' | 'notApplicableDesc' | 'bombonAST' | 'elementSqids' | 'atomTagSqids' | 'elementTypeKey' | 'filters' | 'atomScope' | 'hasOtherOption'>
    )> }
  )>>> }
);

export type SaveStepMutationVariables = Exact<{
  input: SaveGigReportStepInput;
}>;


export type SaveStepMutation = (
  { __typename: 'Mutation' }
  & { saveGigReportStep?: Maybe<(
    { __typename: 'GigReportStep' }
    & Pick<GigReportStep, 'id' | 'progress' | 'stateChecksum'>
  )> }
);

export type SubmitGigReportMutationVariables = Exact<{
  gigId: Scalars['ID'];
}>;


export type SubmitGigReportMutation = (
  { __typename: 'Mutation' }
  & { submitGigReport?: Maybe<(
    { __typename: 'Gig' }
    & Pick<Gig, 'id' | 'workflowStateKey'>
  )> }
);
