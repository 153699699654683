import * as React from "react";
import { ApolloClient } from "@apollo/client";
import { withApollo } from "@apollo/client/react/hoc";
import { RouteComponentProps } from "react-router-dom";
import moment from "moment";
import _, { flowRight as compose } from "lodash";
import { PhotoTemplate } from "gql-gen";
import { Location } from "history";

import downloadIcon from "assets/download.svg";

import Icon from "components/Icon";
import { getProgramIdFromSearch } from "utilities/routes";
import Clickable from "components/Clickable";
import variables from "styles/variables";
import getServerRootUrl, { getRustBackendUrl } from "utilities/getServerRootUrl";
import { getJwt } from "utilities/authentication";
import { withUserInfoNoCache, UserInfoQuery } from "modules/Dashboard/UserInfo";
import { getAppUrl } from "utilities/routes";
import { isNumber } from "utilities/number";
import { isString } from "utilities/string";

import styles from "./styles.scss";

import dashboardPdfUrl from "./dashboardPdfUrl.gql";
import dashboardQuery from "./dashboard.gql";

import DashboardPlaceholder from "../DashboardPlaceholder";
import ImageQuestionGallery, { ReportDashboardImageQuestion } from "./ImageQuestionGallery";
import { ProgramsRouteParams } from "../../index";
import { withDownloads, DownloadsContext } from "../../../../Downloads";
import { ParsedViewport, parseViewport, Viewport } from "./viewport";

const IMAGE_LIMIT = 500;

// We hardcode these in a promise to make it easier to change them later
// once we have a better way to get the ids from the backend
const loadBeforeAfterDashboardGroupKeys = (): Promise<[string, string]> =>
  Promise.resolve(["beforePhotoPP", "afterPhotoPP"]);

type CommonFilters = {
  date_range?: { start: string; end: string };
  activity_ids: number[];
  product_ids: number[];
  brand_ids: number[];
  location_ids: number[];
  states: string[];
  gig_states: string[];
  program_uuids: string[];
  product_tags: number[];
};

type SlidesReportFiltersBeforeAfter = CommonFilters & {
  template: "BeforeAfter";
  question_group_keys: [string, string];
  program_uuids: string[];
};

type SlidesReportFiltersSimple = CommonFilters & {
  template: "Simple";
  question_ids: number[];
};

const getProgramIds = (location: Location) => {
  // The `getProgramIdFromSearch` helper has a different return type and behavior
  // It returns a string (ie. no split) or "all" if no program is selected
  const programs = new URLSearchParams(location.search).get("program")?.split(",") ?? [];

  return programs.filter((p) => p !== "all");
};

const slidesReportFiltersSimple = (
  { filters, daterange }: ParsedViewport,
  location: Location,
): SlidesReportFiltersSimple => ({
  template: "Simple",
  date_range: daterange,
  activity_ids: filters.activity_ids?.filter(isNumber) ?? [],
  product_ids: filters.product_ids?.filter(isNumber) ?? [],
  brand_ids: filters.brand_ids?.filter(isNumber) ?? [],
  location_ids: filters.location_ids?.filter(isNumber) ?? [],
  states: filters.states?.filter(isString) ?? [],
  gig_states: filters.gig_states?.filter(isString) ?? [],
  program_uuids: getProgramIds(location),
  product_tags: filters.product_tags?.filter(isNumber) ?? [],
  question_ids: filters.question_ids?.filter(isNumber) ?? [],
});

const slidesReportFiltersBeforeAfter = (
  { filters, daterange }: ParsedViewport,
  location: Location,
  question_group_keys: [string, string],
): SlidesReportFiltersBeforeAfter => ({
  template: "BeforeAfter",
  date_range: daterange,
  activity_ids: filters.activity_ids?.filter(isNumber) ?? [],
  brand_ids: filters.brand_ids?.filter(isNumber) ?? [],
  product_ids: filters.product_ids?.filter(isNumber) ?? [],
  location_ids: filters.location_ids?.filter(isNumber) ?? [],
  states: filters.states?.filter(isString) ?? [],
  gig_states: filters.gig_states?.filter(isString) ?? [],
  program_uuids: getProgramIds(location),
  product_tags: filters.product_tags?.filter(isNumber) ?? [],
  question_group_keys,
});

export interface IReportDashboard {
  key: string;
  title: string;
  url: string | null;
  viewport: ParsedViewport;
  photoTemplate?: PhotoTemplate;
  downloadPdf: boolean;
  imageQuestions: ReportDashboardImageQuestion[];
}

interface ReportDashboardRouteParams extends ProgramsRouteParams {
  dashboard: string;
}

interface Props extends RouteComponentProps<ReportDashboardRouteParams>, UserInfoQuery {
  client: ApolloClient<any>;
  downloads: DownloadsContext;
  viewport: Viewport;
  onViewportChange: (viewport: Viewport) => void;
  onDashboardUnavailable: (key: string | null) => void;
}

interface State {
  loadingIframe: boolean;
  dashboard: IReportDashboard | null;
  waiting: boolean;
  questionGroupKeys?: [string, string];
}

class ReportDashboard extends React.Component<Props, State> {
  readonly state: State = {
    loadingIframe: true,
    dashboard: null,
    waiting: false,
  };

  public iframeRef = React.createRef<HTMLIFrameElement>();

  public componentDidMount() {
    window.addEventListener("message", this.handleOnMessage);
    this.loadDashboard();
  }

  public componentWillUnmount() {
    window.removeEventListener("message", this.handleOnMessage);
  }

  public componentDidUpdate({
    match: { params: oldParams },
    location: oldLocation,
    viewport: oldViewport,
  }: Props) {
    const {
      match: { params },
      location,
      viewport,
    } = this.props;
    const oldId = getProgramIdFromSearch(oldLocation.search);
    const newId = getProgramIdFromSearch(location.search);
    const viewportS = JSON.stringify(viewport);
    const oldViewportS = JSON.stringify(oldViewport);

    if (oldParams.dashboard !== params.dashboard || oldId !== newId || viewportS !== oldViewportS) {
      this.setState({ dashboard: null, loadingIframe: true, waiting: false });
      this.loadDashboard();
    }
  }

  public async loadDashboard() {
    const {
      client,
      match: {
        params: { dashboard: dashboardKey },
      },
      viewport,
    } = this.props;
    const id = getProgramIdFromSearch(this.props.location.search);

    try {
      const {
        data: { reportDashboard: dashboard },
      } = await client.query({
        query: dashboardQuery,
        variables: {
          dashboardKey,
          programIds: !id || id === "all" ? null : id.split(","),
          viewport: parseViewport(viewport),
        },
      });

      this.setState({ dashboard });

      const photoTemplate = dashboard?.photoTemplate;

      if (photoTemplate === PhotoTemplate.BeforeAfter) {
        const questionGroupKeys = await loadBeforeAfterDashboardGroupKeys();

        this.setState({ questionGroupKeys });
      }
    } catch (error) {
      if (_.get(error, "graphQLErrors.0.message") === "dashboardNotFound") {
        this.props.onDashboardUnavailable(dashboardKey);
      }
    }
  }
  public handleOnMessage = (e: MessageEvent) => {
    const { current: iframe } = this.iframeRef;

    if (!iframe) return;

    if (e.source === iframe.contentWindow) {
      const edata = e.data as any;

      if (edata.event_type === "dashboard_resize") {
        iframe.style.height = edata.dashboard_height + 50 + "px";
        this.setState({ loadingIframe: false });
      } else if (edata.event_type === "viewport_changed") {
        const viewport = _.pick(edata, ["aggregation", "daterange", "filters"]);
        this.props.onViewportChange(viewport);
      }
    }
  };

  public downloadPDF = async () => {
    const { waiting, dashboard } = this.state;
    if (waiting || !dashboard) return;

    const {
      match: {
        params: { dashboard: dashboardKey },
      },
      location,
      downloads,
      client,
      viewport,
    } = this.props;
    const id = getProgramIdFromSearch(location.search);

    this.setState({ waiting: true }, () => {
      setTimeout(() => {
        this.setState({ waiting: false });
      }, 3000);
    });

    const fileId = downloads.createFile(
      `${dashboard.title}-${moment().format("YYYYMMDDHHmmss")}.pdf`,
      { withAuth: true },
    );

    const { data } = await client.mutate({
      mutation: dashboardPdfUrl,
      variables: {
        dashboardKey,
        programIds: !id || id === "all" ? null : id.split(","),
        viewport: parseViewport(viewport),
      },
    });

    if (data) {
      const pdfUrl = getServerRootUrl() + "/dashboard-pdf/" + data.createReportDashboardPdf;

      downloads.setFileUrl({
        id: fileId,
        url: pdfUrl,
        pollUrl: pdfUrl,
        method: { method: "until-body-type", type: "binary/octet-stream" },
      });
    }
  };

  downloadSlidesReport = async () => {
    this.setState((pState) => ({ ...pState, waiting: true }));
    const { downloads, viewport } = this.props;
    const { dashboard, questionGroupKeys } = this.state;
    const { orgId } = this.props.match.params;

    if (!dashboard || !dashboard?.photoTemplate) {
      throw new Error("Dashboard or photo template not found");
    }

    const parsed = parseViewport(viewport);

    const filters =
      // TODO: when we introduce the template picker we should handle this case
      //       if the template is BeforeAfter and there are no group keys,
      //       it should fail rather than defaulting to the other template mode
      dashboard.photoTemplate === PhotoTemplate.BeforeAfter && questionGroupKeys
        ? slidesReportFiltersBeforeAfter(parsed, this.props.location, questionGroupKeys)
        : slidesReportFiltersSimple(parsed, this.props.location);

    try {
      const result = await fetch(
        `${getRustBackendUrl()}/api/v1/organizations/${orgId}/reports/photos`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getJwt()}`,
          },
          body: JSON.stringify(filters),
        },
      );
      const res = await result.json();

      const fileId = downloads.createFile("Photo Report", { withAuth: true });
      const sqid = res.document_sqid;

      const fileUrl = `${getRustBackendUrl()}/api/v1/organizations/${orgId}/reports/photos/url/${sqid}`;

      downloads.setFileUrl({
        id: fileId,
        url: fileUrl,
        pollUrl: fileUrl,
        method: { method: "rustend-json" },
      });
    } catch (e) {
      console.error(e);
    }
  };

  downloadReportBtn = () => {
    const { dashboard, waiting } = this.state;
    const { userInfo } = this.props;

    if (
      !userInfo?.me?.organizationUser?.slidesActive ||
      !dashboard ||
      !dashboard?.imageQuestions?.length ||
      !dashboard?.photoTemplate
    ) {
      return null;
    }

    const isBeforeAfter = dashboard.photoTemplate === PhotoTemplate.BeforeAfter;

    const imagesCount = dashboard.imageQuestions
      .flatMap(({ images }) => images)
      // BeforeAfter format: count only the first image of each question
      .reduce((count, { urls }) => count + (isBeforeAfter ? urls.slice(0, 1) : urls).length, 0);

    const tooMany = imagesCount > IMAGE_LIMIT;
    const notEnough = imagesCount === 0;
    const downloadDisabled = tooMany || notEnough;

    return (
      <Clickable
        disabled={downloadDisabled || waiting}
        actionLabel={""}
        onClick={downloadDisabled ? () => {} : this.downloadSlidesReport}
      >
        <span className={styles.downloadText}>
          {waiting ? (
            "Generating, this might take a while.."
          ) : (
            <>
              Generate Photo Slides{" "}
              {tooMany ? (
                <span className={styles.warning}>({IMAGE_LIMIT} images max)</span>
              ) : notEnough ? (
                <span className={styles.warning}>(no photos selected)</span>
              ) : null}
            </>
          )}
        </span>
        {!waiting && <Icon src={downloadIcon} size={20} fill={variables.gray1} />}
      </Clickable>
    );
  };

  dataExportLink = () => {
    const { dashboard } = this.state;
    const { userInfo } = this.props;

    const orgId = userInfo?.me?.organizationUser?.organizationId;

    if (!orgId || !dashboard?.photoTemplate) {
      return null;
    }

    const org = userInfo?.me?.organizations.find((o) => o.id === orgId);

    if (!org?.dataExportEnabled) {
      return null;
    }

    return (
      <a
        href={getAppUrl("theApp", `${orgId}/data-export`)}
        target="_blank"
      >
        <span className={styles.downloadText}>Go to Export Data</span>
      </a>
    );
  };

  public render() {
    const { dashboard, loadingIframe, waiting } = this.state;

    const goToExportDataLink = this.dataExportLink();
    const downloadReportBtn = this.downloadReportBtn();

    return (
      <div className={styles.rdashboard}>
        {(loadingIframe || !dashboard) && <DashboardPlaceholder />}

        {dashboard && dashboard.url && (
          <React.Fragment>
            {dashboard.downloadPdf && (
              <Clickable
                actionLabel={""}
                onClick={this.downloadPDF}
                className={waiting ? styles.waitingDownloadBtn : styles.downloadBtn}
              >
                <span className={styles.downloadText}>Download PDF</span>
                <Icon src={downloadIcon} size={20} fill={variables.gray1} />
              </Clickable>
            )}

            <div className={styles.downloadBtnGroup}>
              {goToExportDataLink}
              {goToExportDataLink && downloadReportBtn && <div>|</div>}
              {downloadReportBtn}
            </div>

            <iframe
              ref={this.iframeRef}
              title={"Report Dashboard"}
              src={dashboard.url}
              className={loadingIframe ? styles.loadingIframe : styles.iframe}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default compose(withApollo, withUserInfoNoCache, withDownloads)(ReportDashboard);
