import * as React from "react";
import { useState, useEffect } from "react";
import cx from "classnames";
import { idIn } from "utilities/knueppel";
import LegacyText from "components/LegacyText";
import { WorkflowState } from "interfaces/Gig";
import ReportStatus from "../GigInfo/ReportStatus";
import DotDotDot from "components/DotDotDot";
import styles from "./styles.scss";
import closeIcon from "assets/times-circle.svg";
import chevrons from "assets/chevrons-right-regular.svg";
import Icon from "components/Icon";
import Clickable from "components/Clickable";
import { Gig } from "gql-gen";
import { CancelGigsMutation, CancelGigsMutationVariables } from "gql-gen";
import cancelGigs from "./cancelGigs.gql";
import { useMutation } from "@apollo/client";
import { getRustBackendUrl } from "utilities/getServerRootUrl";
import { getJwt } from "utilities/authentication";
import useRouter from "use-react-router";
import { getBaseAppUrl } from "../../../utilities/routes";
import { AppName } from "pinata-common/routes";

const rustendUrl = `${getRustBackendUrl()}/api/v1`;

function getStateClass(state?: WorkflowState) {
  switch (state) {
    case "verified":
      return styles.verified;
    case "reportVerificationRejected":
      return styles.rejected;
    default:
      return "";
  }
}

interface Props {
  agencyName?: string;
  clientName?: string;
  logoUrl?: string | null;
  gig?: Gig;
  onSave: () => Promise<void>;
}

export default function ReportHeader({ agencyName, clientName, gig, onSave }: Props) {
  const [menuVisible, setMenuVisible] = useState(false);

  if (!gig) {
    return null;
  }

  return (
    <>
      <div className={cx(styles.header, getStateClass(gig.workflowStateKey))}>
        <div className={styles.headerContent} data-test="gogetter.gigInfo.clientAgencyAndTitle">
          <div className={styles.topHeader}>
            <div>
              <LegacyText.Display4 kind="reverse" className={styles.orgName}>
                {agencyName ? agencyName : clientName}
              </LegacyText.Display4>
              <LegacyText.Display2 kind="reverse" className={styles.roleTitle}>
                {gig.programRole?.title}
              </LegacyText.Display2>
            </div>
            <Clickable className={styles.closeBtn} onClick={() => setMenuVisible(true)}>
              <Icon src={closeIcon} size={20} fill="white" />
            </Clickable>
          </div>
          <LegacyText.P kind="reverse" className={styles.gigTitle}>
            {gig.title}
          </LegacyText.P>

          {gig.compareWorkflowStateOrder >= 0 && (
            <ReportStatus
              state={gig.workflowStateKey}
              message={gig.reportReviewMessage}
              reviewedByName={
                gig.reportReviewedBy ? `${gig.reportReviewedBy.firstName} ${gig.reportReviewedBy.lastName}` : null
              }
              reviewedByOrganization={agencyName || clientName || ""}
            />
          )}
        </div>
      </div>
      <Menu menuVisible={menuVisible} setMenuVisible={setMenuVisible} gig={gig} onSave={onSave} />
    </>
  );
}

interface MenuProps {
  menuVisible: boolean;
  setMenuVisible: (visible: boolean) => void;
  gig: Gig;
  onSave: () => Promise<void>;
}

function Menu({ menuVisible, setMenuVisible, gig, onSave }: MenuProps) {
  const { selfAssignUserCanEditWhenGigHasSelfAssignableActivity: selfAssignable, id: gigId } = gig;
  const { orgId } = useRouter<{ orgId: string }>().match.params;

  const [cancelGig, { loading: cancelGigLoading }] = useMutation<CancelGigsMutation, CancelGigsMutationVariables>(
    cancelGigs,
  );

  const [cancelError, setCancelError] = useState<string | null>(null);
  const [homeAppUrl, setHomeAppUrl] = useState<string | null>(null);

  useEffect(() => {
    if (menuVisible) {
      document.body.classList.add(styles.noScroll);
    } else {
      document.body.classList.remove(styles.noScroll);
    }

    return () => {
      document.body.classList.remove(styles.noScroll);
    };
  }, [menuVisible]);

  useEffect(() => {
    async function fetchHomeApp() {
      const homeAppResponse = await fetch(`${rustendUrl}/organizations/${orgId}/organization_users/home_app`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getJwt()}`,
          "X-Pinata-Organization-Id": orgId,
        },
      });
      const homeApp = await homeAppResponse.json();

      setHomeAppUrl(getUrl(homeApp, orgId));
    }
    fetchHomeApp();
  }, [orgId]);

  if (!menuVisible) {
    return null;
  }

  const nonAutonomousItems = [
    {
      key: "saveGoToTaskSummary",
      onClick: async () => {},
      content: (
        <Clickable
          onClick={async () => {
            setMenuVisible(false);
            await onSave();
            const go = getBaseAppUrl("go") + `/${orgId}/tasks/${gigId}`;
            window.location.href = go;
          }}
        >
          Save & go back to Task Summary <Icon src={chevrons} size={12} fill="white" />
        </Clickable>
      ),
    },
    {
      key: "saveGoHome",
      content: (
        <Clickable
          onClick={async () => {
            setMenuVisible(false);
            await onSave();
            if (homeAppUrl) {
              window.location.href = homeAppUrl;
            }
          }}
        >
          Save & go Home <Icon src={chevrons} size={12} fill="white" />
        </Clickable>
      ),
    },
  ];

  const autonomousItems = [
    {
      key: "cancel",
      content: (
        <>
          {cancelGigLoading ? (
            <span className={styles.cancelling}>
              Cancelling <DotDotDot ticks={3} />
            </span>
          ) : (
            <Clickable
              onClick={async () => {
                try {
                  const response = await cancelGig({
                    variables: {
                      filters: idIn([gigId]),
                      programIds: [],
                      reasonKey: "createdByMistake",
                      reasonDescription: null,
                      cancellationType: "cancelledUnpaid",
                    },
                  });
                  if (response.data?.cancelGigs === 0 || !homeAppUrl) {
                    setCancelError("Could not cancel this task.");
                  } else {
                    window.location.href = homeAppUrl;
                  }
                } catch (e) {
                  setCancelError("Could not cancel this task.");
                }
              }}
            >
              Cancel & Send to Trash <Icon src={chevrons} size={12} fill="white" />
            </Clickable>
          )}
          {cancelError ? (
            <p className={styles.menuItemErrorMessage}>
              {cancelError}
              <span> Try again or</span>
              <a href="mailto:support@gopinata.com"> contact support.</a>
            </p>
          ) : null}
        </>
      ),
    },
  ];

  return (
    <div className={styles.overlay}>
      <div className={styles.overlayContent}>
        <div className={styles.menu}>
          <ul>
            {selfAssignable
              ? autonomousItems.map((item, idx) => <li key={item.key}>{item.content}</li>)
              : nonAutonomousItems.map(item => <li key={item.key}>{item.content}</li>)}
          </ul>
          <button onClick={() => setMenuVisible(false)}>Nevermind, stay here</button>
        </div>
        <div className={styles.closeArea} onClick={() => setMenuVisible(false)}></div>
      </div>
    </div>
  );
}

function getUrl(homeApp: { home_app: string }, orgId: string) {
  const appMap: Record<string, string> = {
    TheApp: "theApp",
    Dashboard: "dashboard",
    Go: "go",
  };

  const redirectToApp = (appMap[homeApp.home_app] || "dashboard") as AppName;

  return `${getBaseAppUrl(redirectToApp)}/${orgId}`;
}
